import React, { useEffect, useState } from "react";
import { Grid, IconButton, InputAdornment } from "@mui/material";
import TextFieldGeneric from "../../components/Generics/TextFieldGeneric";
import { Cliente } from "../../model/entities/Cliente";
import { MagnifyingGlass, Plus } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import TableGeneric from "../../components/Generics/TableGeneric";
import clienteApi from "../../api/clienteApi";

const columns: Cliente[] | any[] = [
  {
    field: "id",
    headerName: "id",
    width: 5,
    editable: false,
  },
  {
    field: "nome",
    headerName: "Nome",
    width: 230,
    editable: false,
  },
  {
    field: "apelido",
    headerName: "Apelido",
    width: 500,
    editable: false,
  },
];

const ListagemClientesPage: React.FC = () => {
  const [clientes, setClientes] = useState<Cliente[]>([]);
  const [totalLinhas, setTotalLinhas] = useState(0);
  const [loading, setLoading] = useState(false);
  const [nome, setNome] = useState("");
  const navigate = useNavigate();
  const [pagina, setPagina] = useState(0);
  const [linhasPorPagina, setLinhasPorPagina] = useState(5);
  const [clienteSelecionado, setClienteSelecionado] = useState<Cliente | null>(
    null
  );

  useEffect(() => {
    buscarClientes();
  }, []);

  const buscarClientes = async () => {
    try {
      setLoading(true);
      const count = await clienteApi.count();
      setTotalLinhas(count);

      const res = await clienteApi.getMany(pagina, linhasPorPagina);
      setClientes(res);
      setLoading(false);
    } catch (erro: any) {
      // mostrarSnack("Ops, aconteceu um problema ao buscar os clientes!!");
      console.log("Erro ao buscar clientes", erro);
      setLoading(false);
    }
  };

  useEffect(() => {
    buscarClientes();
  }, [pagina, linhasPorPagina]);

  useEffect(() => {
    navegarParaCliente();
  }, [clienteSelecionado]);

  const navegarParaCliente = () => {
    if (clienteSelecionado) {
      navigate(`/cliente/cadastro`, { state: clienteSelecionado });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextFieldGeneric
          label="Pesquisa por nome"
          value={nome}
          onChange={(value) => {
            setNome(value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton onClick={() => buscarClientes()}>
                  <MagnifyingGlass size={32} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TableGeneric
          pageSize={linhasPorPagina}
          colunas={columns}
          linhas={clientes}
          loading={loading}
          totalLinhas={totalLinhas}
          selecionaLinha={false}
          formasDeListar={[5, 10]}
          onRowClick={(params) => setClienteSelecionado(params.row)}
          sx={{ height: "400px" }}
          stringLinhaVazia="Nenhum cliente encontrado"
          setPaginacao={(model) => {
            setPagina(model.page);
            setLinhasPorPagina(model.pageSize);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ListagemClientesPage;
