import { useState } from "react";
import { Button, Grid } from "@mui/material";
import TextFieldGeneric from "../../components/Generics/TextFieldGeneric";
import { Endereco } from "../../model/entities/Endereco";
import ModalGeneric from "../Generics/ModalGeneric";
import NumberFieldGeneric from "../Generics/NumberFieldGeneric";
import { FloppyDisk } from "@phosphor-icons/react";
import TipoEnderecoField from "../fields/TipoEnderecoField";
import cepApi from "../../api/cepApi";
import Titulo2 from "../Generics/Titulo2";
import { useSnack } from "../../redux/snack/snackHooks";

type Props = {
  open: boolean;
  onClose: () => void;
  adicionarEndereco: (endereco: Endereco) => void;
};

const ModalNovoEndereco: React.FC<Props> = (props) => {
  const { open, onClose, adicionarEndereco } = props;

  const [endereco, setEndereco] = useState<Endereco>(new Endereco());
  const mostrarSnack = useSnack();

  const salvar = () => {
    if (!endereco.cep) {
      mostrarSnack("Por favor informe o cep do endereço", "error");
      return;
    }

    if (!endereco.logradouro) {
      mostrarSnack("Por favor informe o logradouro", "error");
      return;
    }

    if (!endereco.bairro) {
      mostrarSnack("Por favor informe o bairro", "error");
      return;
    }

    if (!endereco.bairro) {
      mostrarSnack("Por favor informe a cidade", "error");
      return;
    }

    if (endereco.tipoEndereco !== 0 && endereco.tipoEndereco !== 0) {
      mostrarSnack("Por favor informe o tipo do endereço", "error");
      return;
    }

    adicionarEndereco(endereco);
  };

  const buscarCep = async (value: string) => {
    try {
      const buscaCep = await cepApi.getCep(value);

      setEndereco({
        ...endereco,
        bairro: buscaCep.bairro,
        cidade: buscaCep.cidade,
        logradouro: buscaCep.logradouro,
        uf: buscaCep.uf,
        cep: buscaCep.cep,
      });
    } catch (erro: any) {
      mostrarSnack(`Não consegui encontrar o CEP ${value}`, "info");
    }
  };

  return (
    <ModalGeneric open={open} onClose={onClose}>
      <Grid container xs={12} spacing={2}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Titulo2>Cadastrar novo endereço</Titulo2>
        </Grid>

        <Grid item xs={6}>
          <TextFieldGeneric
            label="CEP"
            value={endereco.cep}
            onChange={(value) => {
              setEndereco({ ...endereco, cep: value });
              if (value.length === 8) {
                buscarCep(value);
              }
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextFieldGeneric
            label="Logradouro"
            value={endereco.logradouro}
            onChange={(value) => {
              setEndereco({ ...endereco, logradouro: value });
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextFieldGeneric
            label="Bairro"
            value={endereco.bairro}
            onChange={(value) => {
              setEndereco({ ...endereco, bairro: value });
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <NumberFieldGeneric
            label="Numero"
            value={endereco.numero}
            onChange={(value) => {
              setEndereco({ ...endereco, numero: value });
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <TextFieldGeneric
            label="Cidade"
            value={endereco.cidade}
            onChange={(value) => {
              setEndereco({ ...endereco, cidade: value });
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <TextFieldGeneric
            label="UF"
            value={endereco.uf}
            onChange={(value) => {
              setEndereco({ ...endereco, uf: value });
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <TipoEnderecoField
            setTipoEndereco={(value) => {
              setEndereco({ ...endereco, tipoEndereco: value.codigo });
            }}
            sx={350}
          />
        </Grid>

        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            style={{ margin: "5px" }}
            variant="contained"
            endIcon={<FloppyDisk size={32} />}
            onClick={() => salvar()}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    </ModalGeneric>
  );
};

export default ModalNovoEndereco;
