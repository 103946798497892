import React, { useState } from "react";
import { useEmpresa } from "../../../redux/empresa/empresaHooks";
import { Collapse, Grid, IconButton, Typography } from "@mui/material";
import Titulo3 from "../../../components/Generics/Titulo3";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";

const HeaderNovoOrcamento: React.FC = () => {
  const [empresa] = useEmpresa();
  const [expandir, setExpandir] = useState(false);

  return (
    <Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <h2 style={{ fontWeight: "bold" }}>
          Proposta de orçamento
          <IconButton
            onClick={() => {
              setExpandir(!expandir);
            }}
          >
            {expandir ? (
              <KeyboardArrowUpOutlinedIcon />
            ) : (
              <KeyboardArrowDownOutlinedIcon />
            )}
          </IconButton>
        </h2>
      </Grid>

      <Collapse in={expandir} timeout="auto">
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <h3>{empresa?.nomeFantasia ?? ""}</h3>
        </Grid>

        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Titulo3 style={{ fontWeight: "bold" }}>
            {empresa?.logradouro ?? ""} , {empresa?.cidade ?? ""}
          </Titulo3>
        </Grid>

        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Titulo3 style={{ fontWeight: "bold" }}>
            {empresa?.telefone ?? ""} | {empresa?.email ?? ""}
          </Titulo3>
        </Grid>
      </Collapse>
    </Grid>
  );
};

export default HeaderNovoOrcamento;
