import { CardContent, Typography, Grid, Divider, Chip } from "@mui/material";
import { motion } from "framer-motion";
import {
  CalendarToday,
  EventAvailable,
  EditOutlined,
} from "@mui/icons-material";
import { Cliente } from "../../../model/entities/Cliente";
import { formatarData, useIsSmallScrenn } from "../../../helper";
import { ProjetoCompletoView } from "../../../model/entities/ProjetoCompletoView";
import { LISTA_STATUS_PROJETO } from "../../../constants";
import { useState } from "react";
import StatusProjetoField from "../../../components/fields/StatusProjetoField";
import ButtonGeneric from "../../../components/Generics/ButtonGeneric";
import {
  DadosAtualizarProjeto,
  Projeto,
  projetoCompletoViewToProjeto,
} from "../../../model/entities/Projeto";
import { useSnack } from "../../../redux/snack/snackHooks";
import { boolean } from "yup";
import projetoApi from "../../../api/projetoApi";

interface ProjetoProps {
  cliente: Cliente | null;
  projeto: ProjetoCompletoView[];
  loading: boolean;
  setLoading: (x: boolean) => void;
  refresh: () => void;
}

const AbaInformacoesProjeto: React.FC<ProjetoProps> = (props) => {
  const { cliente, projeto, loading, setLoading, refresh } = props;

  const getStatusColor = (status: number) => {
    switch (status) {
      case 0:
        return "warning";
      case 1:
        return "success";
      case 2:
        return "error";
      default:
        return "default";
    }
  };

  const isSmallScreen = useIsSmallScrenn();
  const [showSelectStatus, setShowSelectStatus] = useState(false);
  const mostrarSnack = useSnack();
  const [projetoObj, setProjetoObj] = useState<DadosAtualizarProjeto>(
    projetoCompletoViewToProjeto(projeto[0])
  );

  const salvar = async () => {
    try {
      setLoading(true);
      await projetoApi.update(projeto[0].projetoId, projetoObj);

      setLoading(false);
      refresh();
    } catch (erro: any) {
      setLoading(false);
      mostrarSnack("Não foi possível buscar os dados do projeto", "error");
      console.log("Não foi possível buscar os dados do projeto", erro);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      style={{ maxHeight: isSmallScreen ? "550px" : "", overflow: "auto" }}
    >
      <CardContent>
        <Typography
          variant="h5"
          style={{ fontWeight: "bold", color: "#0a4b75" }}
        >
          Detalhes do Projeto
        </Typography>
        <Divider style={{ margin: "10px 0" }} />

        <Grid container spacing={2}>
          {/* STATUS */}
          <Grid item xs={12} sm={12}>
            {showSelectStatus ? (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <StatusProjetoField
                    codigoStatus={projetoObj?.status ?? 0}
                    setStatus={(obj) => {
                      setProjetoObj({ ...projetoObj, status: obj.id });
                    }}
                  />
                </Grid>
                <Grid xs={12} style={{ textAlign: "center" }}>
                  <ButtonGeneric label={"Salvar"} onClick={salvar} />
                </Grid>
              </Grid>
            ) : (
              <Typography
                variant="subtitle1"
                style={{ fontWeight: "bold", color: "#333", fontSize: "22px" }}
              >
                Status:
                <Chip
                  sx={{ marginLeft: "15px" }}
                  label={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {
                        LISTA_STATUS_PROJETO.find(
                          (item) => item.id == projeto[0].projetoStatus
                        )?.decricao
                      }
                      <div style={{ marginLeft: "8px", cursor: "pointer" }}>
                        <EditOutlined
                          onClick={() => setShowSelectStatus(true)}
                        />
                      </div>
                    </div>
                  }
                  color={getStatusColor(projeto[0].projetoStatus)}
                  style={{ fontWeight: "bold" }}
                />
              </Typography>
            )}
          </Grid>

          {/* Data de Inicio */}
          <Grid item xs={12} sm={6}>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: "bold", color: "#333", fontSize: "22px" }}
            >
              <CalendarToday
                style={{
                  marginRight: "8px",
                  verticalAlign: "middle",
                  color: "#2e7d32",
                }}
              />
              Data Prevista Início:
            </Typography>
            <Typography
              variant="body1"
              style={{ color: "#555", fontSize: "20px" }}
            >
              {formatarData(projeto[0].projetoDataInicio)}
            </Typography>
          </Grid>

          {/* Data de Fim */}
          <Grid item xs={12} sm={6}>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: "bold", color: "#333", fontSize: "22px" }}
            >
              <EventAvailable
                style={{
                  marginRight: "8px",
                  verticalAlign: "middle",
                  color: "#d32f2f",
                }}
              />
              Data Prevista Fim:
            </Typography>

            <Typography
              variant="body1"
              style={{ color: "#555", fontSize: "20px" }}
            >
              {formatarData(projeto[0].projetoDataFim)}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>

      <CardContent>
        <Typography
          variant="h5"
          style={{ fontWeight: "bold", color: "#0a4b75" }}
        >
          Detalhes do Cliente
        </Typography>
        <Divider style={{ margin: "10px 0" }} />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="body1"
              style={{ color: "#555", fontSize: "20px" }}
            >
              <strong>Nome:</strong> {cliente?.nome || "Não informado"}
            </Typography>
            <Typography
              variant="body1"
              style={{ color: "#555", fontSize: "20px" }}
            >
              <strong>Apelido:</strong> {cliente?.apelido || "Não informado"}
            </Typography>
            <Typography
              variant="body1"
              style={{ color: "#555", fontSize: "20px" }}
            >
              <strong>Email:</strong> {cliente?.email || "Não informado"}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography
              variant="body1"
              style={{ color: "#555", fontSize: "20px" }}
            >
              <strong>CPF:</strong> {cliente?.cpf || "Não informado"}
            </Typography>
            <Typography
              variant="body1"
              style={{ color: "#555", fontSize: "20px" }}
            >
              <strong>CNPJ:</strong> {cliente?.cnpj || "Não informado"}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </motion.div>
  );
};

export default AbaInformacoesProjeto;
