import * as React from "react";
import TextField from "@mui/material/TextField";

import { Autocomplete, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { ModeloBriefing } from "../../model/entities/ModeloBriefing";
import { useSnack } from "../../redux/snack/snackHooks";
import modeloBriefingApi from "../../api/modeloBriefingApi";

type Props = {
  setModelo: (p: ModeloBriefing | null) => void;
  modelo: ModeloBriefing | null;
};

const ModeloBriefingField: React.FC<Props> = (props) => {
  const { modelo, setModelo } = props;

  const mostrarSnack = useSnack();
  const [modelos, setModelos] = useState<ModeloBriefing[]>([]);
  const [modeloSelecionado, setModeloSelecionado] =
    useState<ModeloBriefing | null>(null);

  useEffect(() => {
    buscaClientes();
  }, [modelo]);

  const buscaClientes = async () => {
    try {
      const res = await modeloBriefingApi.getManyComplete();

      setModelos(res);
      if (modelo) {
        const modeloEncontrado = res.find((item) => item.id === modelo.id);

        if (modeloEncontrado) {
          setModeloSelecionado(modeloEncontrado);
        }
      }
    } catch (erro: any) {
      mostrarSnack("Erro ao buscar lista de modelos", "error");
      console.log("erro ao buscar modelos", erro);
    }
  };
  const theme = useTheme();

  // Defina o valor de xs com base nos breakpoints do tema
  const xsValue = theme.breakpoints.down("sm") ? 12 : 6;
  return (
    <div style={{ textAlign: "center" }}>
      <Autocomplete
        id="filter-modelo-briefing"
        options={modelos}
        getOptionLabel={(option) =>
          option.titulo ? `${option.titulo} ` : option.titulo
        }
        onChange={(event, value) => {
          if (value) {
            setModelo(value);
            setModeloSelecionado(value);
          } else {
            setModeloSelecionado(null);
            setModelo(null);
          }
        }}
        value={modeloSelecionado}
        sx={{ xsValue }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={"outlined"}
            label={"Selecionar modelo de briefing"}
            placeholder={"Selecionar modelo de briefing"}
            multiline={true}
          />
        )}
      />
    </div>
  );
};

export default ModeloBriefingField;
