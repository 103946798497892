import { useState, useEffect } from "react";
import {
  Grid,
  IconButton,
  Button,
  Paper,
  Typography,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import orcamentoApi from "../../../api/orcamentoApi";
import {
  useClienteOrcamento,
  useDataValidadeOrcamento,
  useLimparOrcamento,
  useServicosOrcamento,
} from "../../../redux/orcamento/orcamentoHooks";
import ServicoField from "../../../components/fields/ServicoField";
import { Servico } from "../../../model/entities/Servico";
import AddIcon from "@mui/icons-material/Add";
import { DeleteOutline } from "@mui/icons-material";

import { formatarDinheiro } from "../../../helper";
import NumberFieldGeneric from "../../../components/Generics/NumberFieldGeneric";
import { OrcamentoServico } from "../../../model/entities/OrcamentoServico";
import GridCenter from "../../../components/Generics/GridCenter";
import { colors } from "../../../theme";
import Titulo2 from "../../../components/Generics/Titulo2";
import { ServicoFilho } from "../../../model/entities/ServicoFilho";
import { useSnack } from "../../../redux/snack/snackHooks";
import { toast } from "react-toastify";

const ServicosOrcamento: React.FC = () => {
  const mostrarSnack = useSnack();

  const limparOrcamento = useLimparOrcamento();
  const [validadeProposta] = useDataValidadeOrcamento();

  const [servicosOrcamento, setServicosOrcamento] = useServicosOrcamento();
  const [cliente] = useClienteOrcamento();
  const [dataOrcamento] = useState(dayjs());
  const navigate = useNavigate();
  const [servicoSelecionado, setServicoSelecionado] = useState<Servico | null>(
    null
  );
  const [valorTotal, setValorTotal] = useState<number>(0);

  useEffect(() => {
    calcularValorTotal();
  }, [servicosOrcamento]);

  const cadastrarOrcamento = async () => {
    try {
      if (!cliente) {
        mostrarSnack(
          "Por favor, informe um cliente antes de continuar.",
          "error"
        );
        return;
      }
      if (servicosOrcamento.length === 0) {
        mostrarSnack(
          "Para finalizar o orçamento é preciso adicionar os serviços.",
          "error"
        );

        return;
      }

      const resolveAfter3Sec = orcamentoApi.insert({
        idEndereco: cliente.enderecos[0].id,
        dadosServicos: servicosOrcamento,
        dataOrcamento: dataOrcamento.toDate(),
        validadeDaProposta: validadeProposta.toDate(),
        nomeCliente: cliente.nome,
        apelidoCliente: cliente.apelido,
        dadosEndereco: cliente.enderecos[0],
      });

      toast.promise(resolveAfter3Sec, {
        pending: {
          render() {
            return "Finalizando orçamento";
          },
        },
        success: {
          render() {
            navigate("/orcamento/listagem");
            limparOrcamento();
            return "Orçamento finalizado";
          },
        },
        error: "Erro ao cadastrar orçamento",
      });
    } catch (erro: any) {
      mostrarSnack("Não foi possível gravar o orçamento.", "error");
      console.log(
        "Não foi possível gravar o orçamento. Orçamento não registrado",
        erro
      );
    }
  };

  const calcularValorTotal = () => {
    const total = servicosOrcamento.reduce(
      (sum, servico) => sum + (servico.valor || 0),
      0
    );
    setValorTotal(total);
  };

  const handleRemoverServico = (index: number) => {
    const novosOrcamentos = [...servicosOrcamento];
    novosOrcamentos.splice(index, 1);
    setServicosOrcamento(novosOrcamentos);
  };

  const handleRemoverServicoFilho = (index: number, indexFilho: number) => {
    const novosOrcamentos = [...servicosOrcamento];
    novosOrcamentos[index].servicosFilhos.splice(indexFilho, 1);
    setServicosOrcamento(novosOrcamentos);
  };

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Titulo2>Adicione os serviços</Titulo2>
      </Grid>

      <Grid item md={6} xs={12}>
        <ServicoField
          idServico={servicoSelecionado?.id ?? 0}
          setServico={(servicoObj) => {
            if (servicoObj) setServicoSelecionado(servicoObj);
          }}
        />
      </Grid>

      {servicoSelecionado && (
        <Grid item md={12} xs={12} style={{ textAlign: "center" }}>
          <Button
            onClick={() => {
              const newServicos = [...servicosOrcamento];

              const servicosFilhos: ServicoFilho[] = [];
              for (const servico of servicoSelecionado.servicosFilhos) {
                console.log("sevico", servico);
                const filhos: ServicoFilho = {
                  servicoFilho: servico.servicoFilho,
                  servicoFilhoId: servico.servicoFilho.id,
                  servicoPai: servico.servicoPai,
                  servicoPaiId: servico.servicoPai.id,
                  id: 0,
                };
                servicosFilhos.push(filhos);
              }
              newServicos.push(
                new OrcamentoServico(servicoSelecionado, servicosFilhos)
              );
              setServicosOrcamento([...newServicos]);
            }}
            variant="contained"
            startIcon={<AddIcon />}
          >
            Adicionar Serviço
          </Button>
        </Grid>
      )}

      <Grid item xs={12}>
        {servicosOrcamento.map((servico, index) => (
          <GridCenter>
            <Paper
              key={servico.id}
              style={{
                borderRadius: "20px",
                margin: "12px 0px",
                padding: "12px",
                width: "100%",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={10} md={6}>
                  <Typography fontWeight={"bold"} fontSize={20}>
                    {servico.titulo}
                  </Typography>
                </Grid>

                <Grid item xs={2} md={6} style={{ textAlign: "right" }}>
                  <IconButton onClick={() => handleRemoverServico(index)}>
                    <DeleteOutline />
                  </IconButton>
                </Grid>

                <Grid item xs={12}>
                  {servico.servicosFilhos.map((servicoFilho, indexFilho) => (
                    <Grid
                      container
                      style={{ marginLeft: "22px", alignItems: "center" }}
                    >
                      <Grid item>
                        <Typography fontWeight={"bold"} fontSize={16}>
                          {servicoFilho.servicoFilho.titulo}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <IconButton
                          onClick={() =>
                            handleRemoverServicoFilho(index, indexFilho)
                          }
                        >
                          <DeleteOutline />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>

                <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
                  <NumberFieldGeneric
                    value={servico.valor}
                    type="money"
                    label={"Valor serviço"}
                    onChange={(value) => {
                      const novosOrcamentos = [...servicosOrcamento];
                      novosOrcamentos[index].valor = value;
                      setServicosOrcamento(novosOrcamentos);
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </GridCenter>
        ))}

        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Container
            style={{ background: colors.lightGreen, borderRadius: "25px" }}
          >
            <Typography variant="h6" color={"white"}>
              Valor Total: R$ {formatarDinheiro(valorTotal)}
            </Typography>
          </Container>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ServicosOrcamento;
