import React from "react";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { makeStyles } from "@material-ui/core/styles";
import { useSessao } from "../../redux/sessao/sessaoHooks";
import { ModalCfgApp } from "../../components/modais/ModalCfgApp";
import { useSnack } from "../../redux/snack/snackHooks";
import Loader from "../../components/Generics/Loader";
import ModalGeneric from "../../components/Generics/ModalGeneric";
import { useCfgGeral } from "../../redux/cfgGeral/cfgGeralHooks";
import Copyright from "../../components/layout/Copyrigth";
import Back from "../../assets/Back.png";
import GridCenter from "../../components/Generics/GridCenter";
import theme from "../../theme";
import ModalRecuperarSenha from "../../components/modais/ModalRecuperarSenha";

const LoginPage: React.FC = () => {
  const mostrarSnack = useSnack();
  const [usuario, setUsuario] = useState("");
  const [senha, setSenha] = useState("");
  const [verSenha, setVerSenha] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sessao, login, revalidate] = useSessao();
  const classes = useStyles();
  const [openModalCfg, setOpenModalCfg] = useState(false);
  const [cfgGeral, setCfgGeral] = useCfgGeral();
  const [openModalRecuperarSenha, setOpenModalRecuperarSenha] = useState(false);

  const frases = [
    "⏳ Carregando...",
    "🔍 Verificando credenciais...",
    "🔌 Conectando ao servidor...",
    "🗄️ Acessando banco de dados...",
    "🔑 Autenticando usuário...",
    "🔄 Sincronizando informações...",
    "✅ Validando dados...",
    "⚙️ Preparando ambiente...",
    "🚀 Iniciando sessão...",
    "🛡️ Verificando permissões...",
  ];
  const [frase, setFrase] = useState(frases[0]);
  useEffect(() => {
    const intervalo = setInterval(() => {
      const novaFrase = frases[Math.floor(Math.random() * frases.length)];
      setFrase(novaFrase);
    }, 1000);
    return () => clearInterval(intervalo);
  }, []);
  const onLogin = async () => {
    try {
      setLoading(true);
      await login(usuario, senha);
      setLoading(false);
    } catch (err: any) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      if (err.data === "Usuário inexistente ou senha inválida") {
        mostrarSnack("Usuário inexistente ou senha inválida", "error");
        return;
      }
      mostrarSnack(`${err}`, "error");
      console.log("Erro no login", err);
    }
  };
  useEffect(() => {
    const callback = (event: any) => {
      if (event.key === "*") {
        event.preventDefault();
        setOpenModalCfg(true);
      }
    };
    document.addEventListener("keydown", callback);
    return () => {
      document.removeEventListener("keydown", callback);
    };
  }, []);
  return (
    <div style={{ background: "#0f1f2c" }}>
      <div className={classes.backGround}>
        <div className={classes.loginBox}>
          {loading && (
            <ModalGeneric
              open={loading}
              hideCloseBootom
              style={{ background: "transparent", border: "none" }}
              onClose={() => {}}
            >
              <GridCenter>
                <Grid
                  item
                  xs={12}
                  style={{
                    color: "white",
                  }}
                >
                  <h2>{frase}</h2>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", display: "flex" }}
                >
                  <Loader color="white" />
                </Grid>
              </GridCenter>
            </ModalGeneric>
          )}
          <Grid
            container
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Grid item xs={12} md={8} style={{ margin: "0px 15px" }}>
              <TextField
                className={classes.input}
                style={{ marginBottom: "25px" }}
                label="Código de acesso"
                value={cfgGeral.tenantCode}
                onChange={(event) =>
                  setCfgGeral({ ...cfgGeral, tenantCode: event.target.value })
                }
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    onLogin();
                  }
                }}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} md={8} style={{ margin: "0px 15px" }}>
              <TextField
                className={classes.input}
                style={{ marginBottom: "25px" }}
                label="Usuário"
                value={usuario}
                onChange={(event) => setUsuario(event.target.value)}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    onLogin();
                  }
                }}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} md={8} style={{ margin: "0px 15px" }}>
              <TextField
                className={classes.input}
                label="Senha"
                style={{ marginBottom: "25px" }}
                value={senha}
                onChange={(event) => setSenha(event.target.value)}
                type={verSenha ? "text" : "password"}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    onLogin();
                  }
                }}
                disabled={loading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setVerSenha(!verSenha)}
                      >
                        {verSenha ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{ textAlign: "center", margin: "0px 15px" }}
            >
              <Button
                style={{ width: "75%" }}
                variant="contained"
                onClick={() => {
                  onLogin();
                }}
              >
                Entrar
              </Button>
            </Grid>
            <div
              className={classes.forgotPassword}
              onClick={() => {
                setOpenModalRecuperarSenha(true);
              }}
            >
              <Typography
                fontSize={14}
                color={(theme) => theme.palette.primary.main}
              >
                Esqueceu a senha ?
                <br />
                <span>Clique aqui para recuperar!</span>
              </Typography>
            </div>
          </Grid>
          {openModalCfg && (
            <ModalCfgApp
              open={openModalCfg}
              onClose={() => setOpenModalCfg(false)}
            />
          )}
        </div>
        {openModalRecuperarSenha && (
          <ModalRecuperarSenha
            open={openModalRecuperarSenha}
            onClose={() => setOpenModalRecuperarSenha(false)}
            usuario={usuario}
            codigoAcesso={cfgGeral.tenantCode}
            setUsuario={setUsuario}
            setCodigoAcesso={(codigoAcesso: string) => {
              setCfgGeral({ ...cfgGeral, tenantCode: codigoAcesso });
            }}
          ></ModalRecuperarSenha>
        )}
      </div>
      <footer className={classes.footer}>
        <div style={{ color: "white" }}>𝙿𝚛𝚘𝚓𝚎𝚝𝚎.𝚊𝚙𝚙</div>
        <Copyright textColor={"#fb6f24"} />
      </footer>
    </div>
  );
};
export default LoginPage;
const useStyles = makeStyles(() => ({
  backGround: {
    "@media (max-width: 768px)": {
      backgroundImage: `url(${Back})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      height: "100vh",
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      objectFit: "contain",
      flexDirection: "column",
    },
    "@media (min-width: 768px)": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "94.8vh",
      position: "relative",
    },
  },
  forgotPassword: {
    cursor: "pointer",
    width: "100%",
    textAlign: "center",
    botom: "0",
    marginTop: "auto",
  },
  loginBox: {
    background: "#fff",
    padding: "40px",
    borderRadius: "10px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    width: "350px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "@media (min-width: 768px)": {
      width: "550px",
    },
  },
  input: {
    width: "100%",
    minWidth: "350px",
    marginBottom: "35px",
    "@media (max-width: 768px)": {
      minWidth: "400px",
      marginBottom: "20px",
    },
  },
  button: {
    backgroundColor: "#3a7bd5 !important",
    color: "#fff !important",
    "&:hover": {
      backgroundColor: "#306bb5 !important",
    },
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "auto", // Empurra o rodapé para o final
    width: "100%", // Garante a largura total
    justifyContent: "center",
    alignItems: "center",
  },
}));
