import React, { useState } from "react";
import { Divider, Grid, useMediaQuery } from "@mui/material";
import { useClienteOrcamento } from "../../../redux/orcamento/orcamentoHooks";
import ClienteField from "../../../components/fields/ClienteField";
import Info from "../../../components/Generics/Info";
import theme from "../../../theme";
import ButtonGeneric from "../../../components/Generics/ButtonGeneric";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ModalGeneric from "../../../components/Generics/ModalGeneric";
import NovoClientePage from "../../clientes/NovoClientePage";

const SelecaoClienteOrcamento: React.FC = () => {
  const [cliente, setCliente] = useClienteOrcamento();
  const isSmallSScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openNovoCliente, setOpenNovoCliente] = useState(false);

  return (
    <Grid>
      <Grid item xs={12}>
        <h3 style={{ fontWeight: "bold" }}>Informações do cliente</h3>
      </Grid>

      <Divider style={{ background: "black" }} />

      <Grid item xs={12} style={{ textAlign: "right", marginTop: "15px" }}>
        <ButtonGeneric
          label={"Novo cliente"}
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => {
            setOpenNovoCliente(true);
          }}
        />
      </Grid>

      <Grid item xs={12} style={{ marginTop: "4px" }}>
        <ClienteField
          cliente={cliente}
          setCliente={(value) => {
            setCliente(value);
          }}
        />
      </Grid>

      <Grid item xs={12} style={{ marginTop: "15px" }}>
        {!cliente && (
          <h6 style={{ fontSize: "22px", textAlign: "center" }}>
            Por favor selecione ou cadastre um novo cliente, para criar um novo
            orçamento
          </h6>
        )}
      </Grid>

      {cliente && (
        <>
          <Grid item xs={12} style={{ marginTop: "15px" }}>
            <Info label="Nome"> {cliente?.nome ?? ""} </Info>
          </Grid>

          <Grid item xs={12} style={{ marginTop: "15px" }}>
            <Info label="Endereço">
              {cliente?.enderecos[0].logradouro ?? ""}{" "}
            </Info>
          </Grid>
        </>
      )}

      <ModalGeneric
        open={openNovoCliente}
        onClose={() => setOpenNovoCliente(false)}
      >
        <NovoClientePage
          cadastrando
          onSave={(cliente) => {
            setCliente(cliente);
            setOpenNovoCliente(false);
          }}
        />
      </ModalGeneric>
    </Grid>
  );
};

export default SelecaoClienteOrcamento;
