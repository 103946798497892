import {
  DataGrid,
  ptBR,
  GridPaginationModel,
  GridRowIdGetter,
  GridRowParams,
  GridLocaleText,
} from "@mui/x-data-grid";
import { Grid, makeStyles } from "@material-ui/core";

type Props = {
  colunas: any[];
  linhas: any[];
  loading: boolean;
  totalLinhas: number;
  selecionaLinha?: boolean;
  stringLinhaVazia?: string;
  setPaginacao: (paginacao: GridPaginationModel) => void;
  formasDeListar: number[];
  onRowClick?: (params: GridRowParams<any>) => void;
  getRowId?: GridRowIdGetter<any> | undefined;
  rowHeight?: number;
  pageSize: number;
  sx?: any;
};

const TableGeneric: React.FC<Props> = (props) => {
  const {
    colunas,
    linhas,
    loading,
    totalLinhas,
    selecionaLinha,
    stringLinhaVazia,
    setPaginacao,
    formasDeListar,
    onRowClick,
    getRowId,
    rowHeight,
    pageSize,
    sx,
  } = props;

  const classes = useStyles();

  const customLocaleText: Partial<GridLocaleText> = {
    footerRowSelected: (count) =>
      selecionaLinha ? `${count} Linha selecionada` : "",

    noRowsLabel: stringLinhaVazia
      ? stringLinhaVazia
      : "Nenhum registro encontrado",
  };

  return (
    <Grid item xs={12} className={classes.root}>
      <DataGrid
        sx={sx}
        columns={colunas}
        rows={linhas}
        loading={loading}
        getRowId={getRowId ? getRowId : (value) => value.id}
        rowCount={totalLinhas}
        pagination={true}
        paginationMode="server"
        rowHeight={rowHeight}
        style={{ height: "400px" }}
        pageSizeOptions={formasDeListar}
        initialState={{
          pagination: { paginationModel: { pageSize: pageSize } },
        }}
        onPaginationModelChange={(model) => {
          setPaginacao(model);
        }}
        onRowClick={(params) => {
          if (onRowClick) onRowClick(params);
        }}
        localeText={customLocaleText}
      />
    </Grid>
  );
};

export default TableGeneric;
const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-root": {
      border: "1px solid #ddd",
      boxShadow: "0px 0px 10px #ddd",
      // height: "400px",
    },
    "& .MuiDataGrid-header": {
      background: "#f0f0f0",
      fontWeight: "bold",
    },
    "& .MuiDataGrid-cell": {
      padding: "8px",
    },
    "& .MuiDataGrid-row:hover": {
      backgroundColor: "#c8d7e7",
    },
  },
});
