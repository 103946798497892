import { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import TextFieldGeneric from "../../components/Generics/TextFieldGeneric";
import { FloppyDisk } from "@phosphor-icons/react";
import Container from "../../components/Generics/Container";
import servicoApi from "../../api/servicoApi";
import { useLocation, useNavigate } from "react-router-dom";
import CheckBoxGeneric from "../../components/Generics/CheckBoxGeneric";
import MultiServicoField from "../../components/fields/MultiServicoField";
import { Servico } from "../../model/entities/Servico";
import NumberFieldGeneric from "../../components/Generics/NumberFieldGeneric";
import {
  DadosCadastrarServicosFilhos,
  DadosListagemServico,
} from "../../model/types/ServicoTypes";
import { useSnack } from "../../redux/snack/snackHooks";
import { toast } from "react-toastify";

const NovoServicoPage: React.FC = () => {
  const { state } = useLocation();
  const servicoSelecionado = state as DadosListagemServico;

  const [nome, setNome] = useState("");
  const [descricao, setDescricao] = useState("");
  const [servicoPai, setServicoPai] = useState(false);
  const [valor, setValor] = useState(0);
  const [servicosVinculados, setServicosVinculados] = useState<Servico[]>([]);
  const mostrarSnack = useSnack();

  const navigate = useNavigate();

  const salvar = async () => {
    try {
      const servicosFilhos: DadosCadastrarServicosFilhos[] =
        servicosVinculados.map((item) => ({
          servicoPaiId: 0,
          servicoFilhoId: item.id,
        }));

      if (servicoSelecionado) {
        const resolveAfter3Sec = servicoApi.update(servicoSelecionado.id, {
          titulo: nome,
          descricao: descricao,
          valor: valor,
          servicoPai: servicoPai,
          servicosFilhos: servicosFilhos,
        });

        toast.promise(resolveAfter3Sec, {
          pending: {
            render() {
              return "Atualizando dados do serviço";
            },
          },
          success: {
            render() {
              navigate("/servico/listagem");
              return "Serviço atualizado";
            },
          },
          error: "Erro ao atualiza serviço",
        });
        return;
      }

      if (!nome) {
        mostrarSnack("Por favor informe o nome do serviço", "error");
        return;
      }

      if (!descricao) {
        mostrarSnack("Por favor informe a descrição do serviço", "error");
        return;
      }

      const resolveAfter3Sec = servicoApi.insert({
        titulo: nome,
        descricao: descricao,
        valor: valor,
        servicoPai: servicoPai,
        servicosFilhos: servicosFilhos,
      });

      toast.promise(resolveAfter3Sec, {
        pending: {
          render() {
            return "Cadastrando serviço";
          },
        },
        success: {
          render() {
            navigate("/servico/listagem");
            return "Serviço cadastrado";
          },
        },
        error: "Erro ao cadastrar serviço",
      });
    } catch (erro: any) {
      console.log("Erro ao cadastrar serviço", erro);
    }
  };

  useEffect(() => {
    if (servicoSelecionado) {
      setNome(servicoSelecionado.titulo);
      setDescricao(servicoSelecionado.descricao);
      setServicoPai(servicoSelecionado.servicoPai);
      setValor(servicoSelecionado.valor);

      setServicosVinculados(
        servicoSelecionado.servicosFilhos.map((item) => new Servico(item))
      );
    }
  }, [servicoSelecionado]);

  useEffect(() => {
    const valorServico = servicosVinculados.reduce(
      (soma, item) => (soma += item.valor),
      0
    );
    setValor(valorServico ?? 0);
  }, [servicosVinculados]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container page>
        <Grid container xs={12} spacing={2}>
          <Grid item xs={12}>
            <CheckBoxGeneric
              label={
                "Marque se este é um serviço, que contem serviços vinculados a ele."
              }
              value={servicoPai}
              onChange={(x) => setServicoPai(x)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextFieldGeneric
              label="Nome do serviço"
              value={nome}
              maxLength={50}
              exibirMaxLength
              onChange={(value) => {
                setNome(value);
              }}
            />
          </Grid>

          {servicoPai && (
            <Grid item xs={12}>
              <MultiServicoField
                label="Serviços vinculados"
                idServicoAtual={servicoSelecionado?.id ?? 0}
                apenasFilhos
                servicosSelecionados={servicosVinculados}
                setServicosSelecionados={(value) =>
                  setServicosVinculados(value)
                }
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <NumberFieldGeneric
              label="Valor do serviço"
              disabled={servicoPai}
              value={valor}
              type="money"
              onChange={(value) => {
                setValor(value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextFieldGeneric
              label="Descrição do serviço *"
              value={descricao}
              minRows={10}
              type="text"
              onChange={(value) => {
                setDescricao(value);
              }}
            />
          </Grid>

          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Button
              style={{ margin: "5px" }}
              variant="contained"
              endIcon={<FloppyDisk size={32} />}
              onClick={() => salvar()}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default NovoServicoPage;
