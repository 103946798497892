import { useState } from "react";
import { useSnack } from "../../../redux/snack/snackHooks";
import { ProjetoCompletoView } from "../../../model/entities/ProjetoCompletoView";
import { Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import LinkIcon from "@material-ui/icons/Link";
import ButtonGeneric from "../../../components/Generics/ButtonGeneric";
import { useCfgGeral } from "../../../redux/cfgGeral/cfgGeralHooks";
import { formatarDinheiro } from "../../../helper";

import { motion } from "framer-motion";

type Props = {
  projeto: ProjetoCompletoView[];
};

const AbaBriefingProjeto: React.FC<Props> = (props) => {
  const { projeto } = props;
  const mostrarSnack = useSnack();

  const [cfgGeral] = useCfgGeral();

  const copiarLinkPreenchimentoBriefing = () => {
    const currentURL = window.location.href;
    const tenantCode = cfgGeral.tenantCode;
    if (currentURL.includes("projete.app")) {
      const urlPartida = currentURL.split(".app.br")[0];
      const link = `${urlPartida}.app.br/briefing/preenchimento/${projeto[0].briefingId}/${tenantCode}`;
      navigator.clipboard.writeText(link);
      return;
    } else if (currentURL.includes("localhost")) {
      const urlPartida = currentURL.split(":3000")[0];
      const link = `${urlPartida}:3000/briefing/preenchimento/${projeto[0].briefingId}/${tenantCode}`;
      navigator.clipboard.writeText(link);
    }

    mostrarSnack("Link copiada para area de transferência", "success");
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, ease: "easeOut" }}
      style={{ maxHeight: "550px" }}
    >
      {projeto[0] && (
        <Grid xs={12} container spacing={3} justifyContent="center">
          <Grid
            item
            xs={12}
            style={{ textAlign: "right", padding: "10px 20px" }}
          >
            <ButtonGeneric
              variant="outlined"
              startIcon={<LinkIcon />}
              label="Copiar link de preenchimento do briefing"
              onClick={copiarLinkPreenchimentoBriefing}
            />
          </Grid>

          <Grid item xs={12} md={8}>
            <Typography
              variant="h4"
              align="center"
              style={{
                fontWeight: "bold",
                color: "#0a4b75",
                marginBottom: "15px",
              }}
            >
              📋 Briefing do Projeto
            </Typography>

            <Typography
              variant="body1"
              align="center"
              style={{
                fontWeight: "bold",
                color: projeto[0].jaRespondido ? "#4CAF50" : "#d32f2f",
                padding: "10px 0",
              }}
            >
              {projeto[0].jaRespondido
                ? "✅ Já respondido pelo cliente"
                : "❌ Ainda não respondido pelo cliente"}
            </Typography>

            <Grid container spacing={3}>
              {projeto.map((item, i) => (
                <Grid item xs={12} key={i}>
                  <Paper
                    elevation={3}
                    style={{
                      padding: "15px 20px",
                      borderRadius: "10px",
                      backgroundColor: "#f9f9f9",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{
                        fontWeight: "bold",
                        color: "#333",
                        marginBottom: "5px",
                      }}
                    >
                      {item.campoNome}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "16px",
                        color: "#555",
                        wordBreak: "break-word",
                      }}
                    >
                      {item.campoTipo === "VALOR_MONETARIO"
                        ? formatarDinheiro(item.campoValor)
                        : item.campoValor}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
    </motion.div>
  );
};

export default AbaBriefingProjeto;
