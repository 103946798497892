import { RouteType } from "../config";

import AcUnitIcon from "@material-ui/icons/AcUnit";
import GridCenter from "../../components/Generics/GridCenter";
import ConfiguracaoBriefingPage from "../../pages/Briefing/ConfiguracaoBriefingPage";
import DashboardPageLayout from "../../components/layout/DashboardPageLayout";
import HomePage from "../../pages/home/HomePage";
import ListaConfiguracaoBriefingPage from "../../pages/Briefing/ListaConfiguracaoBriefingPage";

const grupoBriefing: RouteType = {
  path: "/briefing",
  element: <DashboardPageLayout />,
  state: "Briefing",
  sidebarProps: {
    displayText: "Briefing",
    icon: <AcUnitIcon />,
  },
  show: true,
  child: [
    {
      index: true,
      element: <HomePage />,
      state: "briefing.index",
      show: true,
    },
    {
      path: "/briefing/modelo/configuração",
      element: (
        <GridCenter>
          <ConfiguracaoBriefingPage />
        </GridCenter>
      ),
      state: "briefing.cadastro",
      sidebarProps: {
        displayText: "Novo Briefing",
      },
      show: false,
    },
    {
      path: "/briefing/modelo/lista",
      element: (
        <GridCenter>
          <ListaConfiguracaoBriefingPage />
        </GridCenter>
      ),
      state: "briefing.modelo.lista",
      sidebarProps: {
        displayText: "Configuração formulários",
      },
      show: true,
    },
    {
      path: "/briefing/modelo/configuração",
      element: (
        <GridCenter>
          <ConfiguracaoBriefingPage />
        </GridCenter>
      ),
      state: "briefing.configuração",
      sidebarProps: {
        displayText: "Configuração modelo briefing",
      },
      show: false,
    },

    // {
    //   path: "/briefing/preenchimento/:briefingId/:tenant",
    //   element: (
    //     <GridCenter>
    //       <PreenchimentoBriefingPage />
    //     </GridCenter>
    //   ),
    //   show: false,
    //   state: "briefing.preenchimento",

    //   sidebarProps: {
    //     displayText: "Preechimento Briefing",
    //   },
    // },
  ],
};

export default grupoBriefing;
