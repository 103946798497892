import { IconButton, Tooltip } from "@mui/material";
import { Info } from "@phosphor-icons/react";
import React from "react";
import { ReactElement } from "react";

type Props = {
  title: string | ReactElement;
};

const Hint: React.FC<Props> = (props) => {
  return (
    <Tooltip title={props.title}>
      <IconButton>
        <Info />
      </IconButton>
    </Tooltip>
  );
};

export default Hint;
