import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Pagination,
  Paper,
  Switch,
  Typography,
  useMediaQuery,
} from "@mui/material";
import TableGeneric from "../../../components/Generics/TableGeneric";
import dayjs from "dayjs";
import { formatarDinheiro } from "../../../helper";
import { GridColDef } from "@mui/x-data-grid";

import {
  ACESSO_OS_VINCULADA,
  STATUS_SERVICO_OS_CONCLUIDO,
} from "../../../constants";

import DatePickerGeneric from "../../../components/Generics/DatePickerGeneric";
import Container from "../../../components/Generics/Container";
import StatusServicoOSField from "../../../components/fields/StatusServicoOSField";
import UsuarioField from "../../../components/fields/UsuarioField";
import { Usuario } from "../../../model/entities/Usuario";
import ServicoField from "../../../components/fields/ServicoField";
import {
  DadosServicoOS,
  FiltroBuscaServicoByUsuario,
} from "../../../model/types/ServicoOSTypes";
import { useSessao } from "../../../redux/sessao/sessaoHooks";
import servicoOsApi, { getStatusServicoOS } from "../../../api/servicoOsApi";
import PaidIcon from "@mui/icons-material/Paid";
import ModalRecebimentoServico from "../../../components/modais/ModalRecebimentoServico";
import EditIcon from "@mui/icons-material/Edit";
import ModalEditarServicoOS from "../../../components/modais/ModalEditarServicoOS";
import GridCenter from "../../../components/Generics/GridCenter";

import AccountBoxIcon from "@material-ui/icons/AccountBox";
import TodayIcon from "@material-ui/icons/Today";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { useSnack } from "../../../redux/snack/snackHooks";
import KanbanBoard from "../../../components/Generics/KanbanBoard";
import theme from "../../../theme";

const ServicoPorUsuarioPage: React.FC = () => {
  const mostrarSnack = useSnack();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [servicos, setServicos] = useState<DadosServicoOS[]>([]);
  const [totalLinhas, setTotalLinhas] = useState(0);
  const [loading] = useState(false);
  const [pagina, setPagina] = useState(0);
  const [linhasPorPagina, setLinhasPorPagina] = useState(5);
  const [servicoOsSelecionado, setServicoOsSelecionado] =
    useState<DadosServicoOS | null>(null);

  const [sessao] = useSessao();
  const [usuario, setUsuario] = useState<Usuario | null>(sessao.usuario);

  const [openModalEditarServicoOS, setOpenModalEditarServicoOs] =
    useState(false);
  const [openModalRecebimentoServico, setOpenModalRecebimentoServico] =
    useState(false);

  const [filtros, setFiltros] = useState<FiltroBuscaServicoByUsuario>(
    new FiltroBuscaServicoByUsuario()
  );

  const [modoKanban, setModoKanban] = useState(false);

  useEffect(() => {
    buscarServicos();
  }, []);

  useEffect(() => {
    buscarServicos();
  }, [filtros, usuario, pagina, linhasPorPagina]);

  const buscarServicos = async () => {
    try {
      const res = await servicoOsApi.getServicos({
        ...filtros,
        pagina,
        linhasPorPagina,
      });

      setServicos(res);
      setTotalLinhas(res.length);
    } catch (erro: any) {
      console.log("erro ao buscar servicos", erro);
      mostrarSnack("Aconteceu um erro ao buscar os servicos", "error");
    }
  };

  const columns: GridColDef<DadosServicoOS>[] = [
    {
      field: "idOrdemServico",
      headerName: "OS",
      width: 30,
      editable: false,
      renderCell: (params) => {
        return params.row.idOrdemServico;
      },
    },

    {
      field: "dataInicio",
      headerName: "Data prevista para inicio",
      width: 230,
      editable: false,
      renderCell: (params) => {
        return dayjs(params.row.dataInicio).format("DD/MM/YYYY");
      },
    },
    {
      field: "dataFim",
      headerName: "Data prevista para entrega",
      width: 230,
      editable: false,
      renderCell: (params) => {
        if (dayjs(params.row.dataEntrega).isBefore(dayjs())) {
          return (
            <span style={{ color: "red" }}>
              {dayjs(params.row.dataEntrega).format("DD/MM/YYYY")}
            </span>
          );
        } else {
          return (
            <span>{dayjs(params.row.dataEntrega).format("DD/MM/YYYY")}</span>
          );
        }
      },
    },

    {
      field: "nomeServico",
      headerName: "Serviço",
      width: 230,
      editable: false,
      renderCell: (params) => {
        return params.row.nomeServico;
      },
    },
    // {
    //   field: "valorServico",
    //   headerName: "Valor serviço",
    //   width: 230,
    //   editable: false,
    //   renderCell: (params) => {
    //     return formatarDinheiro(params.row.valorServico);
    //   },
    // },

    {
      field: "status",
      headerName: "Status ordem servico",
      width: 230,
      editable: false,
      renderCell: (params) => {
        return getStatusServicoOS(params.row.status);
      },
    },
    {
      field: "acoes",
      headerName: "Ações",
      width: 230,
      editable: false,
      renderCell: (params) => {
        return (
          <>
            {/* {params.row.valorServico > params.row.valorPago && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setServicoOsSelecionado(params.row);
                  setOpenModalRecebimentoServico(true);
                }}
              >
                <abbr title="Fazer recebimento">
                  <PaidIcon />
                </abbr>
              </IconButton>
            )} */}

            {params.row.status !== STATUS_SERVICO_OS_CONCLUIDO.id && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setServicoOsSelecionado(params.row);
                  setOpenModalEditarServicoOs(true);
                }}
              >
                <abbr title="Editar informações">
                  <EditIcon />
                </abbr>
              </IconButton>
            )}
          </>
        );
      },
    },
  ];

  if (modoKanban) {
    return (
      <GridCenter>
        <Container page>
          <Grid
            item
            xs={12}
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            <h4> Visualizar em modo Tabela</h4>
            <Switch
              checked={modoKanban}
              onChange={() => setModoKanban(!modoKanban)}
              name="checkedA"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </Grid>

          <KanbanBoard
            servicos={servicos}
            colunas={{
              0: {
                id: "0",
                title: "Pendete",
                tasks: [
                  ...servicos
                    .filter((servico) => servico.status === 0)
                    .map((item) => ({
                      id: item.idServico.toString(),
                      content: item.nomeServico,
                    })),
                ],
              },
              1: {
                id: "1",
                title: "Em Andamento",
                tasks: [
                  ...servicos
                    .filter((servico) => servico.status === 1)
                    .map((item) => ({
                      id: `${item.idOrdemServico}${item.idOsServico}${item.idServico}`,
                      content: item.nomeServico,
                    })),
                ],
              },
              2: {
                id: "2",
                title: "Concluído",
                tasks: [
                  ...servicos
                    .filter((servico) => servico.status === 2)
                    .map((item) => ({
                      id: `${item.idOrdemServico}${item.idOsServico}${item.idServico}`,
                      content: item.nomeServico,
                    })),
                ],
              },
            }}
          />
        </Container>
      </GridCenter>
    );
  }

  return (
    <Container style={{ textAlign: "center" }}>
      {/* <Grid
        item
        xs={12}
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "right",
          alignItems: "center",
        }}
      >
        <h4> Visualizar em modo Kanban</h4>
        <Switch
          checked={modoKanban}
          onChange={() => setModoKanban(!modoKanban)}
          name="checkedA"
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      </Grid> */}

      <Grid item xs={12}>
        <h2>Listagem de serviço por usuário</h2>
      </Grid>

      <Grid container spacing={1}>
        <Grid item sm={6} xs={12}>
          <DatePickerGeneric
            label="Data entrega de"
            value={dayjs(filtros?.dataInicioEntegra)}
            setValue={(value) => {
              if (value) {
                setFiltros({ ...filtros, dataInicioEntegra: value.toDate() });
              }
            }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <DatePickerGeneric
            label="Data entrega ate"
            value={dayjs(filtros?.dataFimEntegra)}
            setValue={(value) => {
              if (value) {
                setFiltros({ ...filtros, dataFimEntegra: value.toDate() });
              }
            }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <ServicoField
            idServico={filtros.idServico ?? 0}
            setServico={(servico) => {
              setFiltros({ ...filtros, idServico: servico?.id ?? null });
            }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <StatusServicoOSField
            codigoStatus={filtros.idStatusServico ?? 0}
            setStatus={(codStatus) => {
              setFiltros({
                ...filtros,
                idStatusServico: codStatus?.id ?? null,
              });
            }}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <UsuarioField
            usuario={usuario}
            disabled={
              ACESSO_OS_VINCULADA.descricaoEnum ===
              sessao?.usuario?.perfil.acessoOSPerfil
            }
            label="Responsavél"
            idUsuario={usuario?.id ?? 0}
            setUsuario={(obj) => {
              setUsuario(obj);
              setFiltros({
                ...filtros,
                idUsuario: obj?.id ?? null,
              });
            }}
          />
        </Grid>
        {isSmallScreen && (
          <Grid
            container
            spacing={2}
            sx={{ display: { xs: "flex", md: "none" } }}
          >
            {servicos.length == 0 ? (
              <Grid container style={{ textAlign: "center", margin: "25px" }}>
                <Grid item xs={12}>
                  <h3>
                    Não foi encontrado serviços com os filtros selecionados.
                  </h3>
                </Grid>
              </Grid>
            ) : (
              <>
                {servicos.map((servico) => (
                  <Grid item xs={12}>
                    <Paper
                      style={{
                        padding: "12px",
                        width: "100%",
                      }}
                      key={servico.idServico}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12} style={{ textAlign: "left" }}>
                          <Typography fontWeight={"bold"} variant="h5">
                            {servico.nomeServico}
                          </Typography>
                        </Grid>

                        <Grid item xs={2} style={{ textAlign: "left" }}>
                          <MonetizationOnIcon />
                        </Grid>

                        <Grid item xs={10} style={{ textAlign: "left" }}>
                          <Typography fontWeight={"bold"}>
                            R${formatarDinheiro(servico.valorServico)}
                          </Typography>
                        </Grid>

                        <Grid item xs={2} style={{ textAlign: "left" }}>
                          <AccountBoxIcon />
                        </Grid>

                        <Grid item xs={10} style={{ textAlign: "left" }}>
                          {servico.nomeResponsavel}
                        </Grid>

                        <Grid item xs={2} style={{ textAlign: "left" }}>
                          <TodayIcon />
                        </Grid>

                        <Grid item xs={10} style={{ textAlign: "left" }}>
                          Prev. Inicio:{" "}
                          {dayjs(servico.dataInicio).format("DD/MM/YYYY")}
                        </Grid>

                        <Grid item xs={2} style={{ textAlign: "left" }}>
                          <TodayIcon />
                        </Grid>

                        <Grid item xs={10} style={{ textAlign: "left" }}>
                          Prev. Fim:{" "}
                          {dayjs(servico.dataEntrega).format("DD/MM/YYYY")}
                        </Grid>

                        <Grid item xs={12} style={{ textAlign: "left" }}>
                          {getStatusServicoOS(servico.status)}
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                ))}
              </>
            )}

            <Grid item xs={12} style={{ textAlign: "center" }}>
              <GridCenter>
                <Pagination
                  size={"large"}
                  defaultPage={1}
                  count={totalLinhas / linhasPorPagina}
                  onChange={(event, number) => setPagina(number)}
                  page={pagina}
                />
              </GridCenter>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <TableGeneric
            sx={{ display: { xs: "none", md: "flex" } }}
            pageSize={linhasPorPagina}
            colunas={columns}
            linhas={servicos}
            loading={loading}
            getRowId={(item) =>
              `${item.idOrdemServico}${item.idOsServico}${item.idServico}`
            }
            totalLinhas={totalLinhas}
            selecionaLinha={false}
            formasDeListar={[5, 10]}
            setPaginacao={(model) => {
              setPagina(model.page);
              setLinhasPorPagina(model.pageSize);
            }}
          />
        </Grid>
        {servicoOsSelecionado && openModalRecebimentoServico && (
          <ModalRecebimentoServico
            onClose={() => {
              setServicoOsSelecionado(null);
              buscarServicos();
              setOpenModalRecebimentoServico(false);
            }}
            open={Boolean(servicoOsSelecionado) && openModalRecebimentoServico}
            dadosServico={servicoOsSelecionado}
          />
        )}
        {servicoOsSelecionado && openModalEditarServicoOS && (
          <ModalEditarServicoOS
            onClose={() => {
              setServicoOsSelecionado(null);
              buscarServicos();
              setOpenModalEditarServicoOs(false);
            }}
            open={Boolean(servicoOsSelecionado) && openModalEditarServicoOS}
            servico={servicoOsSelecionado}
          />
        )}
      </Grid>
    </Container>
  );
};

export default ServicoPorUsuarioPage;
