import React, { useCallback } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Paper,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CamposBriefing from "../../model/entities/ConfiguracaoBriefing";
import {
  TIPO_CAMPO_NUMERICO,
  TIPO_CAMPO_SELECAO,
  TIPO_CAMPO_SELECAO_UNICA,
  TIPO_CAMPO_TEXTO,
  TIPO_CAMPO_VALOR_MONETARIO,
} from "../../constants";

interface ItemDaListaProps {
  campo: CamposBriefing;
  index: number;
  removerCampo: (index: number) => void;
  handleInputChange: (
    index: number,
    property: keyof CamposBriefing,
    valor: string | boolean
  ) => void;
}

const CampoCfgBriefing: React.FC<ItemDaListaProps> = React.memo(
  ({ campo, removerCampo, index, handleInputChange }) => {
    const remover = useCallback(() => {
      removerCampo(index);
    }, [index, removerCampo]);

    return (
      <Grid item xs={12} key={index}>
        <Paper elevation={5} style={{ padding: "5px" }}>
          <div style={{ display: "flex" }}>
            <Grid item xs={6} style={{ margin: "5px" }}>
              {index + 1}º Campo
            </Grid>

            <Grid item xs={6} style={{ textAlign: "right" }}>
              <IconButton
                onClick={() => {
                  remover();
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </div>
          <Grid container spacing={3} style={{ padding: "15px" }}>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Nome do Campo"
                value={campo.nome}
                onChange={(e) =>
                  handleInputChange(index, "nome", e.target.value)
                }
                required
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Obrigatório</InputLabel>
                <Select
                  value={campo.obrigatorio ? "sim" : "não"}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "obrigatorio",
                      e.target.value === "sim"
                    )
                  }
                  label="Obrigatório"
                  required
                >
                  <MenuItem value="sim">Sim</MenuItem>
                  <MenuItem value="não">Não</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Tipo</InputLabel>
                <Select
                  value={campo.tipo}
                  onChange={(e) =>
                    handleInputChange(index, "tipo", e.target.value)
                  }
                  label="Tipo"
                  required
                >
                  <MenuItem value={TIPO_CAMPO_TEXTO}>Texto</MenuItem>
                  <MenuItem value={TIPO_CAMPO_NUMERICO}>Número</MenuItem>
                  <MenuItem value={TIPO_CAMPO_SELECAO}>Seleção</MenuItem>
                  <MenuItem value={TIPO_CAMPO_SELECAO_UNICA}>
                    Seleção Única
                  </MenuItem>
                  <MenuItem value={TIPO_CAMPO_VALOR_MONETARIO}>
                    Valor Monetário
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {campo.tipo === TIPO_CAMPO_SELECAO ||
              (TIPO_CAMPO_SELECAO_UNICA && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Opções (separadas por vírgula)"
                    value={campo.options}
                    onChange={(e) =>
                      handleInputChange(index, "options", e.target.value)
                    }
                    required
                  />
                </Grid>
              ))}
          </Grid>
        </Paper>
      </Grid>
    );
  }
);

export default CampoCfgBriefing;
