import {
  DadosConfirmarRecuperacaoSenha,
  DadosEnvioEmaiLRecuperacaoSenha,
  DadosParaRecuperarSenha,
} from "../model/entities/RecuperarSenha";
import api from "./api";

const ROUTE = "recuperarSenha";

class recuperarSenhaApi {
  async enviarSolicitacaoRecuperacaoSenha(dados: DadosParaRecuperarSenha) {
    try {
      const res = await api.post<DadosEnvioEmaiLRecuperacaoSenha>(
        `/${ROUTE}`,
        dados
      );
      if (res) {
        const data = res.data;
        return data;
      }
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async confirmarRecuperacaoSenha(dados: DadosConfirmarRecuperacaoSenha) {
    try {
      const res = await api.post<Boolean>(`/${ROUTE}/confirmar`, dados);
      if (res) {
        const data = res.data;
        return data;
      }
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

export default new recuperarSenhaApi();
