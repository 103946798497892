import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Typography, Button, Grid, Backdrop } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Container from "../../components/Generics/Container";
import { useSnack } from "../../redux/snack/snackHooks";

import configuracaoBriefingApi from "../../api/camposBriefingApi";
import { toast } from "react-toastify";
import CamposBriefing from "../../model/entities/ConfiguracaoBriefing";
import { ModeloBriefing } from "../../model/entities/ModeloBriefing";
import { useLocation, useNavigate } from "react-router-dom";
import TextFieldGeneric from "../../components/Generics/TextFieldGeneric";
import { Save } from "@mui/icons-material";
import CampoCfgBriefing from "./CampoCfgBriefing";
import GridCenter from "../../components/Generics/GridCenter";
import Loader from "../../components/Generics/Loader";

const ConfiguracaoBriefingPage = () => {
  const [campos, setCampos] = useState<CamposBriefing[]>([]);
  const [titulo, setTitulo] = useState("");
  const [descricao, setDescricao] = useState("");
  const mostrarSnack = useSnack();
  const [loading, setLoading] = useState(false);
  const divRolavelRef = useRef(null);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [mensagemLoading, setMensagemLoading] = useState("");

  const modeloSelecionado = state as ModeloBriefing;

  const [novoCampo, setNovoCampo] = useState<CamposBriefing>({
    id: 0,
    nome: "",
    tipo: "TEXT",
    label: "",
    obrigatorio: false,
    options: "",
    idModelo: modeloSelecionado ? modeloSelecionado.id : 0,
    descricaoModelo: modeloSelecionado
      ? modeloSelecionado.descricao
      : descricao,
    tituloModelo: modeloSelecionado ? modeloSelecionado.titulo : titulo,
  });

  useEffect(() => {
    if (modeloSelecionado) buscarCampos();
  }, []);

  const buscarCampos = async () => {
    try {
      setMensagemLoading("Buscando informações do formulario de briefing");
      if (!modeloSelecionado) return;
      setLoading(true);
      const res = await configuracaoBriefingApi.find(
        modeloSelecionado.id.toString()
      );
      setCampos(res);
      setLoading(false);
    } catch (erro: any) {
      setLoading(false);
      mostrarSnack(
        "Aconteceu um erro ao buscar as configurações do formulário",
        "error"
      );
    }
  };

  const adicionarCampo = () => {
    setCampos([...campos, novoCampo]);
    setNovoCampo({
      id: campos.length + 1,
      nome: "",
      tipo: "text",
      label: "",
      obrigatorio: false,
      options: "",
      idModelo: modeloSelecionado ? modeloSelecionado.id : 0,
      descricaoModelo: modeloSelecionado
        ? modeloSelecionado.descricao
        : descricao,
      tituloModelo: modeloSelecionado ? modeloSelecionado.titulo : titulo,
    });
    window.scrollTo({
      top: document.body.scrollHeight + 1000,
      left: 0,
      behavior: "smooth", // opcional: para rolagem suave
    });
  };

  const removerCampo = useCallback((index: number) => {
    setCampos((prevCampos) => prevCampos.filter((_, i) => i !== index));
  }, []);

  const handleInputChange = useCallback(
    <T extends keyof CamposBriefing>(
      index: number,
      property: T,
      value: CamposBriefing[T]
    ) => {
      setCampos((prevCampos) =>
        prevCampos.map((campo, i) =>
          i === index ? { ...campo, [property]: value } : campo
        )
      );
    },
    []
  );

  const salvarConfiguracao = async () => {
    try {
      setLoading(true);
      setMensagemLoading("Salvando configurações do formulario de briefing");
      const camposAtualizados = [];

      let index = 1;
      for (const campo of campos) {
        if (!campo.nome) {
          mostrarSnack(`O ${index}º campo está sem nome.`, "error");
          setLoading(false);
          break;
        }

        camposAtualizados.push({
          ...campo,
          tituloModelo: titulo,
          descricaoModelo: descricao,
        });
        index += 1;
      }
      if (campos.length !== camposAtualizados.length) return;
      const resolveAfter3Sec =
        configuracaoBriefingApi.insert(camposAtualizados);

      toast.promise(resolveAfter3Sec, {
        pending: {
          render() {
            return "Configurando formulário";
          },
        },
        success: {
          render(params) {
            navigate(`/briefing/modelo/lista`);
            return "Formulário configurado";
          },
        },
        error: {
          render: (params: any) => {
            if (!params) {
              return "Dados indisponíveis";
            }
            console.log("params", params);
            return `${params.data.data}`;
          },
        },
      });
      setLoading(false);
    } catch (erro: any) {
      setLoading(false);
      console.log("Erro ao configurar formulário", erro);
    }
  };

  return (
    <div ref={divRolavelRef}>
      <Container page>
        <Backdrop open={loading} onClick={() => {}}>
          <GridCenter>
            <Grid
              item
              xs={12}
              style={{
                color: "white",
                textAlign: "center",
              }}
            >
              <h2>{mensagemLoading}</h2>
            </Grid>

            <Grid item xs={12} style={{ textAlign: "center", display: "flex" }}>
              <Loader color="white" />
            </Grid>
          </GridCenter>
        </Backdrop>

        <Grid container spacing={2}>
          <div style={{ textAlign: "center" }}>
            <Typography variant="h4" gutterBottom>
              {!modeloSelecionado
                ? "Criação de novo formulário de Briefing"
                : `Configuração do formulário de Briefing - ${modeloSelecionado.titulo}`}
            </Typography>
          </div>
          <Grid item xs={12}>
            <TextFieldGeneric
              label={"Nome do Formulário"}
              value={titulo}
              onChange={(value) => {
                setTitulo(value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextFieldGeneric
              label={"Descrição do formulário"}
              minRows={5}
              value={descricao}
              onChange={(value) => {
                setDescricao(value);
              }}
            />
          </Grid>

          {!loading && (
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                variant="contained"
                startIcon={<AddCircleOutlineIcon />}
                onClick={adicionarCampo}
              >
                Adicionar Campo
              </Button>
            </Grid>
          )}

          {campos && campos.length > 0 && (
            <>
              {campos.map((campo, index) => (
                <CampoCfgBriefing
                  key={index}
                  campo={campo}
                  index={index}
                  removerCampo={removerCampo}
                  handleInputChange={handleInputChange}
                />
              ))}
            </>
          )}

          {campos.length >= 3 && !loading && (
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                variant="contained"
                startIcon={<AddCircleOutlineIcon />}
                onClick={adicionarCampo}
              >
                Adicionar Campo
              </Button>
            </Grid>
          )}
        </Grid>

        {!loading && (
          <Grid item xs={12} style={{ textAlign: "center", marginTop: "15px" }}>
            <Button
              variant="contained"
              startIcon={<Save />}
              onClick={salvarConfiguracao}
            >
              Salvar formulário
            </Button>
          </Grid>
        )}
      </Container>
    </div>
  );
};

export default ConfiguracaoBriefingPage;
