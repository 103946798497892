import { Doughnut } from "react-chartjs-2";
import { Grid } from "@mui/material";
import { Container } from "@material-ui/core";
import { Transacao } from "../../model/entities/Transacao";
import Titulo3 from "../../components/Generics/Titulo3";
import { formatarDinheiro } from "../../helper";
import {
  TIPO_LANCAMENTO_CAIXA_CREDITO,
  TIPO_LANCAMENTO_CAIXA_DEBITO,
} from "../../constants";

type Props = {
  transacoes: Transacao[];
};

const DoughnutTransacoes: React.FC<Props> = (props) => {
  const { transacoes } = props;

  const transacoesCredito = transacoes.filter(
    (transacao) => transacao.tipoTransacao === TIPO_LANCAMENTO_CAIXA_CREDITO
  );

  const transacoesDebito = transacoes.filter(
    (transacao) => transacao.tipoTransacao === TIPO_LANCAMENTO_CAIXA_DEBITO
  );

  const totalCredito = transacoesCredito.reduce(
    (soma, transacao) => soma + transacao.valor,
    0
  );

  const totalDebito = transacoesDebito.reduce(
    (soma, transacao) => soma + transacao.valor,
    0
  );

  const totalGeral = totalCredito + totalDebito;

  const percentualCredito = ((totalCredito / totalGeral) * 100).toFixed(2);
  const percentualDebito = ((totalDebito / totalGeral) * 100).toFixed(2);
  const percentualDespesas = ((totalDebito / totalCredito) * 100).toFixed(2);

  const data = {
    labels: ["Crédito", "Débito"],
    datasets: [
      {
        data: [totalCredito, totalDebito],
        backgroundColor: ["#66BB6A", "#EF5350"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Grid item xs={12} md={6}>
      <Container style={{ textAlign: "center" }}>
        <Titulo3>Totalizador de Transações</Titulo3>
        <Doughnut data={data} />
        <div style={{ marginTop: "10px", color: "black" }}>
          <div style={{ display: "inline-block", marginRight: "10px" }}>
            Receitas: R$ {formatarDinheiro(totalCredito)}
          </div>
          <div style={{ display: "inline-block" }}>
            Despesas: R$ {formatarDinheiro(totalDebito)}
            {"  |  "}
            {}
            <span style={{ textDecoration: "underline" }}>
              {percentualDespesas === "Infinity" ? (
                100
              ) : (
                <>{Number(percentualDespesas) ? percentualDespesas : 0} </>
              )}{" "}
              % das receitas
            </span>
          </div>
        </div>
      </Container>
    </Grid>
  );
};

export default DoughnutTransacoes;
