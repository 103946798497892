import {
  Alert,
  Button,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import LinearProgress from "@mui/joy/LinearProgress";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import osApi from "../../../api/osApi";
import { DadosOS } from "../../../model/types/OSTypes";
import Container from "../../../components/Generics/Container";
import Info from "../../../components/Generics/Info";
import dayjs from "dayjs";
import UsuarioField from "../../../components/fields/UsuarioField";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";

import TextFieldGeneric from "../../../components/Generics/TextFieldGeneric";
import StatusServicoOSField from "../../../components/fields/StatusServicoOSField";
import { SaveOutlined } from "@mui/icons-material";
import NumberFieldGeneric from "../../../components/Generics/NumberFieldGeneric";
import DatePickerGeneric from "../../../components/Generics/DatePickerGeneric";
import { DadosServicoOS } from "../../../model/types/ServicoOSTypes";
import ModalRecebimentoServico from "../../../components/modais/ModalRecebimentoServico";
import { formatarDinheiro } from "../../../helper";
import { ACESSO_OS_TODAS, ACESSO_OS_VINCULADA } from "../../../constants";
import { useSessao } from "../../../redux/sessao/sessaoHooks";
import { Usuario } from "../../../model/entities/Usuario";
import usuarioApi from "../../../api/usuarioApi";
import Titulo2 from "../../../components/Generics/Titulo2";
import { formatMilliseconds } from "../../../helpers/helper";
import { useSnack } from "../../../redux/snack/snackHooks";
import KanbanBoard from "../../../components/Generics/KanbanBoard";

const OSPage: React.FC = () => {
  const mostrarSnack = useSnack();

  const { osId } = useParams();
  const [os, setOs] = useState<DadosOS | null>(null);
  const [loading, setLoading] = useState(true);
  const [expandir, setExpandir] = useState<boolean[]>([]);
  const [sessao] = useSessao();
  const [usuario] = useState<Usuario | null>(sessao.usuario);

  const [, setUsuarios] = useState<Usuario[]>([]);
  const [openModalRecebimento, setOpenModalRecebimento] = useState(false);
  const [servicoSelecionadoReceber, setServicoSelecionadoReceber] =
    useState<DadosServicoOS | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (osId && Number(osId) > 0) {
      buscarOS();
      buscarUsuarios();
    } else {
      setLoading(false);
    }
  }, [osId]);

  const buscarOS = async () => {
    setLoading(true);
    try {
      if (!osId) return;
      const res = await osApi.findById(osId);
      setOs(res);
      setLoading(false);
    } catch (erro: any) {
      setLoading(false);
      setLoading(false);

      mostrarSnack("Não foi possível buscar a ordem de serviço", "error");
      console.log("Aconteceu um erro ao buscar OS", erro);
    }
  };

  const salvar = async () => {
    setLoading(true);
    try {
      if (!os) return;
      await osApi.update(os);

      setLoading(false);
      mostrarSnack("Informações atualizadas", "success");
      navigate("/ordemServico/listagem");
    } catch (erro: any) {
      setLoading(false);
      setLoading(false);

      mostrarSnack(
        "Aconteceu um erro ao atualizar informaçãos da ordem de serviço",
        "error"
      );
      console.log(
        "Aconteceu um erro ao atualizar informaçãos da ordem de serviço",
        erro
      );
    }
  };

  const buscarUsuarios = async () => {
    try {
      const res = await usuarioApi.getManyComplete();
      setUsuarios(res);
    } catch (erro: any) {
      mostrarSnack("Erro ao buscar lista de usuarios", "error");
      console.log("erro ao buscar usuarios", erro);
    }
  };

  const renderServicosFilhos = (servico: DadosServicoOS, indexPai: number) => {
    if (os && !loading)
      return (
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Info label="Valor do serviço">
              {formatarDinheiro(servico.valorServico)}
            </Info>
          </Grid>

          {servico.valorPago > 0 && (
            <Grid item md={6} xs={12}>
              <Info label="Valor já pago">
                {formatarDinheiro(servico.valorPago)}
              </Info>
            </Grid>
          )}

          {servico.filhos.map((filho, indexFilho) => (
            <Paper
              elevation={5}
              style={{
                padding: "25px",
                margin: "5px",
                minWidth: "100%",
                borderRadius: "25px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Titulo2>{filho.tituloServicoFilho}</Titulo2>
                </Grid>

                <Grid item md={6} xs={12}>
                  <UsuarioField
                    label="Resposável"
                    disabled={
                      ACESSO_OS_VINCULADA.descricaoEnum ===
                      usuario?.perfil?.acessoOSPerfil
                    }
                    idUsuario={filho.idResponsavel}
                    usuario={null}
                    setUsuario={(obj) => {
                      if (obj) {
                        const novosDados = [...os.servicoOS];
                        novosDados[indexPai].filhos[indexFilho].idResponsavel =
                          obj.id;

                        setOs({ ...os, servicoOS: novosDados });
                      }
                    }}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <StatusServicoOSField
                    codigoStatus={filho.status}
                    setStatus={(obj) => {
                      if (obj) {
                        const novosDados = [...os.servicoOS];
                        novosDados[indexPai].filhos[indexFilho].status = obj.id;
                        setOs({ ...os, servicoOS: novosDados });
                      }
                    }}
                  />
                </Grid>

                <Grid item md={2} xs={12}>
                  <DatePickerGeneric
                    label={"Data inicio"}
                    value={dayjs(filho.dataInicio)}
                    setValue={(value) => {
                      const novosDados = [...os.servicoOS];
                      novosDados[indexPai].filhos[indexFilho].dataInicio =
                        value.toDate();
                      setOs({ ...os, servicoOS: novosDados });
                    }}
                  />
                </Grid>

                <Grid item md={2} xs={12}>
                  <DatePickerGeneric
                    label={"Data entrega"}
                    value={dayjs(filho.dataEntrega)}
                    setValue={(value) => {
                      const novosDados = [...os.servicoOS];
                      novosDados[indexPai].filhos[indexFilho].dataEntrega =
                        value.toDate();

                      setOs({ ...os, servicoOS: novosDados });
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextFieldGeneric
                    label="Observação"
                    minRows={4}
                    value={filho.observacao}
                    onChange={(value) => {
                      const novosDados = [...os.servicoOS];
                      novosDados[indexPai].filhos[indexFilho].observacao =
                        value;

                      setOs({ ...os, servicoOS: novosDados });
                    }}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <Info
                    label={`${
                      servico.status === 2
                        ? "Tempo gasto na excução do serviço"
                        : `Tempo gasto na execução até o momento`
                    }`}
                    children={formatMilliseconds(filho.tempoDecorrido)}
                  ></Info>
                </Grid>
              </Grid>
            </Paper>
          ))}

          {servico.valorPago < servico.valorServico && (
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                children={"Fazer recebimento deste serviço"}
                onClick={() => {
                  setOpenModalRecebimento(true);
                  setServicoSelecionadoReceber(servico);
                }}
              />
            </Grid>
          )}
        </Grid>
      );
  };

  const tempoGastoNaOs =
    os && os.servicoOS
      ? os.servicoOS.reduce((acc, servico) => {
          const tempoFilhos = servico.filhos.reduce(
            (accFilho, filho) => accFilho + filho.tempoDecorrido,
            0
          );
          return acc + servico.tempoDecorrido + tempoFilhos;
        }, 0)
      : 0;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container>
        {loading && (
          <Grid item xs={12}>
            <h2>Carregando OS . . .</h2>
            <LinearProgress color="primary" size="lg" variant="plain" />
          </Grid>
        )}

        {os && !loading && (
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              style={{ textAlign: "center", textDecoration: "underline" }}
            >
              <h2> Ordem de serviço #{os.id}</h2>
            </Grid>

            <Grid item xs={6}>
              <Info label="Cliente">{os.cliente.nome}</Info>
            </Grid>

            {os.cliente.cpf && os.cliente.cpf !== "11111111111" && (
              <Grid item xs={3}>
                <Info label="CPF">{os.cliente.cpf}</Info>
              </Grid>
            )}

            {os.cliente.cnpj && os.cliente.cnpj !== "11111111111111" && (
              <Grid item xs={3}>
                <Info label="CNPJ">{os.cliente.cnpj}</Info>
              </Grid>
            )}

            <Grid item xs={6}>
              <Info label="Data OS">
                {dayjs(os.dataOs).format("DD/MM/YYYY")}
              </Info>
            </Grid>

            {tempoGastoNaOs > 0 && (
              <Grid item xs={6}>
                <Info label="Tempo de trabalho na ordem de serviço">
                  {formatMilliseconds(tempoGastoNaOs)}
                </Info>
              </Grid>
            )}

            <Grid item xs={6} />

            <Grid item xs={12} style={{ overflow: "auto" }}>
              {os.servicoOS.map((servico, index) => {
                const podeAccessarServico =
                  (ACESSO_OS_VINCULADA.descricaoEnum ===
                    usuario?.perfil?.acessoOSPerfil &&
                    servico.idResponsavel === usuario.id) ||
                  ACESSO_OS_TODAS.descricaoEnum ===
                    usuario?.perfil?.acessoOSPerfil;

                return (
                  <Container style={{ marginBottom: "15px" }}>
                    <Grid item xs={12}>
                      <IconButton
                        onClick={() => {
                          if (podeAccessarServico) {
                            const newValue = { ...expandir };
                            newValue[index] = !expandir[index];
                            setExpandir(newValue);
                          }
                        }}
                      >
                        {expandir[index] ? (
                          <KeyboardArrowUpOutlinedIcon />
                        ) : (
                          <>
                            {podeAccessarServico && (
                              <KeyboardArrowDownOutlinedIcon />
                            )}
                          </>
                        )}

                        <Typography component={"h2"} fontSize={22}>
                          {servico.nomeServico}
                        </Typography>
                      </IconButton>

                      {!podeAccessarServico && (
                        <>
                          {"Você não tem permissão para acessar este serviço"}
                        </>
                      )}

                      <Collapse in={expandir[index]}>
                        {servico.filhos.length > 0 ? (
                          renderServicosFilhos(servico, index)
                        ) : (
                          <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                              <UsuarioField
                                label="Resposável"
                                disabled={
                                  ACESSO_OS_VINCULADA.descricaoEnum ===
                                  usuario?.perfil?.acessoOSPerfil
                                }
                                idUsuario={servico.idResponsavel}
                                usuario={null}
                                setUsuario={(obj) => {
                                  if (obj && obj.id) {
                                    const novosDados = [...os.servicoOS];
                                    const dados = novosDados[index];
                                    dados.idResponsavel = obj.id;
                                    novosDados[index] = dados;
                                    setOs({ ...os, servicoOS: novosDados });
                                  }
                                }}
                              />
                            </Grid>

                            <Grid item md={6} xs={12}>
                              <StatusServicoOSField
                                codigoStatus={servico.status}
                                setStatus={(obj) => {
                                  if (obj) {
                                    const novosDados = [...os.servicoOS];
                                    const dados = novosDados[index];
                                    dados.status = obj.id;
                                    novosDados[index] = dados;
                                    setOs({ ...os, servicoOS: novosDados });
                                  }
                                }}
                              />
                            </Grid>

                            <Grid item md={6} xs={12}>
                              <NumberFieldGeneric
                                type="money"
                                label="Valor do serviço"
                                value={servico.valorServico}
                                disabled
                                onChange={() => {}}
                              />
                            </Grid>

                            <Grid item md={2} xs={12}>
                              <DatePickerGeneric
                                label={"Data inicio"}
                                value={dayjs(servico.dataInicio)}
                                setValue={(value) => {
                                  const novosDados = [...os.servicoOS];
                                  const dados = novosDados[index];
                                  dados.dataInicio = value.toDate();
                                  novosDados[index] = dados;
                                  setOs({ ...os, servicoOS: novosDados });
                                }}
                              />
                            </Grid>

                            <Grid item md={2} xs={12}>
                              <DatePickerGeneric
                                label={"Data entrega"}
                                value={dayjs(servico.dataEntrega)}
                                setValue={(value) => {
                                  const novosDados = [...os.servicoOS];
                                  const dados = novosDados[index];
                                  dados.dataEntrega = value.toDate();
                                  novosDados[index] = dados;
                                  setOs({ ...os, servicoOS: novosDados });
                                }}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <TextFieldGeneric
                                label="Observação"
                                minRows={4}
                                value={servico.observacao}
                                onChange={(value) => {
                                  const novosDados = [...os.servicoOS];
                                  const dados = novosDados[index];
                                  dados.observacao = value;
                                  novosDados[index] = dados;
                                  setOs({ ...os, servicoOS: novosDados });
                                }}
                              />
                            </Grid>

                            <Grid item md={12} xs={12}>
                              <Info
                                label={`${
                                  servico.status === 2
                                    ? "Tempo gasto na excução do serviço"
                                    : `Tempo gasto na execução até o momento`
                                }`}
                                children={formatMilliseconds(
                                  servico.tempoDecorrido
                                )}
                              ></Info>
                            </Grid>

                            {servico.valorPago > 0 &&
                              servico.valorServico - servico.valorPago > 0 && (
                                <Grid item xs={12}>
                                  <Info
                                    label={"Valor já pago"}
                                    children={formatarDinheiro(
                                      servico.valorPago
                                    )}
                                  ></Info>
                                </Grid>
                              )}

                            {servico.valorPago > 0 &&
                              servico.valorServico - servico.valorPago > 0 &&
                              servico.valorPago !== servico.valorServico && (
                                <Grid item xs={12}>
                                  <Info
                                    label={"Valor restante"}
                                    children={formatarDinheiro(
                                      servico.valorServico - servico.valorPago
                                    )}
                                  ></Info>
                                </Grid>
                              )}

                            {servico.valorPago > 0 &&
                              servico.valorPago >= servico.valorServico && (
                                <Grid item xs={12}>
                                  <Alert
                                    elevation={6}
                                    variant="filled"
                                    severity={"info"}
                                  >
                                    {"O serviço já foi pago."}
                                  </Alert>
                                </Grid>
                              )}

                            {servico.valorPago < servico.valorServico && (
                              <Grid
                                item
                                xs={12}
                                style={{ textAlign: "center" }}
                              >
                                <Button
                                  variant="contained"
                                  children={"Fazer recebimento deste serviço"}
                                  onClick={() => {
                                    setOpenModalRecebimento(true);
                                    setServicoSelecionadoReceber(servico);
                                  }}
                                />
                              </Grid>
                            )}
                          </Grid>
                        )}
                      </Collapse>
                    </Grid>
                  </Container>
                );
              })}
            </Grid>

            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                startIcon={<SaveOutlined />}
                variant="contained"
                onClick={salvar}
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        )}

        {openModalRecebimento && servicoSelecionadoReceber && (
          <ModalRecebimentoServico
            open={openModalRecebimento && Boolean(servicoSelecionadoReceber)}
            dadosServico={servicoSelecionadoReceber}
            onClose={() => {
              setServicoSelecionadoReceber(null);
              setOpenModalRecebimento(false);
              buscarOS();
            }}
          />
        )}
      </Container>
    </div>
  );
};

export default OSPage;
