import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import ModalGeneric from "../Generics/ModalGeneric";
import { useSnack } from "../../redux/snack/snackHooks";
import { useState } from "react";
import { makeStyles } from "@material-ui/core";
import Titulo3 from "../Generics/Titulo3";
import ButtonGeneric from "../Generics/ButtonGeneric";
import { Send } from "@mui/icons-material";
import {
  MENSAGEM_CODIGO_VERIFICACAO_RECUPERACAO_SENHA_INVALIDO,
  MENSAGEM_TEMPO_RECUPERACAO_SENHA_EXCEDIDO,
  MENSAGEM_USUARIO_SEM_MAIL_PARA_RECUPERAR_SENHA,
} from "../../constants";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { DadosEnvioEmaiLRecuperacaoSenha } from "../../model/entities/RecuperarSenha";
import recuperarSenhaApi from "../../api/recuperarSenhaApi";

type Props = {
  open: boolean;
  onClose: () => void;
  dadosEmailEnviado: DadosEnvioEmaiLRecuperacaoSenha | null;
};

const ModalConfirmarTrocaSenha: React.FC<Props> = (props) => {
  const { open, onClose, dadosEmailEnviado } = props;

  const mostrarSnack = useSnack();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const [verSenha, setVerSenha] = useState(false);
  const [codigoVerificacao, setCodigoVerificacao] = useState("");
  const [novaSenha, setNovaSenha] = useState("");
  const [novaSenhaConfirma, setNovaSenhaSetNovaSenhaConfirma] = useState("");

  if (!dadosEmailEnviado) return null;

  const confirmarTrocaSenha = async () => {
    try {
      if (!novaSenha || novaSenha === "") {
        mostrarSnack("Informe a senha!", "error");
        return;
      }

      if (!novaSenhaConfirma || novaSenhaConfirma === "") {
        mostrarSnack("Informe a confirmação de senha!", "error");
        return;
      }

      if (novaSenha != novaSenhaConfirma) {
        mostrarSnack(
          "As senhas são diferentes! Por favor, revise a nova senha e a senha de confimação",
          "error"
        );
        return;
      }

      setLoading(true);
      await recuperarSenhaApi.confirmarRecuperacaoSenha({
        codigoVerificacao: codigoVerificacao,
        id: dadosEmailEnviado.id,
        novaSenha: novaSenha,
      });
      setLoading(false);
      mostrarSnack("Senha alterada com sucesso!", "success");
      onClose();
    } catch (erro: any) {
      setLoading(false);

      if (
        erro.data === MENSAGEM_CODIGO_VERIFICACAO_RECUPERACAO_SENHA_INVALIDO
      ) {
        mostrarSnack(
          MENSAGEM_CODIGO_VERIFICACAO_RECUPERACAO_SENHA_INVALIDO,
          "error"
        );
        return;
      }
      if (erro.data === MENSAGEM_TEMPO_RECUPERACAO_SENHA_EXCEDIDO) {
        mostrarSnack(MENSAGEM_TEMPO_RECUPERACAO_SENHA_EXCEDIDO, "error");
        return;
      }
      setLoading(false);
      mostrarSnack("Erro ao enviar código", "error");
    }
  };

  return (
    <ModalGeneric open={open} onClose={onClose}>
      <Grid item xs={12} md={8} style={{ textAlign: "center" }}>
        <Titulo3>Confirme o código de verificação enviado</Titulo3>
      </Grid>

      <Grid item xs={12} md={8} style={{ textAlign: "center" }}>
        <h4>O email foi enviado para {dadosEmailEnviado.emailEnvio}</h4>
      </Grid>

      <Grid item xs={12} md={8} style={{ margin: "0px 15px" }}>
        <TextField
          className={classes.input}
          style={{ marginBottom: "25px" }}
          label="Código de verificação"
          value={codigoVerificacao}
          onChange={(event) => {
            setCodigoVerificacao(event.target.value);
          }}
          disabled={loading}
        />
      </Grid>

      <Grid item xs={12} md={8} style={{ margin: "0px 15px" }}>
        <TextField
          className={classes.input}
          label="Nova senha"
          style={{ marginBottom: "25px" }}
          value={novaSenha}
          onChange={(event) => setNovaSenha(event.target.value)}
          type={verSenha ? "text" : "password"}
          disabled={loading}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setVerSenha(!verSenha)}
                >
                  {verSenha ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} md={8} style={{ margin: "0px 15px" }}>
        <TextField
          className={classes.input}
          label="Confirmação da nova senha"
          style={{ marginBottom: "25px" }}
          value={novaSenhaConfirma}
          onChange={(event) =>
            setNovaSenhaSetNovaSenhaConfirma(event.target.value)
          }
          type={verSenha ? "text" : "password"}
          disabled={loading}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setVerSenha(!verSenha)}
                >
                  {verSenha ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} style={{ margin: "0px 15px", textAlign: "center" }}>
        <ButtonGeneric
          label={"Confirmar"}
          startIcon={<Send />}
          onClick={confirmarTrocaSenha}
        />
      </Grid>
    </ModalGeneric>
  );
};

export default ModalConfirmarTrocaSenha;
const useStyles = makeStyles(() => ({
  input: {
    width: "100%",
    minWidth: "350px",
    marginBottom: "35px",
    "@media (max-width: 768px)": {
      minWidth: "400px",
      marginBottom: "20px",
    },
  },
  button: {
    backgroundColor: "#3a7bd5 !important",
    color: "#fff !important",
    "&:hover": {
      backgroundColor: "#306bb5 !important",
    },
  },

  footer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "auto", // Empurra o rodapé para o final
    width: "100%", // Garante a largura total
    justifyContent: "center",
    alignItems: "center",
  },
}));
