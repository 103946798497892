import React, { useEffect, useState } from "react";
import { Divider, Grid, Icon, useMediaQuery } from "@mui/material";
import {
  useClienteOrcamento,
  useDataValidadeOrcamento,
  useLimparOrcamento,
  useServicosOrcamento,
} from "../../../redux/orcamento/orcamentoHooks";
import { ArrowLeft, Done } from "@mui/icons-material";
import ButtonGeneric from "../../../components/Generics/ButtonGeneric";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { Servico } from "../../../model/entities/Servico";
import { useSnack } from "../../../redux/snack/snackHooks";
import orcamentoApi from "../../../api/orcamentoApi";
import { toast } from "react-toastify";

import DatePickerGeneric from "../../../components/Generics/DatePickerGeneric";
import Info from "../../../components/Generics/Info";
import { formatarDinheiro } from "../../../helper";
import Container from "../../../components/Generics/Container";
import theme from "../../../theme";
import ModalGeneric from "../../../components/Generics/ModalGeneric";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import ModalEnviarOrcamentoZap from "../ModalEnviarOrcamentoZap";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { useEmpresa } from "../../../redux/empresa/empresaHooks";

type Props = {
  abaAtual: number;
  setAbaAtual: (x: number) => void;
};

const PropiedadesOrcamento: React.FC<Props> = (props) => {
  const { abaAtual, setAbaAtual } = props;
  const [cliente] = useClienteOrcamento();
  const limparOrcamento = useLimparOrcamento();
  const [validadeProposta, setValidadeProposta] = useState(dayjs());

  const [servicosOrcamento] = useServicosOrcamento();
  const [dataOrcamento, setDataOrçamento] = useState(dayjs());
  const navigate = useNavigate();
  const mostrarSnack = useSnack();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openModalOrcamentoConcluido, setOpenModalOrcamentoConcluido] =
    useState(false);

  const [empresa] = useEmpresa();
  const [imprimindo, setImprimindo] = useState(false);
  const [openModalEnviarOrcamento, setOpenModalEnviarOrcamento] =
    useState(false);

  useEffect(() => {
    setValidadeProposta(dayjs().add(30, "days"));
  }, []);

  const cadastrarOrcamento = async () => {
    try {
      if (!cliente) {
        mostrarSnack(
          "Por favor, informe um cliente antes de continuar.",
          "error"
        );
        return;
      }
      if (servicosOrcamento.length === 0) {
        mostrarSnack(
          "Para finalizar o orçamento é preciso adicionar os serviços.",
          "error"
        );

        return;
      }

      const resolveAfter3Sec = orcamentoApi.insert({
        idEndereco: cliente.enderecos[0].id,
        dadosServicos: servicosOrcamento,
        dataOrcamento: dataOrcamento.toDate(),
        validadeDaProposta: validadeProposta.toDate(),
        nomeCliente: cliente.nome,
        apelidoCliente: cliente.apelido,
        dadosEndereco: cliente.enderecos[0],
      });

      toast.promise(resolveAfter3Sec, {
        pending: {
          render() {
            return "Finalizando orçamento";
          },
        },
        success: {
          render() {
            // setOpenModalOrcamentoConcluido(true);
            navigate("/orcamento/listagem");
            limparOrcamento();
            return "Orçamento finalizado";
          },
        },
        error: "Erro ao cadastrar orçamento",
      });
    } catch (erro: any) {
      mostrarSnack("Não foi possível gravar o orçamento.", "error");
      console.log(
        "Não foi possível gravar o orçamento. Orçamento não registrado",
        erro
      );
    }
  };

  const imprimir = async (idOrcamento: number) => {
    try {
      setImprimindo(true);
      await orcamentoApi.imprimir(idOrcamento);
      setImprimindo(false);
    } catch (erro: any) {
      setImprimindo(false);
      mostrarSnack(`Ops, Não foi possível geral o PDF!`, "error");
      console.log("Erro ao gerar PDF", erro);
    }
  };

  return (
    <Container>
      <Grid item xs={12}>
        <h3 style={{ fontWeight: "bold" }}>
          Detalhes do orçamento - Revise-os e finalize o orçamento
        </h3>
      </Grid>

      <Grid item xs={12}>
        <Divider style={{ background: "black" }} />
      </Grid>

      <div style={{ display: "flex" }}>
        <Grid item xs={12} md={6} style={{ margin: "15px" }}>
          <DatePickerGeneric
            label="Data do orçamento"
            pageLS="PropiedadesOrçamento"
            keyLS="dataOrcamento "
            value={dataOrcamento}
            setValue={(value) => {
              setDataOrçamento(value);
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          style={{ margin: "15px", textAlign: "right" }}
        >
          <DatePickerGeneric
            label="Validade da proposta"
            pageLS="PropiedadesOrçamento"
            keyLS="validadeProposta"
            value={validadeProposta}
            setValue={(value) => {
              setValidadeProposta(value);
            }}
          />
        </Grid>
      </div>

      <Grid item xs={12} md={12}>
        <Info label="Valor total do orçamento">
          {formatarDinheiro(
            servicosOrcamento.reduce((soma, item) => (soma += item.valor), 0)
          )}{" "}
        </Info>
      </Grid>

      <Grid item xs={12} md={12}>
        {servicosOrcamento.map((servico) => (
          <Info label="Serviço">
            {servico.titulo} -{" "}
            {formatarDinheiro(
              servicosOrcamento.reduce((soma, item) => (soma += item.valor), 0)
            )}{" "}
          </Info>
        ))}
      </Grid>

      <div style={{ display: "flex" }}>
        <Grid item xs={6} style={{ textAlign: "left", marginTop: "15px" }}>
          <ButtonGeneric
            label={"Voltar"}
            endIcon={<ArrowLeft />}
            onClick={() => {
              setAbaAtual(1);
            }}
          />
        </Grid>

        <Grid
          item
          xs={6}
          style={{
            marginTop: "15px",
            textAlign: "right",
          }}
        >
          <ButtonGeneric
            label={isSmallScreen ? "Concluir" : "Concluir orçamento"}
            style={{ color: "white", background: "green" }}
            endIcon={<Done />}
            onClick={cadastrarOrcamento}
          />
        </Grid>
      </div>

      {openModalOrcamentoConcluido && (
        <ModalGeneric
          open={openModalOrcamentoConcluido}
          onClose={() => {
            navigate("/orcamento/listagem");
          }}
        >
          <Container>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <h3 style={{ textAlign: "center", color: "green" }}>
                Orçamento concluido
                <Icon style={{ marginLeft: "10px" }}>
                  <DoneOutlineIcon />
                </Icon>
              </h3>
            </Grid>

            <Grid item xs={12} style={{ textAlign: "center", margin: "10px" }}>
              <ButtonGeneric
                label={"Imprimir Orçamento"}
                onClick={() => {
                  imprimir(10);
                }}
                startIcon={<PictureAsPdfIcon />}
              />
            </Grid>

            {empresa?.utilizaIntegracaoZap && (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <ButtonGeneric
                  label={"Enviar Orçamento por WhatsApp"}
                  onClick={() => {
                    imprimir(1);
                  }}
                  startIcon={<WhatsAppIcon />}
                />
              </Grid>
            )}
          </Container>
        </ModalGeneric>
      )}

      {/* {openModalEnviarOrcamento && orcamentoSelecionado && (
        <ModalEnviarOrcamentoZap
          dadosOrcamento={orcamentoSelecionado}
          open={Boolean(openModalEnviarOrcamento)}
          onClose={() => {
            setOrcamentoSelecionado(null);
            setOpenModalEnviarOrcamento(false);
       
          }}
        />
      )} */}
    </Container>
  );
};

export default PropiedadesOrcamento;
