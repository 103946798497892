import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { RouteType } from "../../routes/config";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import SidebarItem from "./SidebarItem";
import { useSelector } from "react-redux";
import { RootAppState } from "../../redux/store";
import { useSessao } from "../../redux/sessao/sessaoHooks";
import colorConfigs from "../../configs/colorConfigs";
import { useEmpresa } from "../../redux/empresa/empresaHooks";

type Props = {
  drawerIsOpen: boolean;
  item: RouteType;
  openMenu: string | null; // Estado para controlar qual menu está aberto
  setOpenMenu: (menu: string | null) => void; // Função para atualizar o estado do menu aberto
  closeDrawer: () => void;
};

const SidebarItemCollapse = ({
  drawerIsOpen,
  item,
  openMenu,
  setOpenMenu,
  closeDrawer,
}: Props) => {
  const { appState } = useSelector((state: RootAppState) => state.appState);
  const [sessao] = useSessao();
  const [empresa] = useEmpresa();

  const isOpen = openMenu === item.state;

  useEffect(() => {
    if (appState.includes(item.state)) {
      setOpenMenu(item.state);
    }
  }, [appState, item, setOpenMenu]);

  if (
    item &&
    item.verificarPermissao &&
    !item.verificarPermissao(sessao.usuario, empresa)
  ) {
    return null;
  }

  const handleClick = () => {
    setOpenMenu(isOpen ? null : item.state); // Se estiver aberto, feche-o, senão, abra-o
  };

  return item && item.sidebarProps ? (
    <>
      <ListItemButton
        onClick={handleClick}
        sx={{
          "&:hover": {
            backgroundColor: colorConfigs.sidebar.hoverBg,
            borderRadius: "25px",
          },
        }}
      >
        <ListItemIcon
          sx={{
            color: colorConfigs.sidebar.color,
          }}
        >
          {item.sidebarProps.icon && item.sidebarProps.icon}
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={<Typography>{item.sidebarProps.displayText}</Typography>}
        />
        {isOpen ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
      </ListItemButton>
      <Collapse in={isOpen && drawerIsOpen} timeout="auto">
        <List sx={{ paddingY: "1px" }}>
          {item.child?.map((route, index) => {
            if (
              route &&
              route.verificarPermissao &&
              !route.verificarPermissao(sessao.usuario, empresa)
            ) {
              return null;
            }

            if (route.sidebarProps) {
              return route.child ? (
                <SidebarItemCollapse
                  drawerIsOpen={drawerIsOpen}
                  closeDrawer={closeDrawer}
                  item={route}
                  openMenu={openMenu}
                  setOpenMenu={setOpenMenu}
                  key={index}
                />
              ) : (
                <SidebarItem
                  item={route}
                  key={index}
                  closeDrawer={closeDrawer}
                />
              );
            }
            return null;
          })}
        </List>
      </Collapse>
    </>
  ) : null;
};

export default SidebarItemCollapse;
