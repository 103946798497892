import * as React from "react";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { Menu, MenuItem } from "@material-ui/core";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material";
import { useSessao } from "../../redux/sessao/sessaoHooks";
import { drawerWidth } from "../../constants";
import { colors } from "../../theme";
import ButtonGeneric from "../Generics/ButtonGeneric";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useNavigate } from "react-router-dom";
import EventIcon from "@material-ui/icons/Event";

type Props = {
  drawerOpen: boolean;
  onDrawerToggle: () => void;
};
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  maxWidth: "92%",
  marginRight: 30,
  marginTop: 4,

  borderRadius: "25px",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    maxWidth: "92%",
    borderRadius: "25px",
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const HeaderAtalhos: React.FC<Props> = (props) => {
  const { onDrawerToggle } = props;

  const [ancora, setAncora] = React.useState<null | HTMLElement>(null);
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);
  const [sessao, login, revalidate, logout, trocaToken] = useSessao();
  const navigate = useNavigate();

  return (
    <AppBar
      position="fixed"
      style={{ background: colors.drawer, margin: "15px" }}
    >
      <Toolbar
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <ButtonGeneric
          variant="text"
          style={{ color: "white", marginBottom: "8px", fontSize: "18px" }}
          label={"Agenda"}
          startIcon={<EventIcon />}
          onClick={() => {
            navigate(`/agenda`);
          }}
        />

        <ButtonGeneric
          variant="text"
          style={{ color: "white", marginBottom: "8px", fontSize: "18px" }}
          label={"Novo cliente"}
          startIcon={<PersonAddIcon />}
          onClick={() => {
            navigate(`/cliente/cadastro`);
          }}
        />

        <ButtonGeneric
          variant="text"
          style={{ color: "white", marginBottom: "8px", fontSize: "18px" }}
          label={"Novo orçamento"}
          startIcon={<PersonAddIcon />}
          onClick={() => {
            navigate(`/orcamento/cadastro`);
          }}
        />

        <ButtonGeneric
          variant="text"
          style={{ color: "white", marginBottom: "8px", fontSize: "18px" }}
          label={"Nova transação"}
          startIcon={<AttachMoneyIcon />}
          onClick={() => {
            navigate(`/financeiro/caixa`);
          }}
        />

        <div>
          <Menu
            id="simple-menu"
            anchorEl={ancora}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            keepMounted
            open={menuIsOpen}
            onClose={() => setMenuIsOpen(false)}
          >
            <MenuItem
              onClick={() => {
                logout();
              }}
            >
              Sair
            </MenuItem>
          </Menu>

          <IconButton
            ref={(ref) => {
              if (ref !== null && ancora === null) {
                setAncora(ref);
              }
            }}
            color="inherit"
            onClick={() => setMenuIsOpen(true)}
          >
            <Avatar>{sessao.usuario?.nome.charAt(0).toUpperCase()}</Avatar>
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderAtalhos;
