import MiniInfo from "../components/Generics/MiniInfo";
import { STATUS_ORCAMENTO } from "../constants";
import { Orcamento } from "../model/entities/Orcamento";
import {
  DadosCadastrarOrcamentoType,
  DadosListagemOrcamento,
  DadosListagemServicoOrcamento,
  ResumoOrcamento,
} from "../model/types/OrcamentoType";
import { ResponseGenericType } from "../model/types/ResponseType";
import api from "./api";

const ROUTE = "orcamento";

class servicoApi {
  async insert(dados: DadosCadastrarOrcamentoType) {
    try {
      const res = await api.post<Orcamento>(`/${ROUTE}`, dados);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async getMany(pagina: number, linhasPorPagina: number) {
    try {
      const res = await api.get<ResponseGenericType<DadosListagemOrcamento[]>>(
        `/${ROUTE}?size=${linhasPorPagina}&page=${pagina}`
      );
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async getServicos(idOrcamento: number) {
    try {
      const res = await api.get<DadosListagemServicoOrcamento[]>(
        `/${ROUTE}/servicos/${idOrcamento}`
      );
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async count() {
    try {
      const res = await api.get<number>(`/${ROUTE}/count`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async update(dados: DadosListagemServicoOrcamento[]) {
    try {
      const res = await api.put<Orcamento>(`/${ROUTE}`, dados);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async deleteOrcamentoServico(idOrcamentoServico: number) {
    try {
      const res = await api.delete<Orcamento>(
        `/${ROUTE}/${idOrcamentoServico}`
      );
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async deleteOrcamentoServicoFilho(
    idOrcamentoServico: number,
    idOrcamentoServicoFilho: number
  ) {
    try {
      const res = await api.delete<Orcamento>(
        `/${ROUTE}/${idOrcamentoServico}/${idOrcamentoServicoFilho}`
      );
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async getResumoOrcamentos() {
    try {
      const res = await api.get<ResumoOrcamento[]>(`/${ROUTE}/resumoGrafico`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async imprimir(orcamentoId: number) {
    try {
      const res = await api.get<string>(`/${ROUTE}/imprimir/${orcamentoId}`, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      });

      const blob = new Blob([res.data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      if (/Mobi|Android/i.test(navigator.userAgent)) {
        const a = document.createElement("a");
        a.href = url;
        a.download = "orcamento.pdf";
        a.click();
      } else {
        window.open(url, "_blank");
      }
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

export default new servicoApi();

export const getStatusOrcamento = (orcamento: DadosListagemOrcamento) => {
  const status = orcamento.status;

  if (status === 0) {
    return (
      <MiniInfo color="#d6da01" style={{ fontSize: "14px", color: "black" }}>
        {STATUS_ORCAMENTO[0].decricao}
      </MiniInfo>
    );
  }
  if (status === 1) {
    return (
      <MiniInfo color="#f74a05" style={{ fontSize: "14px" }}>
        {STATUS_ORCAMENTO[1].decricao}
      </MiniInfo>
    );
  }

  if (status === 2) {
    return (
      <MiniInfo color="#0083c0" style={{ fontSize: "14px" }}>
        {STATUS_ORCAMENTO[2].decricao}
      </MiniInfo>
    );
  }

  if (status === 3) {
    return (
      <MiniInfo color="#46c000" style={{ fontSize: "14px" }}>
        {STATUS_ORCAMENTO[3].decricao}
      </MiniInfo>
    );
  }
};
