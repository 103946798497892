import React from "react";
import { Divider, Grid, useMediaQuery } from "@mui/material";
import { useClienteOrcamento } from "../../../redux/orcamento/orcamentoHooks";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import ButtonGeneric from "../../../components/Generics/ButtonGeneric";
import ServicosOrçamento from "./ServicosOrçamento";
import theme from "../../../theme";

type Props = {
  abaAtual: number;
  setAbaAtual: (x: number) => void;
};

const SelecaoServicosOrcamento: React.FC<Props> = (props) => {
  const { abaAtual, setAbaAtual } = props;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid>
      <Grid item xs={12}>
        <h3 style={{ fontWeight: "bold" }}>Serviços</h3>
      </Grid>

      <Divider style={{ background: "black" }} />

      <ServicosOrçamento />

      <div style={{ display: "flex" }}>
        <Grid item xs={6} style={{ textAlign: "left", marginTop: "15px" }}>
          <ButtonGeneric
            label={"Voltar"}
            endIcon={<ArrowLeft />}
            onClick={() => {
              setAbaAtual(0);
            }}
          />
        </Grid>

        <Grid item xs={6} style={{ textAlign: "right", marginTop: "15px" }}>
          <ButtonGeneric
            label={isSmallScreen ? "Finalizar" : "Fechar orçamento"}
            endIcon={<ArrowRight />}
            onClick={() => {
              setAbaAtual(2);
            }}
          />
        </Grid>
      </div>
    </Grid>
  );
};

export default SelecaoServicosOrcamento;
