import { Outlet, useNavigate } from "react-router-dom";
import { Box, Grid, Hidden, Toolbar } from "@mui/material";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import sizeConfigs from "../../configs/sizeConfigs";
import colorConfigs from "../../configs/colorConfigs";
import theme, { colors } from "../../theme";
import { useSessao } from "../../redux/sessao/sessaoHooks";
import LoginPage from "../../pages/Login/LoginPage";
import Drawer from "../common/Drawer";
import Copyright from "./Copyrigth";
import { useEmpresa } from "../../redux/empresa/empresaHooks";
import HeaderMobile from "./HeaderMobile";
import HeaderAtalhos from "./HeaderAtalhos";
import empresaApi from "../../api/empresaApi";
import ModalGeneric from "../Generics/ModalGeneric";
import Titulo2 from "../Generics/Titulo2";
import { useSelector } from "react-redux";
import { RootAppState } from "../../redux/store";
import { useCfgGeral } from "../../redux/cfgGeral/cfgGeralHooks";
import zapApi from "../../api/zapApi";
import CornerMenu from "../Generics/CornerMenu";

const MainLayout = () => {
  const [openSlideBar, setOpenSliderBar] = useState(false);
  const classes = useStyles();
  const [sessao] = useSessao();
  const [empresa, setEmpresa] = useEmpresa();
  const [autorizado, setAutorizado] = useState(true);
  const navigate = useNavigate();
  const [carregandoEmpresa, setCarregandoEmpresa] = useState(false);
  const { appState } = useSelector((state: RootAppState) => state.appState);
  const [cfgGeral, setCfgGeral] = useCfgGeral();

  const handleDrawerToggle = () => {
    setOpenSliderBar(!openSlideBar);
  };

  useEffect(() => {
    if (!sessao.tokenJWT) {
      setAutorizado(false);
    } else {
      setAutorizado(true);
    }
    // if (
    //   empresa &&
    //   (!empresa.cnpj ||
    //     empresa.cnpj === "null" ||
    //     !empresa.nomeResposavel ||
    //     empresa.nomeResposavel === "null" ||
    //     !empresa.nomeFantasia ||
    //     empresa.nomeFantasia === "null")
    // ) {
    //   setCarregandoEmpresa(true);
    //   try {
    //     empresaApi.getOne().then((res) => {
    //       if (
    //         res &&
    //         (!res.cnpj ||
    //           res.cnpj === "null" ||
    //           !res.nomeResposavel ||
    //           res.nomeResposavel === "null" ||
    //           !res.nomeFantasia ||
    //           res.nomeFantasia === "null")
    //       ) {
    //         navigate(`/configuracoes/empresa`);
    //       } else {
    //         setEmpresa(res);
    //       }
    //       setCarregandoEmpresa(false);
    //     });
    //   } catch (erro: any) {
    //     console.log("erro ao buscar dados da empresa");
    //   }
    // }
  }, [sessao]);

  useEffect(() => {
    if (
      empresa &&
      empresa.utilizaIntegracaoCalendar &&
      empresa.urlApiZap != ""
    ) {
      zapApi.getStatus().then((res) => {
        if (res) setCfgGeral({ ...cfgGeral, statusZap: res });
      });
    }
  }, []);

  if (!autorizado) {
    return <LoginPage />;
  }

  return (
    <div>
      {carregandoEmpresa && (
        <ModalGeneric open={carregandoEmpresa} onClose={() => {}}>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Titulo2>Buscando informações da empresa ...</Titulo2>
            </Grid>
          </Grid>
        </ModalGeneric>
      )}

      <Box sx={{ display: "flex" }}>
        <Hidden mdUp>
          <HeaderMobile
            drawerOpen={openSlideBar}
            onDrawerToggle={handleDrawerToggle}
          />
        </Hidden>

        {appState === "inicio" && (
          <Hidden mdDown>
            <HeaderAtalhos
              drawerOpen={openSlideBar}
              onDrawerToggle={handleDrawerToggle}
            />
          </Hidden>
        )}

        <CornerMenu />
        <Hidden mdDown>
          <Box component="nav">
            <Drawer
              open={openSlideBar}
              handleDrawerClose={handleDrawerToggle}
              onOpen={() => setOpenSliderBar(true)}
              onClose={() => setOpenSliderBar(false)}
            />
          </Box>
        </Hidden>

        {openSlideBar && (
          <Hidden mdUp>
            <Box component="nav">
              <Drawer
                open={openSlideBar}
                handleDrawerClose={handleDrawerToggle}
                onOpen={() => setOpenSliderBar(true)}
                onClose={() => setOpenSliderBar(false)}
              />
            </Box>
          </Hidden>
        )}

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: `calc(100% - ${sizeConfigs.sidebar.width})`,
            minHeight: "97vh",
            backgroundColor: colorConfigs.mainBg,
          }}
        >
          <Toolbar />
          <Outlet />
        </Box>
      </Box>

      <footer className={classes.footer}>
        <Copyright textColor={"#fb6f24"} />
      </footer>
    </div>
  );
};

const useStyles = makeStyles({
  body: {
    "@media (max-width: 768px)": {
      height: "100vh",
      width: "100vw",
      padding: "15%",
    },
  },
  footer: {
    background: colorConfigs.mainBg,
  },
});

export default MainLayout;
