import { motion } from "framer-motion";

import { CircularProgress } from "@mui/material";
import { makeStyles } from "@material-ui/core";

type Props = {
  color?: string;
  message: string;
};

type PropsStyles = {
  cor: string;
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    height: "100vh",
  },
  text: (props: PropsStyles) => ({
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: props.cor,
  }),
  dotContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  },
  dot: {
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    willChange: "transform",
  },
});

export function LoadingThreeDotsPulse({ color = "#ff0088", message }: Props) {
  const classes = useStyles({ cor: color });

  const dotVariants = {
    pulse: {
      scale: [1, 1.5, 1],
      transition: {
        duration: 1.2,
        repeat: Infinity,
        ease: "easeInOut",
      },
    },
  };

  return (
    <div className={classes.container}>
      <span className={classes.text}>{message}</span>
      <CircularProgress style={{ color }} />
      <motion.div
        animate="pulse"
        transition={{ staggerChildren: -0.2, staggerDirection: -1 }}
        className={classes.dotContainer}
      >
        <motion.div
          className={classes.dot}
          style={{ backgroundColor: color }}
          variants={dotVariants}
        />
        <motion.div
          className={classes.dot}
          style={{ backgroundColor: color }}
          variants={dotVariants}
        />
        <motion.div
          className={classes.dot}
          style={{ backgroundColor: color }}
          variants={dotVariants}
        />
      </motion.div>
    </div>
  );
}

export default LoadingThreeDotsPulse;
