import { useContext, useState } from "react";
import GridCenter from "../../components/Generics/GridCenter";
import { Grid } from "@mui/material";
import ModalGeneric from "../../components/Generics/ModalGeneric";
import Titulo2 from "../../components/Generics/Titulo2";
import { useSnack } from "../../redux/snack/snackHooks";
import CheckBoxGeneric from "../../components/Generics/CheckBoxGeneric";
import { CfgEmpresaContext } from "../../contexts/CfgEmpresaContext/CfgEmpresaContext";
import TextFieldGeneric from "../../components/Generics/TextFieldGeneric";
import Container from "../../components/Generics/Container";
import api from "../../api/api";

const IntegracoesEmpresaPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const mostrarSnack = useSnack();

  const { empresaEditar, setEmpresaEditar } = useContext(CfgEmpresaContext);

  const authGoogle = async () => {
    try {
      setLoading(true);
      const response = await api.get("http://localhost:2500/gCalendar/auth");

      console.log("response", response);
    } catch (erro: any) {
      mostrarSnack("Erro ao conectar com Google", "error");
      console.log("Erro ao conectar com Google", erro);
    }
  };

  return (
    <GridCenter>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CheckBoxGeneric
            label={"Utiliza integração com Whaths App"}
            value={empresaEditar.utilizaIntegracaoZap}
            onChange={(x) => {
              setEmpresaEditar({ ...empresaEditar, utilizaIntegracaoZap: x });
            }}
          />
        </Grid>

        <Grid item xs={6}>
          {empresaEditar.utilizaIntegracaoZap && (
            <TextFieldGeneric
              label={"Url api zap"}
              value={empresaEditar.urlApiZap}
              onChange={(value) =>
                setEmpresaEditar({ ...empresaEditar, urlApiZap: value })
              }
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <CheckBoxGeneric
            label={"Utiliza integração com Google Calendar"}
            value={empresaEditar.utilizaIntegracaoCalendar}
            onChange={(x) => {
              setEmpresaEditar({
                ...empresaEditar,
                utilizaIntegracaoCalendar: x,
              });
            }}
          />
        </Grid>

        <Grid item xs={12}>
          {empresaEditar.utilizaIntegracaoCalendar && (
            <Container page style={{ textAlign: "center" }}>
              <div>
                <h2>Conectar ao Google Calendar</h2>
                <p>Autorize nosso aplicativo a acessar seu Google Calendar.</p>
                <button onClick={authGoogle}>Conectar com Google</button>
              </div>
            </Container>
          )}
        </Grid>
      </Grid>
    </GridCenter>
  );
};

export default IntegracoesEmpresaPage;
