import { useState } from "react";
import { Button, Grid, useMediaQuery } from "@mui/material";
import TextFieldGeneric from "../../components/Generics/TextFieldGeneric";
import { FloppyDisk, Plus } from "@phosphor-icons/react";
import Container from "../../components/Generics/Container";
import { useLocation, useNavigate } from "react-router-dom";

import { Cliente } from "../../model/entities/Cliente";
import theme from "../../theme";
import Titulo2 from "../../components/Generics/Titulo2";
import { useSnack } from "../../redux/snack/snackHooks";
import DatePickerGeneric from "../../components/Generics/DatePickerGeneric";
import dayjs from "dayjs";
import ClienteField from "../../components/fields/ClienteField";
import projetoApi from "../../api/projetoApi";
import { toast } from "react-toastify";
import Titulo4 from "../../components/Generics/Titulo4";
import ButtonGeneric from "../../components/Generics/ButtonGeneric";
import ModalGeneric from "../../components/Generics/ModalGeneric";
import NovoClientePage from "../clientes/NovoClientePage";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { ModeloBriefing } from "../../model/entities/ModeloBriefing";
import ModeloBriefingField from "../../components/fields/ModeloBriefingField";

const NovoProjetoPage: React.FC = () => {
  const [nome, setNome] = useState("");
  const [descricao, setDescricao] = useState("");
  const [dataInicio, setDataInicio] = useState(dayjs());
  const [dataFim, setDataFim] = useState(dayjs().add(3, "months"));
  const [modeloBriefing, setModeloBriefing] = useState<ModeloBriefing | null>(
    null
  );
  const [cliente, setCliente] = useState<Cliente | null>(null);
  const isSmallSScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openNovoCliente, setOpenNovoCliente] = useState(false);
  const navigate = useNavigate();
  const mostrarSnack = useSnack();

  const salvar = async () => {
    try {
      if (!nome) {
        mostrarSnack("Por favor, informe o nome do projeto", "error");
        return;
      }
      if (!descricao) {
        mostrarSnack("Por favor, informe a descrição do projeto.", "error");
        return;
      }
      if (!cliente || !cliente.id) {
        mostrarSnack(
          "Por favor, informe ou cadastre, o cliente do projeto.",
          "error"
        );
        return;
      }

      if (!modeloBriefing || !modeloBriefing.id) {
        mostrarSnack(
          "Por favor, selecione o modelo de briefing do projeto.",
          "error"
        );
        return;
      }

      const resolveAfter3Sec = projetoApi.insert({
        nome,
        descricao,
        clienteId: cliente.id,
        dataFim: dataFim.toDate().toISOString(),
        dataInicio: dataInicio.toDate().toISOString(),
        id: 0,
        status: 0,
        modeloBriefingId: modeloBriefing.id,
      });
      toast.promise(resolveAfter3Sec, {
        pending: {
          render() {
            return "Criando projeto . . .";
          },
        },
        success: {
          render(params) {
            navigate("/projeto/listagem");
            return "Projeto cadastrado";
          },
        },
        error: {
          render: (params: any) => {
            if (!params) {
              return "Dados indisponíveis";
            }
            console.log("params", params);
            return `${params.data.data}`;
          },
        },
      });
    } catch (erro: any) {
      console.log("Erro ao cadastrar projeto", erro);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container page>
        <Grid container xs={12} spacing={2}>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Titulo2>Cadastrar novo projeto</Titulo2>
          </Grid>

          <Grid item xs={12} md={6}>
            <Titulo4>Selecione o cliente. Ou cadastre o mesmo</Titulo4>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            style={{ textAlign: isSmallSScreen ? "center" : "right" }}
          >
            <ButtonGeneric
              label={"Novo cliente"}
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => {
                setOpenNovoCliente(true);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <ClienteField cliente={cliente} setCliente={setCliente} />
          </Grid>

          <Grid item xs={12}>
            <TextFieldGeneric
              label="Nome do projeto"
              value={nome}
              maxLength={50}
              onChange={(value) => {
                setNome(value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <ModeloBriefingField
              modelo={modeloBriefing}
              setModelo={setModeloBriefing}
            />
          </Grid>

          <Grid item xs={12}>
            <TextFieldGeneric
              label="Descrição do projeto"
              value={descricao}
              minRows={4}
              onChange={(value) => {
                setDescricao(value);
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            style={{ textAlign: isSmallSScreen ? "center" : "left" }}
          >
            <DatePickerGeneric
              label="Previsão de inicio"
              value={dataInicio}
              setValue={(value) => {
                setDataInicio(value);
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            style={{ textAlign: isSmallSScreen ? "center" : "right" }}
          >
            <DatePickerGeneric
              label="Previsão de fim"
              value={dataFim}
              setValue={(value) => {
                setDataFim(value);
              }}
            />
          </Grid>

          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Button
              style={{ margin: "5px" }}
              variant="contained"
              endIcon={<FloppyDisk size={32} />}
              onClick={() => salvar()}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>

        <ModalGeneric
          open={openNovoCliente}
          onClose={() => setOpenNovoCliente(false)}
        >
          <NovoClientePage
            cadastrando
            onSave={(cliente) => {
              setCliente(cliente);
              setOpenNovoCliente(false);
            }}
          />
        </ModalGeneric>
      </Container>
    </div>
  );
};

export default NovoProjetoPage;
