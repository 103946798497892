import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TableGeneric from "../../components/Generics/TableGeneric";
import modeloBriefingApi from "../../api/modeloBriefingApi";
import { ModeloBriefing } from "../../model/entities/ModeloBriefing";
import { useSnack } from "../../redux/snack/snackHooks";
import InfoTabela from "../../components/Generics/InfoTabela";
import ButtonGeneric from "../../components/Generics/ButtonGeneric";
import { PlusCircle } from "@phosphor-icons/react";

const columns: ModeloBriefing[] | any[] = [
  {
    field: "titulo",
    headerName: "Modelo",
    width: 230,
    editable: false,
  },
];

const ListaConfiguracaoBriefingPage: React.FC = () => {
  const [modelos, setModelos] = useState<ModeloBriefing[]>([]);
  const [totalLinhas, setTotalLinhas] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [pagina, setPagina] = useState(0);
  const [linhasPorPagina, setLinhasPorPagina] = useState(5);
  const mostrarSnack = useSnack();
  const [modeloSelecionado, setModeloSelecionado] =
    useState<ModeloBriefing | null>(null);

  useEffect(() => {
    buscarModelos();
  }, []);

  const buscarModelos = async () => {
    try {
      setLoading(true);
      const count = await modeloBriefingApi.count();
      setTotalLinhas(count);

      const res = await modeloBriefingApi.getMany(pagina, linhasPorPagina);
      setModelos(res);
      setLoading(false);
    } catch (erro: any) {
      mostrarSnack("Ops, aconteceu um problema ao buscar os modelos!", "error");
      console.log("Erro ao buscar modelos", erro);
      setLoading(false);
    }
  };

  useEffect(() => {
    buscarModelos();
  }, [pagina, linhasPorPagina]);

  useEffect(() => {
    navegarParaConfiguração();
  }, [modeloSelecionado]);

  const navegarParaConfiguração = () => {
    if (modeloSelecionado) {
      navigate(`/briefing/modelo/configuração`, { state: modeloSelecionado });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={12} sx={{ display: { xs: "none", md: "flex" } }}>
        <Grid item xs={6}>
          <InfoTabela mensagem="Para editar ou ver os dados do modelo clique na linha" />
        </Grid>

        <Grid item xs={6} style={{ textAlign: "right" }}>
          <ButtonGeneric
            startIcon={<PlusCircle />}
            label="Cadastrar modelo"
            onClick={() => {
              navigate(`/briefing/modelo/configuração`);
            }}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <TableGeneric
          pageSize={linhasPorPagina}
          colunas={columns}
          linhas={modelos}
          loading={loading}
          totalLinhas={totalLinhas}
          selecionaLinha={false}
          formasDeListar={[5, 10]}
          onRowClick={(params) => setModeloSelecionado(params.row)}
          sx={{ height: "400px" }}
          stringLinhaVazia="Nenhum modelo de briefing cadastrado"
          setPaginacao={(model) => {
            setPagina(model.page);
            setLinhasPorPagina(model.pageSize);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ListaConfiguracaoBriefingPage;
