import { Grid, useMediaQuery } from "@mui/material";
import DoughnutOrcamentos from "../Graficos/DoughnutOrcamentos";
import Container from "../../components/Generics/Container";
import DoughnutServicos from "../Graficos/DoughnutServicos";
import theme from "../../theme";
import Titulo2 from "../../components/Generics/Titulo2";
import { useSessao } from "../../redux/sessao/sessaoHooks";
import { useEffect, useState } from "react";
import ArticleIcon from "@mui/icons-material/Article";

import ServicosSemana from "../../components/Generics/ServicosSemana";
import servicoApi from "../../api/servicoApi";
import ButtonGeneric from "../../components/Generics/ButtonGeneric";
import { useNavigate } from "react-router-dom";
import GridCenter from "../../components/Generics/GridCenter";
import BotoesAtalhosHome from "./BotoesAtalhosHome";

export default function HomePage() {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [saudacao, setSaudacao] = useState("");
  const [temServicoCadastrado, setTemServicoCadastrado] = useState(false);
  const navigate = useNavigate();

  const [, setQuantidadeOrçamentoEmAndamento] = useState(0);

  const [, setQuantidadeServicoEmAndamento] = useState(0);
  const [sessao] = useSessao();

  const getMomentOfDay = () => {
    const horaAtual = new Date().getHours();

    if (horaAtual >= 6 && horaAtual < 12) {
      return "Bom dia";
    } else if (horaAtual >= 12 && horaAtual < 18) {
      return "Boa tarde";
    } else {
      return "Boa noite";
    }
  };

  useEffect(() => {
    servicoApi
      .count()
      .then((res) => {
        if (res > 0) {
          setTemServicoCadastrado(true);
        }
      })
      .catch((e) => {
        console.log("Não foi possivel contar os servicos ");
      });
    setSaudacao(getMomentOfDay());
  }, []);

  return (
    <Grid container spacing={2}>
      {/* <Grid item xs={12} sx={{ display: { xs: "none", md: "flex" } }}>
          <BotoesAtalhosHome temServicoCadastrado={false} />
        </Grid> */}

      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Titulo2>
          {saudacao}, {sessao.usuario?.nome ?? ""}!
        </Titulo2>
      </Grid>

      {!temServicoCadastrado ? (
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Titulo2>
            Você ainda não tem serviços cadastrados, faça o cadastro para
            continuar.
          </Titulo2>

          <Grid item xs={12} style={{ textAlign: "center" }}>
            <ButtonGeneric
              variant="contained"
              style={{
                color: "white",
                marginBottom: "8px",
                fontSize: "18px",
                borderRadius: "25px",
              }}
              label={"Cadastrar serviços"}
              startIcon={<ArticleIcon />}
              onClick={() => {
                navigate(`/servico/cadastro`);
              }}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid
            item
            md={12}
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <ServicosSemana />
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
            style={{
              borderRadius: "15px",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Container page>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <DoughnutOrcamentos
                    quantidadeRegistro={(x) =>
                      setQuantidadeOrçamentoEmAndamento(x)
                    }
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <DoughnutServicos
                    quantidadeRegistro={(x) =>
                      setQuantidadeServicoEmAndamento(x)
                    }
                  />
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      )}

      {/* <GridCenter item xs={12} style={{ textAlign: "center" }}>
        <Container page>
          <Agenda />
        </Container>
      </GridCenter> */}
    </Grid>
  );
}
