import axios from "axios";
import { Briefing } from "../model/entities/Briefing";
import api from "./api";

const ROUTE = "briefing";

class briefingApi {
  async find() {
    try {
      const res = await api.get<Briefing[]>(`/${ROUTE}`);
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async insert(briefing: Briefing) {
    try {
      const res = await api.post<Briefing>(`/${ROUTE}`, briefing);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async updatePeloCliente(briefing: Briefing, tenantCode: string) {
    try {
      const currentURL = window.location.href;
      const urlPartida = currentURL
        .split(".projete.app")[0]
        .replace("https://", "");
      let baseURL = "https://api.projete.app.br";
      if (urlPartida.includes("localhost")) {
        baseURL = "http://localhost:2500";
      }

      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-Tenant-Code": tenantCode,
        },
      };
      const response = await axios.post(
        `${baseURL}/${ROUTE}/${briefing.id}`,
        briefing,
        config
      );
      const data = response.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async findJaRespondidoById(briefingId: string) {
    try {
      const res = await api.get<boolean>(`/${ROUTE}/respondido/${briefingId}`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

export default new briefingApi();
