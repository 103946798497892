import { Cliente } from "../model/entities/Cliente";
import api from "./api";

const ROUTE = "cliente";

class clienteApi {
  async find(idCliente: number) {
    try {
      const res = await api.get<Cliente>(`/${ROUTE}/${idCliente}`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async getMany(pagina: number, linhasPorPagina: number) {
    try {
      const res = await api.get<Cliente[]>(
        `/${ROUTE}?size=${linhasPorPagina}&page=${pagina}`
      );
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async getManyComplete() {
    try {
      const res = await api.get<Cliente[]>(`/${ROUTE}/complete`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async insert(cliente: Cliente) {
    try {
      const res = await api.post<Cliente>(`/${ROUTE}`, cliente);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async count() {
    try {
      const res = await api.get<number>(`/${ROUTE}/count`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

export default new clienteApi();
