import { useContext, useEffect, useState } from "react";
import Container from "../../components/Generics/Container";
import GridCenter from "../../components/Generics/GridCenter";
import TextFieldGeneric from "../../components/Generics/TextFieldGeneric";
import { Alert, Grid } from "@mui/material";
import cepApi from "../../api/cepApi";
import empresaApi from "../../api/empresaApi";
import ImageFieldSelector from "../../components/Generics/ImageFieldSelector";
import { useUpdateEffect } from "../../helper";
import { useSnack } from "../../redux/snack/snackHooks";
import { CfgEmpresaContext } from "../../contexts/CfgEmpresaContext/CfgEmpresaContext";

const FormularioEmpresa: React.FC = () => {
  const mostrarSnack = useSnack();

  const [logoBase64, setLogoBase64] = useState<string | undefined>(undefined); // Usando undefined inicialmente para controlar melhor a renderização
  const { empresaEditar, setEmpresaEditar, logo, setLogo } =
    useContext(CfgEmpresaContext);

  useEffect(() => {
    const logoData = empresaEditar?.logo?.toString();
    if (logoData) {
      setLogoBase64(logoData);
    } else {
      setLogoBase64(undefined); // Se não houver logo, definir como undefined para garantir que a renderização seja controlada corretamente
    }
  }, [empresaEditar]);

  useUpdateEffect(() => {
    if (logo) {
      setEmpresaEditar(empresaEditar);
      empresaApi
        .update(1, empresaEditar, logo)
        .then((res) => {
          setEmpresaEditar(res);
          mostrarSnack("Informações atualizadas", "success");
        })
        .catch(() => {
          mostrarSnack("Não foi possível atualizar a logo da empresa", "error");
        });
    }
  }, [logo]);

  const buscarCep = async (value: string) => {
    try {
      const buscaCep = await cepApi.getCep(value);
      setEmpresaEditar({
        ...empresaEditar,
        cep: buscaCep.cep,
        bairro: buscaCep.bairro,
        cidade: buscaCep.cidade,
        logradouro: buscaCep.logradouro,
        numeroEndereco: "",
      });
    } catch (erro: any) {
      mostrarSnack(`Não consegui encontrar o CEP ${value}`, "info");
    }
  };

  const handleDeleteLogo = () => {
    setLogo(null);
    setLogoBase64(undefined); // Limpar a representação da imagem quando ela é deletada
  };

  return (
    <GridCenter>
      <Grid container spacing={2}>
        {empresaEditar &&
          (!empresaEditar.cnpj ||
            empresaEditar.cnpj === "null" ||
            !empresaEditar.nomeResposavel ||
            empresaEditar.nomeResposavel === "null" ||
            !empresaEditar.nomeFantasia ||
            empresaEditar.nomeFantasia === "null") && (
            <Grid item xs={12}>
              <Alert elevation={6} variant="standard" severity="warning">
                {"Preencha os dados da empresa para continuar"}
              </Alert>
            </Grid>
          )}

        {/* <ImageFieldSelector
          title="Selecionar imagem"
          imagens={logoBase64 ? [`data:image/jpeg;base64,${logoBase64}`] : []} // Passando uma array com a imagem base64 se existir
          onUpload={(_, file) => {
            setLogo(file);
          }}
          onDelete={handleDeleteLogo}
        /> */}

        <Grid item xs={12}>
          <TextFieldGeneric
            label={"Razão Social *"}
            value={empresaEditar.razaoSocial}
            onChange={(value) =>
              setEmpresaEditar({ ...empresaEditar, razaoSocial: value })
            }
          />
        </Grid>

        <Grid item xs={12}>
          <TextFieldGeneric
            label={"Nome fantasia"}
            value={empresaEditar.nomeFantasia}
            onChange={(value) =>
              setEmpresaEditar({ ...empresaEditar, nomeFantasia: value })
            }
          />
        </Grid>

        <Grid item xs={12}>
          <TextFieldGeneric
            label={"CNPJ * "}
            placeholder="CNPJ *"
            mask={"99.999.999/9999-99"}
            value={empresaEditar.cnpj}
            onChange={(value) => {
              const texto = value.replace(/\D/g, "");
              setEmpresaEditar({ ...empresaEditar, cnpj: texto });
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldGeneric
            label={"Telefone"}
            value={empresaEditar.telefone}
            onChange={(value) =>
              setEmpresaEditar({ ...empresaEditar, telefone: value })
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldGeneric
            label={"E-mail"}
            value={empresaEditar.email}
            onChange={(value) =>
              setEmpresaEditar({ ...empresaEditar, email: value })
            }
          />
        </Grid>

        <Grid item xs={12}>
          <TextFieldGeneric
            label={"Nome responsável *"}
            value={empresaEditar.nomeResposavel}
            onChange={(value) =>
              setEmpresaEditar({ ...empresaEditar, nomeResposavel: value })
            }
          />
        </Grid>

        <Grid item xs={12}>
          <TextFieldGeneric
            label={"Telefone responsável *"}
            value={empresaEditar.telefoneResponsavel}
            onChange={(value) =>
              setEmpresaEditar({
                ...empresaEditar,
                telefoneResponsavel: value,
              })
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldGeneric
            label="CEP"
            value={empresaEditar.cep}
            onChange={(value) => {
              setEmpresaEditar({ ...empresaEditar, cep: value });
              if (value.length === 8) {
                buscarCep(value);
              }
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldGeneric
            label="Rua"
            value={empresaEditar.logradouro}
            onChange={(value) => {
              setEmpresaEditar({
                ...empresaEditar,
                logradouro: value,
              });
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldGeneric
            label="N endereço"
            value={empresaEditar.numeroEndereco}
            onChange={(value) => {
              setEmpresaEditar({
                ...empresaEditar,
                numeroEndereco: value,
              });
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldGeneric
            label="Bairro"
            value={empresaEditar.bairro}
            onChange={(value) => {
              setEmpresaEditar({
                ...empresaEditar,
                bairro: value,
              });
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldGeneric
            label="Cidade"
            value={empresaEditar.cidade}
            onChange={(value) => {
              setEmpresaEditar({
                ...empresaEditar,
                cidade: value,
              });
            }}
          />
        </Grid>
      </Grid>
    </GridCenter>
  );
};

export default FormularioEmpresa;
