import { RouteType } from "../config";
import Agenda from "../../pages/Agenda/Agenda";

import DateRangeIcon from "@material-ui/icons/DateRange";
const grupoAgenda: RouteType = {
  path: "/agenda",
  show: true,
  element: <Agenda />,
  state: "Agenda",
  sidebarProps: {
    displayText: "Agenda",
    icon: <DateRangeIcon />,
  },
};

export default grupoAgenda;
