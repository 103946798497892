import {
  Grid,
  IconButton,
  Paper,
  Typography,
  Box,
  Button,
  TextField,
  Autocomplete,
  Chip,
  LinearProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import DatePickerGeneric from "../../components/Generics/DatePickerGeneric";
import dayjs from "dayjs";
import HorasField from "../../components/fields/HorasField";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSessao } from "../../redux/sessao/sessaoHooks";
import { useSnack } from "../../redux/snack/snackHooks";
import MeetIcon from "../../assets/MeetIcon.png";
import { makeStyles } from "@material-ui/core";
import { Usuario } from "../../model/entities/Usuario";
import agendamentoApi from "../../api/agendamentoApi";
import { Check, Close } from "@mui/icons-material";
import TextFieldGeneric from "../../components/Generics/TextFieldGeneric";
import { useEmpresa } from "../../redux/empresa/empresaHooks";
import ModalGeneric from "../../components/Generics/ModalGeneric";
import UsuarioField from "../../components/fields/UsuarioField";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
  dataAgendamento?: string;
  onReload?: () => void;
  idAgendamento?: number;
  onClose?: () => void;
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    borderRadius: "15px",
    background: "rgba(255, 255, 255, 0.9)",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    maxWidth: "800px",
    margin: "auto",
  },
  header: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  button: {
    marginTop: theme.spacing(2),
  },
  iconButton: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  meetIcon: {
    maxWidth: "25px",
    padding: "1px",
    marginRight: theme.spacing(1),
  },
}));

const FormularioAgenda: React.FC<Props> = (props) => {
  const { dataAgendamento, onReload, idAgendamento, onClose } = props;
  const mostrarSnack = useSnack();
  const [sessao] = useSessao();
  const [titulo, setTitulo] = useState("");
  const [startDate, setStartDate] = useState(
    dayjs(dataAgendamento, "DD/MM/YYYY")
  );
  const [endDate, setEndDate] = useState(dayjs(dataAgendamento, "DD/MM/YYYY"));
  const [solucionado, setSolucionado] = useState(false);
  const [loading, setLoading] = useState(false);
  const [horaInicio, setHoraInicio] = useState("00:00");
  const [horaFim, setHoraFim] = useState("23:59");
  const [observacao, setObservaccao] = useState("");
  const [prioridade, setPrioridade] = useState(0);
  const [adicionarMeet, setAdicionarMeet] = useState(false);
  const [linkMeet, setLinkMeet] = useState("");
  const [empresa] = useEmpresa();
  const [agendadorPor, setAgendadorPor] = useState<Usuario | null>(
    sessao.usuario
  );
  const [agendarPara, setAgendarPara] = useState<Usuario | null>(
    sessao.usuario
  );
  const [convidados, setConvidados] = useState([""]);

  const schema = Yup.object().shape({
    titulo: Yup.string().required("Informe o título"),
    agendarPara: Yup.number().required("Informe para quem será o agendamento"),
    agendadorPor: Yup.number().required(
      "Informe quem está fazendo o agendamento"
    ),
  });

  const {
    register,
    handleSubmit: handleSubmitForm,
    watch,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const handleSubmit = async (data: any) => {
    if (Object.keys(errors).length === 0) {
      await salvar();
    } else {
      console.error("Houve erros de validação:", errors);
    }
  };

  useEffect(() => {
    buscarAgendamento();
  }, []);

  async function buscarAgendamento() {
    try {
      setLoading(true);
      if (idAgendamento) {
        const res = await agendamentoApi.getById(Number(idAgendamento));
        setTitulo(res.title);
        setStartDate(dayjs(res.startDate));
        setEndDate(dayjs(res.endDate));
        setSolucionado(res.solucionado);
        setObservaccao(res.observacao);
        setHoraInicio(dayjs(res.startDate).format("HH:mm"));
        setHoraFim(dayjs(res.endDate).format("HH:mm"));
        setAgendadorPor(res.agendadoPor);
        setAgendarPara(res.agendadoPara);
        setPrioridade(res.prioridade);
        setConvidados(res.convidados.split(","));
        setAdicionarMeet(res.criarEventoMeet);
        setLinkMeet(res.linkMeet);
      }
    } catch (erro: any) {
      console.log("erro ao buscar agendamento especifico", erro);
      mostrarSnack("Erro ao buscar dados do agendamento", "error");
    }
    setLoading(false);
  }

  const salvar = async () => {
    try {
      if (!titulo) {
        mostrarSnack("Por favor informe o título", "error");
        return;
      }

      if (!agendadorPor || !agendadorPor.id) {
        mostrarSnack(
          "Por favor informe quem está fazendo o agendamento",
          "error"
        );
        return;
      }

      if (!agendarPara || !agendarPara.id) {
        mostrarSnack("Por favor informe para quem é o agendamento", "error");
        return;
      }
      setLoading(true);
      const horaInicial = horaInicio.substring(0, 2);
      const minutoInicial = horaInicio.substring(3, 5);

      const horaFinal = horaFim.substring(0, 2);
      const minutoFinal = horaFim.substring(3, 5);

      if (idAgendamento) {
        await agendamentoApi.update(idAgendamento, {
          allDay: false,
          title: titulo,
          endDate: dayjs(
            endDate.toDate().setHours(Number(horaFinal), Number(minutoFinal))
          ).toDate(),
          startDate: dayjs(
            endDate
              .toDate()
              .setHours(Number(horaInicial), Number(minutoInicial))
          ).toDate(),
          horaFim: horaFim,
          horaInicio: horaInicio,
          id: 0,
          observacao: observacao,
          prioridade: prioridade,
          solucionado: solucionado,
          agendadoPara: agendarPara?.id,
          agendadoPor: agendadorPor?.id,
          convidados: convidados.join(", "),
          criarEventoMeet: adicionarMeet,
        });
        setLoading(false);
        mostrarSnack("Agendamento atualizado!", "success");
        if (onReload) onReload();
        return;
      }

      const res = await agendamentoApi.insert({
        allDay: false,
        title: titulo,
        endDate: dayjs(
          endDate.toDate().setHours(Number(horaFinal), Number(minutoFinal))
        ).toDate(),
        startDate: dayjs(
          endDate.toDate().setHours(Number(horaInicial), Number(minutoInicial))
        ).toDate(),
        horaFim: horaFim,
        horaInicio: horaInicio,
        id: 0,
        observacao: observacao,
        prioridade: prioridade,
        solucionado: solucionado,
        agendadoPara: agendarPara?.id,
        agendadoPor: agendadorPor?.id,
        convidados: convidados.join(", "),
        criarEventoMeet: adicionarMeet,
      });

      if (
        empresa &&
        empresa.utilizaIntegracaoCalendar &&
        !res.eventoCriadoGoogle
      ) {
        mostrarSnack(
          "Agendamento concluído, mas não adicionada a sua agenda do google!",
          "info",
          {
            position: "top-right",
            style: {
              fontWeight: "bold",
            },
          }
        );
        setLoading(false);
        if (onReload) onReload();
        if (onClose) onClose();
        return;
      }
      mostrarSnack("Agendamento concluído!", "success");
      if (onReload) onReload();
      if (onClose) onClose();
      setLoading(false);
    } catch (erro: any) {
      mostrarSnack("Não foi possível criar agendamento", "error");
      console.log("Não foi possível criar agendamento", erro);
      setLoading(false);
    }
  };

  const classes = useStyles();

  return (
    <Paper className={classes.container}>
      <IconButton
        onClick={onClose}
        style={{
          height: 53,
          width: 53,
          position: "absolute",
          top: 0,
          right: 0,
        }}
      >
        <CloseIcon />
      </IconButton>

      {loading && (
        <ModalGeneric
          open={loading}
          onClose={() => {}}
          style={{ background: "transparent" }}
        >
          <h3 style={{ textAlign: "center", margin: "15px" }}>
            {empresa?.utilizaIntegracaoCalendar
              ? "Comunicando com a central do Google Calendar"
              : "Gravando agendamento"}
          </h3>
          <LinearProgress />
        </ModalGeneric>
      )}

      <form onSubmit={handleSubmitForm(handleSubmit)}>
        <Box className={classes.header}>
          <Typography variant="h5">
            {idAgendamento ? "Editar agendamento" : "Criar novo agendamento"}
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="titulo"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={"Título"}
                  placeholder="Adicionar um título"
                  value={titulo}
                  error={!!errors.titulo}
                  helperText={errors.titulo ? errors.titulo.message : ""}
                  onChange={(e) => {
                    field.onChange(e);
                    setTitulo(e.target.value);
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <DatePickerGeneric
              label={"Data início"}
              value={startDate}
              setValue={(value) => setStartDate(value)}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <HorasField
              label="Hora início"
              hora={horaInicio}
              setHora={(value) => {
                setHoraInicio(value);
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <DatePickerGeneric
              label={"Data fim"}
              value={endDate}
              setValue={(value) => setEndDate(value)}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <HorasField
              label="Hora fim"
              hora={horaFim}
              setHora={(value) => {
                setHoraFim(value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              multiple
              id="tags-filled"
              options={[]}
              value={convidados}
              freeSolo
              onChange={(event, value) => {
                setConvidados(value);
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <>
                  <TextField
                    {...params}
                    multiline
                    variant="outlined"
                    label=""
                    placeholder=""
                  />
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "12px",
                      color: "inherit",
                    }}
                  >
                    Adicionar convidados (separados por vírgula)
                  </div>
                </>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <TextFieldGeneric
              fullWidth
              label={"Descrição"}
              placeholder="Adicionar uma descrição"
              value={observacao}
              minRows={4}
              maxLength={150}
              onChange={(e) => setObservaccao(e)}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name="agendadorPor"
              control={control}
              render={({ field }) => (
                <UsuarioField
                  idUsuario={agendadorPor?.id}
                  label={"Agendador por"}
                  usuario={agendadorPor}
                  setUsuario={(u) => {
                    field.onChange(u?.id);
                    setAgendadorPor(u);
                  }}
                  error={!!errors.agendadorPor}
                  helperText={
                    errors.agendadorPor ? errors.agendadorPor.message : ""
                  }
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name="agendarPara"
              control={control}
              render={({ field }) => (
                <UsuarioField
                  label={"Agendar para"}
                  usuario={agendarPara}
                  idUsuario={agendarPara?.id}
                  setUsuario={(u) => {
                    field.onChange(u?.id);
                    setAgendarPara(u);
                  }}
                  error={!!errors.agendarPara}
                  helperText={
                    errors.agendarPara ? errors.agendarPara.message : ""
                  }
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <IconButton
              className={classes.iconButton}
              disabled={!empresa?.utilizaIntegracaoCalendar}
              onClick={() => {
                setAdicionarMeet(!adicionarMeet);
              }}
            >
              <img src={MeetIcon} className={classes.meetIcon} />
              {adicionarMeet ? (
                <h5 style={{ color: "green" }}>
                  Videoconferência do Google Meet Adicionada <Check />{" "}
                </h5>
              ) : (
                "Adicionar videoconferência do Google Meet"
              )}
            </IconButton>
          </Grid>

          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={salvar}
              className={classes.button}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default FormularioAgenda;
