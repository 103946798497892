import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { Card, CardContent, Typography } from "@mui/material";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import { ColumnKanban } from "../../model/model/KanbanClass";
import { useSnack } from "../../redux/snack/snackHooks";
import { DadosServicoOS } from "../../model/types/ServicoOSTypes";

export type Props = {
  colunas: Record<string, ColumnKanban>;
  servicos: DadosServicoOS[];
};

const KanbanBoard: React.FC<Props> = (props: Props) => {
  const { colunas, servicos } = props;

  const [columns, setColumns] = useState(colunas);
  const mostrarSnack = useSnack();

  const onDragEnd = (result: any) => {
    const { source, destination } = result;

    if (!destination) return;

    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];

    const sourceTasks = [...sourceColumn.tasks];
    const destTasks = [...destColumn.tasks];

    const [movedTask] = sourceTasks.splice(source.index, 1);

    if (sourceColumn === destColumn) {
      sourceTasks.splice(destination.index, 0, movedTask);
      setColumns({
        ...columns,
        [sourceColumn.id]: { ...sourceColumn, tasks: sourceTasks },
      });
    } else {
      destTasks.splice(destination.index, 0, movedTask);
      setColumns({
        ...columns,
        [sourceColumn.id]: { ...sourceColumn, tasks: sourceTasks },
        [destColumn.id]: { ...destColumn, tasks: destTasks },
      });
    }
  };

  const salvar = async () => {
    try {
      //   if (dadosServicoEditar.idOsServicoFilho) {
      //     await servicoOsApi.atualizar(dadosServicoEditar);
      //   } else {
      //     await servicoOsApi.atualizar(dadosServicoEditar);
      //   }
      mostrarSnack("Dados do serviço foram atualizados!", "success");
    } catch (erro: any) {
      console.log("Erro ao editar dados do serviço", erro);
      mostrarSnack("Erro ao editar dados do serviço", "error");
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div style={{ display: "flex", gap: "20px", padding: "20px" }}>
        {Object.entries(columns).map(([columnId, column]) => (
          <Droppable key={columnId} droppableId={columnId}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  background: "#f4f4f4",
                  padding: "10px",
                  borderRadius: "8px",
                  width: "300px",
                  minHeight: "400px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ textAlign: "center", marginBottom: "10px" }}
                >
                  {column.title}
                </Typography>

                {column.tasks.map((task, index) => (
                  <Draggable key={task.id} draggableId={task.id} index={index}>
                    {(provided) => (
                      <Card
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{
                          marginBottom: "10px",
                          cursor: "grab",
                          background: "white",
                          boxShadow: 3,
                        }}
                      >
                        <CardContent>
                          <Typography>{task.content}</Typography>
                          <div
                            style={{
                              textAlign: "right",
                              top: "auto",
                              marginTop: "0",
                            }}
                          >
                            <PersonPinIcon />
                          </div>
                        </CardContent>
                      </Card>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ))}
      </div>
    </DragDropContext>
  );
};

export default KanbanBoard;
