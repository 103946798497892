import * as React from "react";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Menu, MenuItem } from "@material-ui/core";
import Avatar from "@mui/material/Avatar";
import EngineeringIcon from "@mui/icons-material/Engineering";
import { Icon, styled } from "@mui/material";
import { useSessao } from "../../redux/sessao/sessaoHooks";
import { drawerWidth } from "../../constants";
import { useEmpresa } from "../../redux/empresa/empresaHooks";
import MenuIcon from "@material-ui/icons/Menu";
import { colors } from "../../theme";

type Props = {
  drawerOpen: boolean;
  onDrawerToggle: () => void;
};
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const HeaderMobile: React.FC<Props> = (props) => {
  const { onDrawerToggle } = props;

  const [ancora, setAncora] = React.useState<null | HTMLElement>(null);
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);
  const [empresa, setEmpresa] = useEmpresa();
  const [sessao, login, revalidate, logout, trocaToken] = useSessao();

  return (
    <AppBar position="fixed" style={{ background: colors.drawer }}>
      <Toolbar
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <IconButton onClick={onDrawerToggle}>
          <MenuIcon style={{ color: "white" }} />
        </IconButton>
        <Typography
          variant="h6"
          noWrap
          component="div"
          style={{ textAlign: "center", width: "100%" }}
        >
          {empresa?.nomeFantasia ? empresa?.nomeFantasia : "Projete"}
        </Typography>

        <div>
          <Menu
            id="simple-menu"
            anchorEl={ancora}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            keepMounted
            open={menuIsOpen}
            onClose={() => setMenuIsOpen(false)}
          >
            <MenuItem
              onClick={() => {
                logout();
              }}
            >
              Sair
            </MenuItem>
          </Menu>

          <IconButton
            ref={(ref) => {
              if (ref !== null && ancora === null) {
                setAncora(ref);
              }
            }}
            color="inherit"
            onClick={() => setMenuIsOpen(true)}
          >
            <Avatar>{sessao.usuario?.nome.charAt(0).toUpperCase()}</Avatar>
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderMobile;
