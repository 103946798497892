import HomeIcon from "@mui/icons-material/Home";
import { RouteType } from "../config";
import HomePage from "../../pages/home/HomePage";
const grupoInicio: RouteType = {
  path: "/",
  show: true,
  element: <HomePage />,
  state: "inicio",
  sidebarProps: {
    displayText: "Inicio",
    icon: <HomeIcon />,
  },
};

export default grupoInicio;
