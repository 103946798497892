import {
  DadosAtualizarProjeto,
  DadosCadastrarProjeto,
  Projeto,
} from "../model/entities/Projeto";
import { ProjetoCompletoView } from "../model/entities/ProjetoCompletoView";
import api from "./api";

const ROUTE = "projeto";

class projetoApi {
  async getMany(pagina: number, linhasPorPagina: number) {
    try {
      const res = await api.get<Projeto[]>(
        `/${ROUTE}?size=${linhasPorPagina}&page=${pagina}`
      );
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async getProjetoCompleto(id: string) {
    try {
      const res = await api.get<ProjetoCompletoView[]>(
        `/${ROUTE}/projetoCompleto/${id}`
      );
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async getManyCompleto() {
    try {
      const res = await api.get<Projeto[]>(`/${ROUTE}`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async findProjetosSemBriefing() {
    try {
      const res = await api.get<Projeto[]>(`/${ROUTE}/findProjetosSemBriefing`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async insert(dados: DadosCadastrarProjeto) {
    try {
      const res = await api.post<Projeto>(`/${ROUTE}`, dados);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async update(id: number, dados: DadosAtualizarProjeto) {
    try {
      const res = await api.put<Projeto>(`/${ROUTE}/${id}`, dados);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async count() {
    try {
      const res = await api.get<number>(`/${ROUTE}/count`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

export default new projetoApi();
