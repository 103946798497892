import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import { routes } from "./routes";
import "react-toastify/dist/ReactToastify.css";
import { MuiThemeProvider } from "@material-ui/core";
import theme from "./theme";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useCarregarDados } from "./helper";
import { useEffect } from "react";
import { analytics } from "./firebaseConfig";
import SnackRedux from "./components/Generics/SnackRedux";
import Portfolio from "./pages/Portifolio/Portifolio";
import CssBaseline from "@material-ui/core/CssBaseline";
import PreenchimentoBriefingPage from "./pages/Briefing/PreenchimentoBriefingPage";
dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault("America/Sao_Paulo");
dayjs.locale("pt-br");

const RoutesListener = () => {
  const location = useLocation();

  useEffect(() => {
    analytics.logEvent(location.pathname);
  }, [location]);

  return <></>;
};

function App() {
  // useEffect(() => {
  //   const requestPermission = async () => {
  //     console.log("Solicitando permissão para notificações...");
  //     try {
  //       const permission = await Notification.requestPermission();
  //       if (permission === "granted") {
  //         console.log("Permissão para notificações concedida");
  //         const currentToken = await getToken(messaging, {
  //           vapidKey: "SUA_VAPID_KEY",
  //         });
  //         if (currentToken) {
  //           console.log("FCM Token:", currentToken);
  //           // Envie este token para seu servidor e armazene-o para enviar notificações no futuro.
  //         } else {
  //           console.log(
  //             "Nenhum token disponível. Solicite permissão para gerar um."
  //           );
  //         }
  //       } else {
  //         console.error("Permissão para notificações não concedida");
  //       }
  //     } catch (error) {
  //       console.error("Erro ao solicitar permissão para notificações", error);
  //     }
  //   };

  //   requestPermission();

  //   // Listener para receber mensagens em primeiro plano
  //   onMessage(messaging, (payload) => {
  //     console.log("Mensagem recebida: ", payload);
  //     alert(`Notificação recebida: ${payload.notification?.title}`);
  //     // Personalize a exibição da notificação aqui
  //   });
  // }, []);

  useCarregarDados();
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <RoutesListener />

        <SnackRedux />

        <Routes>
          <Route path="/" element={<MainLayout />}>
            {routes}
          </Route>
          <Route path="/portifolio" element={<Portfolio />} />
          <Route
            path="/briefing/preenchimento/:briefingId/:tenant"
            element={<PreenchimentoBriefingPage />}
          />
        </Routes>
      </BrowserRouter>
    </MuiThemeProvider>
  );
}

export default App;
