import GridCenter from "../../components/Generics/GridCenter";
import DashboardPageLayout from "../../components/layout/DashboardPageLayout";
import HomePage from "../../pages/home/HomePage";
import ListagemOrcamentoPage from "../../pages/orcamento/ListaOrcamento/ListagemOrcamentoPage";
import NovoOrcamentoPage from "../../pages/orcamento/NovoOrcamento/old/NovoOrcamentoPage";
import OrcamentoPage from "../../pages/orcamento/NovoOrcamento/OrcamentoPage";
import { RouteType } from "../config";
import ArticleIcon from "@mui/icons-material/Article";

const grupoOrcamento: RouteType = {
  path: "/orcamento",
  element: <DashboardPageLayout />,
  state: "orcamento",
  show: true,
  sidebarProps: {
    displayText: "Orçamentos",
    icon: <ArticleIcon />,
  },

  child: [
    {
      index: true,
      element: <HomePage />,
      state: "orcamento.index",
      show: true,
    },
    {
      path: "/orcamento/cadastro",
      element: (
        <GridCenter>
          <OrcamentoPage />
        </GridCenter>
      ),
      state: "orcamento.cadastro",
      show: true,
      sidebarProps: {
        displayText: "Novo orçamento",
      },
    },

    {
      path: "/orcamento/listagem",
      element: <ListagemOrcamentoPage />,
      state: "orcamento.listagem",
      show: true,
      sidebarProps: {
        displayText: "Lista de orçamentos",
      },
    },
  ],
};

export default grupoOrcamento;
