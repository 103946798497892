import Container from "../../../components/Generics/Container";
import React from "react";
import { Grid } from "@mui/material";
import "dayjs/locale/pt-br";
import HeaderNovoOrcamento from "./HeaderNovoOrcamento";
import SelecaoClienteOrcamento from "./SelecaoClienteOrcamento";
import ButtonGeneric from "../../../components/Generics/ButtonGeneric";
import { ArrowRight } from "@mui/icons-material";
import { useClienteOrcamento } from "../../../redux/orcamento/orcamentoHooks";
import SelecaoServicosOrcamento from "./SelecaoServicosOrcamento";
import PropiedadesOrcamento from "./PropiedadesOrcamento";

const OrcamentoPage: React.FC = () => {
  const [cliente] = useClienteOrcamento();

  const [abaAtual, setAbaAtual] = React.useState(0);

  return (
    <Container page>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <HeaderNovoOrcamento />
        </Grid>

        {abaAtual === 0 && (
          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <SelecaoClienteOrcamento />
          </Grid>
        )}

        {abaAtual === 1 && (
          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <SelecaoServicosOrcamento
              abaAtual={abaAtual}
              setAbaAtual={setAbaAtual}
            />
          </Grid>
        )}

        {abaAtual === 2 && (
          <PropiedadesOrcamento abaAtual={abaAtual} setAbaAtual={setAbaAtual} />
        )}

        {cliente && abaAtual === 0 && (
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <ButtonGeneric
              label={"Serviços"}
              endIcon={<ArrowRight />}
              onClick={() => {
                setAbaAtual(1);
              }}
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default OrcamentoPage;
