import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useSnack } from "../../../redux/snack/snackHooks";
import projetoApi from "../../../api/projetoApi";
import Container from "../../../components/Generics/Container";
import { ProjetoCompletoView } from "../../../model/entities/ProjetoCompletoView";
import { Box, Card, CardContent, Tab, Tabs, Typography } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import theme from "../../../theme";
import AbaInformacoesProjeto from "./AbaInformacoesProjeto";
import AbaBriefingProjeto from "./AbaBriefingProjeto";

import { motion } from "framer-motion";
import { WarningAmber } from "@mui/icons-material";
import { Cliente } from "../../../model/entities/Cliente";
import clienteApi from "../../../api/clienteApi";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ProjetoPage: React.FC = () => {
  const mostrarSnack = useSnack();

  const { projetoId } = useParams();
  const [loading, setLoading] = useState(true);
  const [projeto, setProjeto] = useState<ProjetoCompletoView[]>([]);
  const [cliente, setCliente] = useState<Cliente | null>(null);

  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };
  function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    if (projetoId && Number(projetoId) > 0) {
      buscarProjeto();
    } else {
      setLoading(false);
    }
  }, [projetoId]);

  const buscarProjeto = async () => {
    try {
      if (!projetoId) return;
      const res = await projetoApi.getProjetoCompleto(projetoId);
      setProjeto(res);
      const resCli = await clienteApi.find(res[0].clienteId);
      setCliente(resCli);

      setLoading(false);
    } catch (erro: any) {
      setLoading(false);
      mostrarSnack("Não foi possível buscar os dados do projeto", "error");
      console.log("Não foi possível buscar os dados do projeto", erro);
    }
  };

  if (projeto.length == 0 && !loading) {
    return <ProjetoVazio />;
  }

  return (
    <Container loading={loading} style={{ borderRadius: "20px" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        aria-label="full width tabs example"
      >
        <Tab label="Dados" {...a11yProps(0)} />
        <Tab label="Briefing" {...a11yProps(1)} />
      </Tabs>

      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0}>
          <AbaInformacoesProjeto
            cliente={cliente}
            projeto={projeto}
            loading={loading}
            setLoading={setLoading}
            refresh={buscarProjeto}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <AbaBriefingProjeto projeto={projeto} />
        </TabPanel>
      </SwipeableViews>
    </Container>
  );
};

export default ProjetoPage;

const ProjetoVazio: React.FC = () => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.4 }}
      style={{ maxHeight: "550px", overflow: "auto" }}
    >
      <Card
        elevation={3}
        style={{
          padding: "15px",
          borderRadius: "10px",
          backgroundColor: "#fff4e5",
          textAlign: "center",
          color: "#d32f2f",
        }}
      >
        <CardContent>
          <WarningAmber style={{ fontSize: 40, color: "#d32f2f" }} />
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            Nenhuma informação disponível
          </Typography>
          <Typography variant="body2" style={{ color: "#555" }}>
            Não há dados sobre o projeto no momento.
          </Typography>
        </CardContent>
      </Card>
    </motion.div>
  );
};
