import { useEffect, useRef, useState } from "react";
import {
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  Autocomplete,
  Chip,
  useMediaQuery,
  Paper,
  Fab,
} from "@mui/material";
import CamposBriefing from "../../model/entities/ConfiguracaoBriefing";
import { ValorCampoBriefing } from "../../model/entities/ValorCampoBriefing";
import { useSnack } from "../../redux/snack/snackHooks";
import { useNavigate, useParams } from "react-router-dom";
import configuracaoBriefingApi from "../../api/camposBriefingApi";
import TextFieldGeneric from "../../components/Generics/TextFieldGeneric";
import NumberFieldGeneric from "../../components/Generics/NumberFieldGeneric";
import { Briefing } from "../../model/entities/Briefing";
import briefingApi from "../../api/briefingApi";
import Copyright from "../../components/layout/Copyrigth";
import theme from "../../theme";
import { motion } from "framer-motion";
import colorConfigs from "../../configs/colorConfigs";
import { ArrowUp } from "@phosphor-icons/react";
import { toast } from "react-toastify";
import {
  MENSAGEM_CAMPO_OBRIGATORIO,
  TIPO_CAMPO_NUMERICO,
  TIPO_CAMPO_SELECAO,
  TIPO_CAMPO_SELECAO_UNICA,
  TIPO_CAMPO_TEXTO,
  TIPO_CAMPO_VALOR_MONETARIO,
} from "../../constants";
import LoadingMotion from "../../components/Generics/LoadingMotion";
import GridCenter from "../../components/Generics/GridCenter";

const PreenchimentoBriefingPage = () => {
  const [configuracoes, setConfiguracoes] = useState<CamposBriefing[]>([]);
  const [valoresCampos, setValoresCampos] = useState<ValorCampoBriefing[]>([]);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [loading, setLoading] = useState(true);

  const mostrarSnack = useSnack();
  const { briefingId, modeloBriefingId, tenant } = useParams();

  const [showScroll, setShowScroll] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);
  const [respondido, setRespondido] = useState(false);
  const [indexSemPrenchimento, setIndexSemPreenchimento] = useState<number[]>(
    []
  );

  useEffect(() => {
    const handleScroll = () => {
      if (cardRef.current) {
        setShowScroll(cardRef.current.scrollTop > 300);
      }
    };
    if (cardRef.current) {
      cardRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (cardRef.current) {
        cardRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const scrollToTop = () => {
    if (cardRef.current) {
      cardRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (!briefingId) return;
    setLoading(true);
    briefingApi
      .findJaRespondidoById(briefingId)
      .then((response) => {
        setRespondido(response);
      })
      .catch((error) => {
        setLoading(false);
        mostrarSnack(
          "Aconteceu um erro ao buscar as configurações do formulário.",
          "error"
        );
      });
    configuracaoBriefingApi
      .find(briefingId)
      .then((response) => {
        setConfiguracoes(response);

        let campos = response.map((config) => ({
          campoId: config.id,
          id: config.id,
          valor: "",
          briefingId: Number(briefingId),
        }));

        setValoresCampos(campos);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        mostrarSnack(
          "Aconteceu um erro ao buscar as configurações do formulário.",
          "error"
        );
      });
  }, []);

  const salvar = () => {
    try {
      if (!briefingId || !tenant) return;

      if (!valoresObrigatoriosPreenchidos()) {
        return;
      }

      const briefing: Briefing = {
        titulo: "",
        descricao: "",
        projetoId: 0,
        valoresCampoBriefing: valoresCampos,
        id: briefingId,
      };

      const resolveAfter3Sec = briefingApi.updatePeloCliente(briefing, tenant);

      toast.promise(resolveAfter3Sec, {
        pending: {
          render() {
            return "Armazenando briefing.";
          },
        },
        success: {
          render() {
            setRespondido(true);
            return "Briefing enviado!";
          },
        },
        error: {
          render: (params: any) => {
            if (!params) {
              return "Dados indisponíveis";
            }
            return `${params.data.data}`;
          },
        },
      });
    } catch (erro: any) {
      mostrarSnack("Ops! Aconteceu um erro ao salvar o Briefing.", "error");
      console.log("Ops! Aconteceu um erro ao salvar o Briefing.", erro);
    }
  };

  const handleCampoChange = (id: number, valor: string) => {
    console.log("yyy preciso do id", id);
    console.log("yyy valoresCampos", valoresCampos);
    setValoresCampos((prevValoresCampos) => {
      const campoIndex = prevValoresCampos.findIndex(
        (campo) => campo.id === id
      );

      if (campoIndex !== -1) {
        // Se o campo já existe, atualiza o valor
        const novosValoresCampos = [...prevValoresCampos];
        novosValoresCampos[campoIndex] = {
          ...novosValoresCampos[campoIndex],
          valor: valor,
        };
        console.log("yyy novosValoresCampos", novosValoresCampos);
        return novosValoresCampos;
      } else {
        // Se o campo não existe, adiciona um novo objeto ao array
        const novoCampo = {
          id, // Usa o ID passado como parâmetro
          valor,
          briefingId: Number(briefingId), // briefingId fixo
          campoId: id, // Adiciona o campoId
        };
        return [...prevValoresCampos, novoCampo];
      }
    });
  };

  function valoresObrigatoriosPreenchidos() {
    let valido = true;
    let indexInvalidos: number[] = [];

    // Loop sobre as configurações para verificar campos obrigatórios
    for (const config of configuracoes) {
      if (config.obrigatorio) {
        // Verificando se existe valor associado ao campo
        const valorEncontrado = valoresCampos.find(
          (valor) => valor.campoId === config.id
        );

        // Se o valor não existir ou estiver vazio, adiciona o campo à lista de inválidos
        if (!valorEncontrado || !valorEncontrado.valor.trim()) {
          indexInvalidos.push(config.id); // Aqui usamos o campoId
          valido = false;
        }
      }
    }

    // Atualizando o estado com os índices dos campos obrigatórios não preenchidos
    setIndexSemPreenchimento(indexInvalidos);

    // Exibindo mensagem de erro, caso algum campo esteja inválido
    if (!valido) {
      mostrarSnack("Campos obrigatórios não preenchidos.", "error");
    }

    return valido;
  }

  if (loading) {
    return (
      <GridCenter
        style={{
          background: colorConfigs.sidebar.bg,
          height: "100vh",
        }}
      >
        <LoadingMotion
          message="Buscando informações do briefing..."
          color="white"
        />
      </GridCenter>
    );
  }

  const ball = {
    width: "100%",
    height: "100%",
    backgroundColor: colorConfigs.sidebar.bg,
    fontStyle: "Roboto",
    color: "#D1D1D1",
    borderRadius: "2%",
  };

  const campoSelecaoTemValor = (id: number) => {
    if (valoresCampos.find((campo) => campo.id === id)?.valor) {
      return valoresCampos.find((campo) => campo.id === id)?.valor.split(",");
    } else {
      return [];
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      style={{ background: colorConfigs.sidebar.bg }}
    >
      <Card
        ref={cardRef}
        sx={{
          background: respondido ? colorConfigs.sidebar.bg : "",
          maxWidth: isSmallScreen ? "100%" : "65%",
          margin: "auto",
          mt: 4,
          p: 2,
          height: isSmallScreen ? "92vh" : "94.5vh",
          overflow: "auto",
          boxShadow: respondido ? 0 : 4,
          borderRadius: respondido ? 0 : 3,
        }}
      >
        {respondido ? (
          <Grid
            item
            xs={12}
            style={{
              textAlign: "center",
              justifyContent: "center",
              marginTop: "30%",
            }}
          >
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.4,
                scale: { type: "spring", visualDuration: 0.4, bounce: 0.5 },
              }}
              style={ball}
            >
              <div>
                <h1>Obrigado por enviar o seu briefing!</h1>
                <span style={{ fontSize: "22px" }}>
                  🚀 Recebemos suas informaçõescom sucesso. Nossa equipe irá
                  analisar os detalhes e entraremos em contato em breve para dar
                  continuidade ao seu projeto. Se precisar de mais alguma coisa,
                  estamos à disposição! 😊"
                </span>
              </div>
            </motion.div>
          </Grid>
        ) : (
          <>
            <CardContent>
              <Typography
                variant="h5"
                gutterBottom
                fontWeight="bold"
                style={{ textAlign: "center", fontSize: "22px" }}
              >
                📋 {configuracoes[0]?.tituloModelo ?? ""}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                mb={2}
                style={{ textAlign: "center" }}
              >
                {configuracoes[0]?.descricaoModelo ?? ""}
              </Typography>

              <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
                {configuracoes.map((configuracao, index) => (
                  <Grid container spacing={2} style={{ padding: "15px" }}>
                    <Grid item xs={12} key={configuracao.id}>
                      {configuracao.tipo === TIPO_CAMPO_TEXTO && (
                        <TextFieldGeneric
                          error={indexSemPrenchimento.includes(configuracao.id)}
                          helperText={
                            indexSemPrenchimento.includes(configuracao.id)
                              ? MENSAGEM_CAMPO_OBRIGATORIO
                              : ""
                          }
                          fullWidth
                          label={configuracao.nome}
                          value={
                            valoresCampos.find(
                              (campo) => campo.id === configuracao.id
                            )?.valor || ""
                          }
                          onChange={(e) =>
                            handleCampoChange(configuracao.id, e)
                          }
                          required={configuracao.obrigatorio}
                        />
                      )}

                      {configuracao.tipo === TIPO_CAMPO_VALOR_MONETARIO && (
                        <NumberFieldGeneric
                          fullWidth
                          type="money"
                          label={configuracao.nome}
                          value={
                            valoresCampos.find(
                              (campo) => campo.id === configuracao.id
                            )?.valor || ""
                          }
                          onChange={(e) =>
                            handleCampoChange(configuracao.id, e.toString())
                          }
                          required={configuracao.obrigatorio}
                          error={indexSemPrenchimento.includes(configuracao.id)}
                          helperText={
                            indexSemPrenchimento.includes(configuracao.id)
                              ? MENSAGEM_CAMPO_OBRIGATORIO
                              : ""
                          }
                        />
                      )}

                      {configuracao.tipo === TIPO_CAMPO_NUMERICO && (
                        <NumberFieldGeneric
                          fullWidth
                          label={configuracao.nome}
                          value={
                            valoresCampos.find(
                              (campo) => campo.id === configuracao.id
                            )?.valor || ""
                          }
                          onChange={(e) =>
                            handleCampoChange(configuracao.id, e.toString())
                          }
                          required={configuracao.obrigatorio}
                          error={indexSemPrenchimento.includes(configuracao.id)}
                          helperText={
                            indexSemPrenchimento.includes(configuracao.id)
                              ? MENSAGEM_CAMPO_OBRIGATORIO
                              : ""
                          }
                        />
                      )}

                      {configuracao.tipo === TIPO_CAMPO_SELECAO && (
                        <Grid item xs={12}>
                          <Autocomplete
                            multiple
                            id="tags-filled"
                            options={configuracao.options.split(",")} // Opções separadas por vírgula
                            value={campoSelecaoTemValor(configuracao.id)}
                            freeSolo
                            onChange={(_, newValue) => {
                              handleCampoChange(
                                configuracao.id,
                                newValue.join(",")
                              );
                            }}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  variant="outlined"
                                  label={option}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <>
                                <TextField
                                  {...params}
                                  multiline
                                  variant="outlined"
                                  label=""
                                  placeholder=""
                                  error={indexSemPrenchimento.includes(
                                    configuracao.id
                                  )}
                                  helperText={
                                    indexSemPrenchimento.includes(
                                      configuracao.id
                                    )
                                      ? MENSAGEM_CAMPO_OBRIGATORIO
                                      : ""
                                  }
                                />
                                <div
                                  style={{
                                    textAlign: "left",
                                    fontSize: "14px",
                                    color: "inherit",
                                  }}
                                >
                                  {configuracao.nome}
                                </div>
                              </>
                            )}
                          />
                        </Grid>
                      )}

                      {configuracao.tipo === TIPO_CAMPO_SELECAO_UNICA && (
                        <Grid item xs={12}>
                          <Autocomplete
                            id="single-selection"
                            options={configuracao.options.split(",")}
                            value={
                              campoSelecaoTemValor(configuracao.id)?.[0] || null
                            }
                            onChange={(_, newValue) => {
                              console.log("yyy", newValue);
                              handleCampoChange(
                                configuracao.id,
                                newValue || ""
                              );
                            }}
                            renderInput={(params) => (
                              <>
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label=""
                                  placeholder=""
                                  error={indexSemPrenchimento.includes(
                                    configuracao.id
                                  )}
                                  helperText={
                                    indexSemPrenchimento.includes(
                                      configuracao.id
                                    )
                                      ? MENSAGEM_CAMPO_OBRIGATORIO
                                      : ""
                                  }
                                />
                                <div
                                  style={{
                                    textAlign: "left",
                                    fontSize: "14px",
                                    color: "inherit",
                                  }}
                                >
                                  {configuracao.nome}
                                </div>
                              </>
                            )}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Paper>

              <Button
                onClick={salvar}
                variant="contained"
                color="primary"
                sx={{ mt: 2, width: "100%" }}
              >
                Enviar
              </Button>
            </CardContent>

            {showScroll && (
              <Fab
                size="small"
                onClick={scrollToTop}
                sx={{
                  position: "fixed",
                  bottom: 16,
                  right: 16,
                  color: "black",
                  backgroundColor: "white",
                }}
              >
                <ArrowUp />
              </Fab>
            )}
          </>
        )}
      </Card>

      <footer
        style={{
          marginTop: "auto",
          width: "100%",
        }}
      >
        <Copyright textColor={"white"} />
      </footer>
    </motion.div>
  );
};

export default PreenchimentoBriefingPage;
