import { RouteType } from "../config";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import ListagemProjetoPage from "../../pages/Projeto/ListagemProjetoPage";
import DashboardPageLayout from "../../components/layout/DashboardPageLayout";
import HomePage from "../../pages/home/HomePage";
import GridCenter from "../../components/Generics/GridCenter";
import NovoProjetoPage from "../../pages/Projeto/NovoProjetoPage";
import ProjetoPage from "../../pages/Projeto/DadosProjeto/ProjetoPage";

const grupoProjeto: RouteType = {
  path: "/projeto",
  element: <DashboardPageLayout />,
  state: "Projeto",
  show: true,
  sidebarProps: {
    displayText: "Projeto",
    icon: <NoteAddIcon />,
  },
  child: [
    {
      index: true,
      element: <HomePage />,
      state: "orcamento.index",
      show: true,
    },
    {
      path: "/projeto/cadastro",
      element: (
        <GridCenter>
          <NovoProjetoPage />
        </GridCenter>
      ),
      state: "projeto.cadastro",
      show: true,
      sidebarProps: {
        displayText: "Novo projeto",
      },
    },

    {
      path: "/projeto/listagem",
      element: <ListagemProjetoPage />,
      state: "projeto.listagem",
      show: true,
      sidebarProps: {
        displayText: "Lista de projetos",
      },
    },

    {
      path: "/projeto/completo/:projetoId",
      element: (
        <GridCenter>
          <ProjetoPage />
        </GridCenter>
      ),
      state: "projeto.completo",
      show: true,
      verificarPermissao: () => false,
      sidebarProps: {
        displayText: "",
      },
    },
  ],
};

export default grupoProjeto;
