import axios from "axios";
import store from "../redux/store";
import { logoutAction } from "../redux/sessao/sessaoActions";
import { analytics } from "../firebaseConfig";

import { toast } from "react-toastify";

const api = axios.create({ timeout: 150000 });

api.interceptors.request.use(async (config) => {
  const state = store.getState();
  config.headers["X-User-Id"] = state.sessao.usuario?.id;
  config.headers["X-Tenant-Code"] = state.cfgGeral.tenantCode;

  const currentURL = window.location.href;
  const urlPartida = currentURL
    .split(".projete.app")[0]
    .replace("https://", "");

  // config.baseURL = `https://api.projete.app.br/${urlPartida.replace(
  //   "https://",
  //   ""
  // )}`;

  config.baseURL = "https://api.projete.app.br";
  if (urlPartida.includes("localhost")) {
    config.baseURL = "http://localhost:2500";
    // config.headers["X-Tenant-Code"] = 4233;
  }

  const token = state.sessao.tokenJWT;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },

  async function (error) {
    console.log("yyy", api.request);
    analytics.logEvent("api-exception", { description: error, fatal: true });
    if (
      error &&
      error.response.data === "Usuário inexistente ou senha inválida"
    ) {
    } else if (error.response.data === "Token JWT invalido ou expirado!") {
      console.log("unauthorized, logging out ...");
      const action = logoutAction();
      store.dispatch(action);
      toast("Seu login expirou. Por favor, faça login novamente", {
        position: "top-center",
        style: {
          background: "#ff4d4f",
          color: "#fff",
          fontWeight: "bold",
        },
      });
    }
    console.log("yyyy error", error.response);

    if (!error.response) {
      console.log("ERRO!!! Não foi possível conectar com servidor.");
      toast("Seu login expirou. Por favor, faça login novamente", {
        position: "top-center",
        style: {
          background: "#ff4d4f",
          color: "#fff",
          fontWeight: "bold",
        },
      });
    }

    return Promise.reject(error.response);
  }
);

export default api;
