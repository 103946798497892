import React, { useEffect, useState } from "react";
import { Grid, IconButton, Pagination, Paper, Typography } from "@mui/material";

import TableGeneric from "../../../components/Generics/TableGeneric";
import osApi from "../../../api/osApi";
import dayjs from "dayjs";
import { formatarDinheiro } from "../../../helper";
import { GridColDef } from "@mui/x-data-grid";

import { STATUS_ORCAMENTO } from "../../../constants";
import MiniInfo from "../../../components/Generics/MiniInfo";
import { DadosListagemOS } from "../../../model/types/OSTypes";
import { useNavigate } from "react-router-dom";
import InfoTabela from "../../../components/Generics/InfoTabela";
import { useSnack } from "../../../redux/snack/snackHooks";
import GridCenter from "../../../components/Generics/GridCenter";
import { getStatusServicoOS } from "../../../api/servicoOsApi";
import { Create, EditNotifications } from "@mui/icons-material";
import Container from "../../../components/Generics/Container";
import Titulo2 from "../../../components/Generics/Titulo2";
import ButtonGeneric from "../../../components/Generics/ButtonGeneric";

const columns: GridColDef<DadosListagemOS>[] = [
  {
    field: "osId",
    headerName: "id",
    width: 30,
    editable: false,
  },

  {
    field: "nomeCliente",
    headerName: "Nome cliente",
    width: 230,
    editable: false,
    renderCell: (params) => {
      return params.row.nomeCliente;
    },
  },
  {
    field: "dataOrcamento",
    headerName: "Data da OS",
    width: 230,
    editable: false,
    renderCell: (params) => {
      return dayjs(params.row.dataOs).format("DD/MM/YYYY");
    },
  },

  {
    field: "valorOrcamento",
    headerName: "Valor ordem servico",
    width: 230,
    editable: false,
    renderCell: (params) => {
      return formatarDinheiro(params.row.valorOs);
    },
  },

  {
    field: "status",
    headerName: "Status ordem servico",
    width: 230,
    editable: false,
    renderCell: (params) => {
      const status = params.row.statusOs;
      if (status >= 0)
        if (status === 0) {
          return (
            <MiniInfo color="#d6da01" style={{ fontSize: "14px" }}>
              {STATUS_ORCAMENTO[0].decricao}
            </MiniInfo>
          );
        }
      if (status === 1) {
        return (
          <MiniInfo color="#f74a05" style={{ fontSize: "14px" }}>
            {STATUS_ORCAMENTO[1].decricao}
          </MiniInfo>
        );
      }

      if (status === 2) {
        return (
          <MiniInfo color="#0083c0" style={{ fontSize: "14px" }}>
            {STATUS_ORCAMENTO[2].decricao}
          </MiniInfo>
        );
      }

      if (status === 3) {
        return (
          <MiniInfo color="#46c000" style={{ fontSize: "14px" }}>
            {STATUS_ORCAMENTO[3].decricao}
          </MiniInfo>
        );
      }
    },
  },
];

const ListagemOSPage: React.FC = () => {
  const mostrarSnack = useSnack();
  const [orcamentos, setOrcamentos] = useState<DadosListagemOS[]>([]);
  const [totalLinhas, setTotalLinhas] = useState(0);
  const [loading, setLoading] = useState(false);
  const [nome, setNome] = useState("");
  const navigate = useNavigate();
  const [pagina, setPagina] = useState(0);
  const [linhasPorPagina, setLinhasPorPagina] = useState(5);
  const [osSelecionada, setOsSelecionada] = useState<DadosListagemOS | null>(
    null
  );

  const buscaOS = async () => {
    try {
      setLoading(true);
      const res = await osApi.getMany(pagina, linhasPorPagina);

      setTotalLinhas(res.totalElements);
      setOrcamentos(res.content);
      setLoading(false);
    } catch (error: any) {
      mostrarSnack(
        "Ops, aconteceu um problema ao buscar os orcamentos!",
        "error"
      );
      console.log("Erro ao buscar orcamentos", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    buscaOS();
  }, [pagina, linhasPorPagina]);

  useEffect(() => {
    navegarParaOS();
  }, [osSelecionada]);

  const navegarParaOS = () => {
    if (osSelecionada) {
      navigate(`/ordemServico/cadastro/${osSelecionada.osId}`);
    }
  };

  return (
    <Grid container spacing={2}>
      {orcamentos.length > 0 && (
        <InfoTabela mensagem="Para detalhes da ordem de serviço clique na mesma" />
      )}

      <Grid
        item
        xs={12}
        sx={{ display: { xs: "flex", md: "none" } }}
        style={{ flexDirection: "column" }}
      >
        {orcamentos.length == 0 ? (
          <Container page>
            <Grid container style={{ textAlign: "center" }}>
              <Grid item xs={12}>
                <Titulo2>Não existem O.S cadastradas</Titulo2>
              </Grid>
              As ordem de serviços, são geradas a partir de orçamentos
              aprovados.
            </Grid>
          </Container>
        ) : (
          <>
            {orcamentos.map((os) => (
              <GridCenter>
                <Paper
                  style={{
                    margin: "12px 0px",
                    padding: "12px",
                    width: "100%",
                  }}
                  key={os.osId}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      {getStatusServicoOS(os.statusOs)}
                    </Grid>

                    <Grid item xs={23}>
                      <Typography fontWeight={"bold"}>
                        Data OS: {dayjs(os.dataOs).format("DD/MM/YYYY")}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <Typography>{os.nomeCliente}</Typography>

                      <Typography>R${formatarDinheiro(os.valorOs)}</Typography>
                    </Grid>

                    <Grid item xs={12} style={{ textAlign: "right" }}>
                      <IconButton>
                        <Create
                          onClick={() => {
                            setOsSelecionada(os);
                          }}
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Paper>
              </GridCenter>
            ))}

            <GridCenter>
              <Pagination
                size={"large"}
                defaultPage={1}
                count={totalLinhas / linhasPorPagina}
                onChange={(event, number) => setPagina(number)}
                page={pagina}
              />
            </GridCenter>
          </>
        )}
      </Grid>

      <Grid item xs={12}>
        <TableGeneric
          pageSize={linhasPorPagina}
          sx={{ display: { xs: "none", md: "flex" } }}
          colunas={columns}
          linhas={orcamentos}
          loading={loading}
          getRowId={(value) => value.osId}
          totalLinhas={totalLinhas}
          selecionaLinha={false}
          formasDeListar={[5, 10]}
          setPaginacao={(model) => {
            setPagina(model.page);
            setLinhasPorPagina(model.pageSize);
          }}
          onRowClick={(row) => setOsSelecionada(row.row)}
        />
      </Grid>
    </Grid>
  );
};

export default ListagemOSPage;
