import MiniInfo from "../components/Generics/MiniInfo";
import { STATUS_SERVICO_OS } from "../constants";
import { ResumoOrcamento } from "../model/types/OrcamentoType";
import {
  DadosServicoOS,
  FiltroBuscaServicoByUsuario,
} from "../model/types/ServicoOSTypes";
import api from "./api";

const ROUTE = "servicoOS";

class servicoOsApi {
  async getServicos(filtros: FiltroBuscaServicoByUsuario) {
    try {
      const res = await api.post<DadosServicoOS[]>(
        `/${ROUTE}/findWhitFilters`,
        filtros
      );

      if (res) {
        const data = res.data;
        return data;
      } else return [];
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async atualizar(dados: DadosServicoOS) {
    try {
      const res = await api.put<DadosServicoOS[]>(`/${ROUTE}`, dados);
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async getResumoServicos() {
    try {
      const res = await api.get<ResumoOrcamento[]>(`/${ROUTE}/resumoGrafico`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async iniciarServico(dados: DadosServicoOS) {
    try {
      const res = await api.post<DadosServicoOS[]>(
        `/${ROUTE}/iniciarServico`,
        dados
      );
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async pausarServico(dados: DadosServicoOS) {
    try {
      const res = await api.post<DadosServicoOS[]>(
        `/${ROUTE}/pausarServico`,
        dados
      );
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

export const getStatusServicoOS = (status: number) => {
  if (status === 0) {
    return (
      <MiniInfo color="#d6da01" style={{ fontSize: "14px" }}>
        {STATUS_SERVICO_OS[0].decricao}
      </MiniInfo>
    );
  }
  if (status === 1) {
    return (
      <MiniInfo color="#0497aa" style={{ fontSize: "14px" }}>
        {STATUS_SERVICO_OS[1].decricao}
      </MiniInfo>
    );
  }

  if (status === 2) {
    return (
      <MiniInfo color="#00c043" style={{ fontSize: "14px" }}>
        {STATUS_SERVICO_OS[2].decricao}
      </MiniInfo>
    );
  }

  if (status === 3) {
    return (
      <MiniInfo color="#46c000" style={{ fontSize: "14px" }}>
        {STATUS_SERVICO_OS[3].decricao}
      </MiniInfo>
    );
  }
};
export default new servicoOsApi();
