import GridCenter from "../../components/Generics/GridCenter";
import DashboardPageLayout from "../../components/layout/DashboardPageLayout";
import { Usuario } from "../../model/entities/Usuario";
import CaixaPage from "../../pages/Financeiro/Caixa/CaixaPage";
import CategoriasFinanceiro from "../../pages/Financeiro/CategoriasFinanceiro/CategoriasFinanceiro";

import ContasCaixaPage from "../../pages/Financeiro/ContasCaixa/ContasCaixaPage";
import FormasPagamentoPage from "../../pages/Financeiro/FormasPagamentoPage";
import HomePage from "../../pages/home/HomePage";
import { RouteType } from "../config";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

const grupoFinanceiro: RouteType = {
  path: "/financeiro",
  element: <DashboardPageLayout />,
  state: "financeiro",
  show: true,
  verificarPermissao: (usuario: Usuario | null) => {
    return usuario ? usuario.perfil.acessaFinanceiro : false;
  },
  sidebarProps: {
    displayText: "Financeiro",
    icon: <AttachMoneyIcon />,
  },

  child: [
    {
      index: true,
      element: <HomePage />,
      state: "financeiro.index",
      show: true,
    },
    {
      path: "/financeiro/caixa",
      element: (
        <GridCenter>
          <CaixaPage />
        </GridCenter>
      ),
      state: "financeiro.caixa",
      show: true,
      sidebarProps: {
        displayText: "Caixa",
      },
    },
    // {
    //   path: "/financeiro/depesas",
    //   element: (
    //     <GridCenter>
    //       <DespesasPage />
    //     </GridCenter>
    //   ),
    //   state: "financeiro.depesas",
    //   sidebarProps: {
    //     displayText: "Despesas",
    //   },
    // },

    {
      path: "/financeiro/categoria",
      element: (
        <GridCenter>
          <CategoriasFinanceiro />
        </GridCenter>
      ),
      state: "financeiro.categoria",
      show: true,
      sidebarProps: {
        displayText: "Categorias",
      },
    },

    // {
    //   path: "/financeiro/contas",
    //   element: (
    //     <GridCenter>
    //       <ContasCaixaPage />
    //     </GridCenter>
    //   ),
    //   state: "financeiro.contas",
    //   sidebarProps: {
    //     displayText: "Contas caixa",
    //   },
    // },
    // {
    //   path: "/financeiro/caixa",
    //   element: (
    //     <GridCenter>
    //       <CaixaPage />
    //     </GridCenter>
    //   ),
    //   state: "financeiro.caixa",
    //   sidebarProps: {
    //     displayText: "Caixa",
    //   },
    // },

    {
      path: "/financeiro/formasPagamento",
      element: <FormasPagamentoPage />,
      state: "financeiro.formasPagamento",
      show: true,
      sidebarProps: {
        displayText: "Formas de pagamento",
      },
    },
  ],
};

export default grupoFinanceiro;
