import CamposBriefing from "../model/entities/ConfiguracaoBriefing";
import api from "./api";

const ROUTE = "camposBriefing";

class camposBriefingApi {
  async find(idModelo: string) {
    try {
      const res = await api.get<CamposBriefing[]>(`/${ROUTE}/${idModelo}`);
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async insert(camposBriefing: CamposBriefing[]) {
    try {
      const res = await api.post<CamposBriefing>(`/${ROUTE}`, camposBriefing);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

export default new camposBriefingApi();
