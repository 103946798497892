import { createReducer } from "@reduxjs/toolkit";
import { setCfgGeralAction } from "./cfgGeralActions";
import { CfgGeralState } from "./cfgGeralTypes";

export const initialState: CfgGeralState = {
  urlApi: "http://localhost:2500",
  versao: '"1.0.0',
  statusZap: false,
  tenantCode: "0",
};

const cfgGeralReducer = createReducer<CfgGeralState>(initialState, {
  [setCfgGeralAction.toString()]: (state, action) => {
    if (setCfgGeralAction.match(action)) {
      return { ...state, ...action.payload };
    }
    return { ...state };
  },
});

export default cfgGeralReducer;
