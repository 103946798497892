import CircleIcon from "@mui/icons-material/Circle";
import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import moment from "moment";
import { useSessao } from "../../redux/sessao/sessaoHooks";
import { Agendamento } from "../../model/entities/Agendamento";
import agendamentoApi from "../../api/agendamentoApi";
import { Usuario } from "../../model/entities/Usuario";
import { colors, IconButton } from "@mui/material";
import {
  LISTA_PRIORIDADE_AGENDAMENTO,
  PRIORIDADE_AGENDAMENTO_ALTA,
  PRIORIDADE_AGENDAMENTO_BAIXA,
  PRIORIDADE_AGENDAMENTO_MEDIA,
} from "../../constants";
import dayjs from "dayjs";
import { useSnack } from "../../redux/snack/snackHooks";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { Record } from "@phosphor-icons/react";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ButtonGeneric from "../../components/Generics/ButtonGeneric";
import { CopyAllOutlined } from "@mui/icons-material";

type Props = {
  id?: string | number;
};

const TooltipAgenda: React.FC<Props> = (props) => {
  const { id } = props;

  const mostrarSnack = useSnack();
  const [agendamento, setAgendamento] = useState<Agendamento | null>(null);
  const [loading, setLoading] = useState(true);
  const [sessao] = useSessao();

  useEffect(() => {
    buscarAgendamento();
  }, []);

  async function buscarAgendamento() {
    try {
      setLoading(true);
      if (id) {
        const res = await agendamentoApi.getById(Number(id));
        setAgendamento({ ...res });
      } else {
        const novoAgendamento: Agendamento = {
          allDay: false,
          title: "",
          id: 0,
          startDate: new Date(),
          endDate: new Date(),
          solucionado: false,
          prioridade: 0,
          observacao: "",
          agendadoPara: sessao.usuario ?? new Usuario(), // pega o codigo do usuario da sessao
          agendadoPor: sessao.usuario ?? new Usuario(),
          convidados: "",
          criarEventoMeet: false,
          linkMeet: "",
        };
        setAgendamento({ ...novoAgendamento });
      }
      setLoading(false);
    } catch (erro: any) {
      console.log("erro ao buscar agendamento especifico", erro);
      mostrarSnack("Erro ao buscar dados do agendamento", "error");
    }
  }
  if (loading || !agendamento) {
    return null;
  }

  return (
    <Grid
      container
      spacing={2}
      style={{
        display: "flex",
        flexDirection: "column",
        paddingInline: "2px",
      }}
    >
      <Grid
        item
        xs={12}
        style={{
          marginTop: "0.5rem",
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          paddingBottom: "0.5rem",
        }}
      >
        <CircleIcon style={{ color: colors.lightBlue[800] }} fontSize="large" />
        <span
          style={{
            fontSize: "1.25rem",
          }}
        >
          {agendamento.title}
        </span>
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <WatchLaterIcon
          style={{ color: colors.lightBlue[800] }}
          fontSize="large"
        />
        <span>{moment(agendamento.startDate).format("DD/MM/YYYY")}</span>
        {!agendamento.allDay && (
          <span>
            {agendamento.startDate && agendamento.endDate && (
              <>{dayjs(agendamento.startDate).format("HH:mm")}</>
            )}
            {" - "}
            {agendamento.startDate && agendamento.endDate && (
              <>{dayjs(agendamento.endDate).format("HH:mm")}</>
            )}
          </span>
        )}
      </Grid>

      {agendamento.convidados !== " " && (
        <Grid
          item
          xs={12}
          style={{
            marginTop: "0.5rem",
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            paddingBottom: "0.5rem",
          }}
        >
          <PersonAddIcon
            style={{ color: colors.lightBlue[800] }}
            fontSize="large"
          />
          <span
            style={{
              fontSize: "1.1rem",
            }}
          >
            {agendamento.convidados.split(",")}
          </span>
        </Grid>
      )}

      {agendamento.linkMeet && agendamento.linkMeet.trim() != " " && (
        <Grid
          item
          xs={12}
          style={{
            marginTop: "0.5rem",
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            paddingBottom: "0.5rem",
          }}
        >
          <ButtonGeneric
            label={"Entrar com google meet"}
            onClick={() => {
              window.open(agendamento.linkMeet, "_blank");
            }}
            style={{ borderRadius: "25px", background: colors.lightBlue[800] }}
          />
          <IconButton
            onClick={() => {
              navigator.clipboard.writeText(agendamento.linkMeet);
              mostrarSnack(
                "Link da reunião, copiado para área  de transferência",
                "info"
              );
            }}
          >
            <CopyAllOutlined />
          </IconButton>
        </Grid>
      )}

      <Grid
        item
        xs={12}
        style={{
          marginTop: "0.5rem",
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          paddingBottom: "0.5rem",
        }}
      >
        {agendamento.prioridade == PRIORIDADE_AGENDAMENTO_BAIXA && (
          <CircleIcon fontSize="medium" style={{ fill: "green" }} />
        )}
        {agendamento.prioridade == PRIORIDADE_AGENDAMENTO_MEDIA && (
          <CircleIcon fontSize="medium" style={{ fill: "yellow" }} />
        )}
        {agendamento.prioridade == PRIORIDADE_AGENDAMENTO_ALTA && (
          <CircleIcon fontSize="medium" style={{ fill: "red" }} />
        )}

        <span
          style={{
            fontSize: "1.25rem",
          }}
        >
          <span>
            {
              LISTA_PRIORIDADE_AGENDAMENTO.find(
                (item) => item.codigo === agendamento.prioridade
              )?.descricao
            }
          </span>
        </span>
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <span>{agendamento.observacao}</span>
      </Grid>
    </Grid>
  );
};

export default TooltipAgenda;
