import { Grid, TextField } from "@mui/material";
import ModalGeneric from "../Generics/ModalGeneric";
import { useSnack } from "../../redux/snack/snackHooks";
import { useState } from "react";
import { makeStyles } from "@material-ui/core";
import Titulo3 from "../Generics/Titulo3";
import ButtonGeneric from "../Generics/ButtonGeneric";
import { Send } from "@mui/icons-material";
import recuperarSenhaApi from "../../api/recuperarSenhaApi";
import { MENSAGEM_USUARIO_SEM_MAIL_PARA_RECUPERAR_SENHA } from "../../constants";
import ModalConfirmarTrocaSenha from "./ModalConfirmarTrocaSenha";
import { DadosEnvioEmaiLRecuperacaoSenha } from "../../model/entities/RecuperarSenha";

type Props = {
  open: boolean;
  onClose: () => void;
  usuario: string;
  codigoAcesso: string;
  setCodigoAcesso: (codigoAcesso: string) => void;
  setUsuario: (usuario: string) => void;
};

const ModalRecuperarSenha: React.FC<Props> = (props) => {
  const { open, onClose, usuario, codigoAcesso } = props;

  const mostrarSnack = useSnack();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [codigoEnviado, setCodigoEnviado] = useState(false);
  const [dadosEmailEnviado, setDadosEmailEnviado] =
    useState<DadosEnvioEmaiLRecuperacaoSenha | null>(null);

  const enviarCodigo = async () => {
    try {
      setLoading(true);
      const res = await recuperarSenhaApi.enviarSolicitacaoRecuperacaoSenha({
        login: usuario,
        codigoAcesso: codigoAcesso,
      });
      if (res) setDadosEmailEnviado(res);
      setLoading(false);
      mostrarSnack("Código enviado com sucesso", "success");
      setCodigoEnviado(true);
    } catch (erro: any) {
      setLoading(false);

      if (
        erro.data ===
        "Usuário não possui e-mail cadastrado! Entre em contato com a equipe de suporte para recuperação de sua senha"
      ) {
        mostrarSnack(MENSAGEM_USUARIO_SEM_MAIL_PARA_RECUPERAR_SENHA, "error");
        return;
      }
      setLoading(false);
      mostrarSnack("Erro ao enviar código", "error");
    }
  };

  return (
    <ModalGeneric open={open} onClose={onClose}>
      <Grid item xs={12} md={8}>
        <Titulo3>
          Confirme seu código de acesso e login para recuperação de senha
        </Titulo3>
      </Grid>

      <Grid item xs={12} md={8} style={{ textAlign: "center" }}>
        <h4>Será enviado um e-mail com um código para recuperação de senha</h4>
      </Grid>

      <Grid item xs={12} md={8} style={{ margin: "0px 15px" }}>
        <TextField
          className={classes.input}
          style={{ marginBottom: "25px" }}
          label="Código de acesso"
          value={codigoAcesso}
          onChange={(event) => {}}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              // onLogin();
            }
          }}
          disabled={loading}
        />
      </Grid>
      <Grid item xs={12} md={8} style={{ margin: "0px 15px" }}>
        <TextField
          className={classes.input}
          style={{ marginBottom: "25px" }}
          label="Usuário"
          value={usuario}
          onChange={(event) => {}}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
            }
          }}
          disabled={loading}
        />
      </Grid>

      <Grid item xs={12} style={{ margin: "0px 15px", textAlign: "center" }}>
        <ButtonGeneric
          label={"Enviar código"}
          startIcon={<Send />}
          onClick={enviarCodigo}
        />
      </Grid>

      {codigoEnviado && (
        <ModalConfirmarTrocaSenha
          open={codigoEnviado}
          onClose={() => {
            setCodigoEnviado(false);
            onClose();
          }}
          dadosEmailEnviado={dadosEmailEnviado}
        />
      )}
    </ModalGeneric>
  );
};

export default ModalRecuperarSenha;
const useStyles = makeStyles(() => ({
  input: {
    width: "100%",
    minWidth: "350px",
    marginBottom: "35px",
    "@media (max-width: 768px)": {
      minWidth: "400px",
      marginBottom: "20px",
    },
  },
  button: {
    backgroundColor: "#3a7bd5 !important",
    color: "#fff !important",
    "&:hover": {
      backgroundColor: "#306bb5 !important",
    },
  },

  footer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "auto", // Empurra o rodapé para o final
    width: "100%", // Garante a largura total
    justifyContent: "center",
    alignItems: "center",
  },
}));
