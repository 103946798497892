import { useDispatch, useSelector } from "react-redux";
import { RootAppState } from "../store";
import { OrcamentoServico } from "../../model/entities/OrcamentoServico";
import { Cliente } from "../../model/entities/Cliente";
import {
  limparOrcamentoAction,
  setApelidoClienteOrcamentoAction,
  setClienteOrcamentoAction,
  setDadosCadastroEnderecoAction,
  setDataValidadeOrcamentoAction,
  setEmailClienteOrcamentoAction,
  setNomeClienteOrcamentoAction,
  setServicosOrcamentoAction,
  setTelefoneClienteOrcamentoAction,
} from "./orcamentoActions";
import { DadosCadastroEndereco } from "./orcamentoTypes";
import { Dayjs } from "dayjs";

export const useClienteOrcamento = (): [
  Cliente | null,
  (cliente: Cliente | null) => void
] => {
  const dispatch = useDispatch();
  const cliente = useSelector((state: RootAppState) => state.orcamento.cliente);
  const setCliente = (cliente: Cliente | null) => {
    const action = setClienteOrcamentoAction(cliente);
    dispatch(action);
  };

  return [cliente, setCliente];
};

export const useServicosOrcamento = (): [
  OrcamentoServico[],
  (cliente: OrcamentoServico[]) => void
] => {
  const dispatch = useDispatch();
  const servicosOrcamento = useSelector(
    (state: RootAppState) => state.orcamento.servicosOrcamento
  );

  const setServicosOrcamento = (servicosOrcamento: OrcamentoServico[]) => {
    const action = setServicosOrcamentoAction(servicosOrcamento);
    dispatch(action);
  };

  return [servicosOrcamento, setServicosOrcamento];
};

export const useDadosCadastroEndereco = (): [
  DadosCadastroEndereco,
  (dadosEndereco: DadosCadastroEndereco) => void
] => {
  const dispatch = useDispatch();
  const dadosEndereco = useSelector(
    (state: RootAppState) => state.orcamento.dadosEndereco
  );

  const setDadosCastroEndereco = (dadosEndereco: DadosCadastroEndereco) => {
    const action = setDadosCadastroEnderecoAction(dadosEndereco);
    dispatch(action);
  };

  return [dadosEndereco, setDadosCastroEndereco];
};

export const useNomeClienteOrcamento = (): [
  string,
  (nomeCliente: string) => void
] => {
  const dispatch = useDispatch();
  const nomeCliente = useSelector(
    (state: RootAppState) => state.orcamento.nomeCliente
  );

  const setNomeCliente = (nomeCliente: string) => {
    const action = setNomeClienteOrcamentoAction(nomeCliente);
    dispatch(action);
  };

  return [nomeCliente, setNomeCliente];
};

export const useApelidoClienteOrcamento = (): [
  string,
  (apelido: string) => void
] => {
  const dispatch = useDispatch();
  const apelido = useSelector(
    (state: RootAppState) => state.orcamento.apelidoCliente
  );

  const setApelido = (apelido: string) => {
    const action = setApelidoClienteOrcamentoAction(apelido);
    dispatch(action);
  };

  return [apelido, setApelido];
};

export const useEmailClienteOrcamento = (): [
  string,
  (email: string) => void
] => {
  const dispatch = useDispatch();
  const email = useSelector((state: RootAppState) => state.orcamento.email);

  const setEmail = (email: string) => {
    const action = setEmailClienteOrcamentoAction(email);
    dispatch(action);
  };

  return [email, setEmail];
};

export const useTelefoneClienteOrcamento = (): [
  string,
  (telefone: string) => void
] => {
  const dispatch = useDispatch();
  const telefone = useSelector(
    (state: RootAppState) => state.orcamento.telefone
  );

  const setApelido = (telefone: string) => {
    const action = setTelefoneClienteOrcamentoAction(telefone);
    dispatch(action);
  };

  return [telefone, setApelido];
};

export const useDataValidadeOrcamento = (): [
  Dayjs,
  (dataValidadeOrcamento: Dayjs) => void
] => {
  const dispatch = useDispatch();
  const dataValidadeOrcamento = useSelector(
    (state: RootAppState) => state.orcamento.dataValidadeOrcamento
  );

  const setDataValidadeOrcamento = (dataValidadeOrcamento: Dayjs) => {
    const action = setDataValidadeOrcamentoAction(dataValidadeOrcamento);
    dispatch(action);
  };

  return [dataValidadeOrcamento, setDataValidadeOrcamento];
};

export const useLimparOrcamento = () => {
  const dispatch = useDispatch();
  const limparPedido = () => {
    const action = limparOrcamentoAction();
    dispatch(action);
  };
  return limparPedido;
};
