import * as React from "react";
import TextField from "@mui/material/TextField";
import { Autocomplete, useTheme } from "@mui/material";
import { LISTA_STATUS_PROJETO } from "../../constants";
import { useEffect, useState } from "react";

type Props = {
  codigoStatus: number;
  setStatus: (obj: (typeof LISTA_STATUS_PROJETO)[0]) => void;
  disabled?: boolean;
};

const StatusProjetoField: React.FC<Props> = (props) => {
  const { codigoStatus, setStatus, disabled } = props;

  const theme = useTheme();

  const [statusSelecionado, setStatusSelecionado] = useState<
    (typeof LISTA_STATUS_PROJETO)[0] | null
  >(null);
  const xsValue = theme.breakpoints.down("sm") ? 12 : 6;

  useEffect(() => {
    const status = LISTA_STATUS_PROJETO.find(
      (item) => item.id === codigoStatus
    );
    if (status) {
      setStatusSelecionado(status);
    }
  }, [codigoStatus]);

  return (
    <div style={{ textAlign: "center" }}>
      <Autocomplete
        id="filter-status-orcamento"
        options={LISTA_STATUS_PROJETO}
        getOptionLabel={(option) => option.decricao}
        value={statusSelecionado}
        onChange={(event, value) => {
          if (value) setStatus(value);
        }}
        sx={{ xsValue }}
        renderInput={(params) => (
          <TextField
            {...params}
            disabled={disabled}
            variant={"outlined"}
            label={"Status projeto"}
            placeholder={"Status projeto"}
            multiline={true}
          />
        )}
      />
    </div>
  );
};

export default StatusProjetoField;
