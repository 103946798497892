import { Briefing } from "./Briefing";
import { Cliente } from "./Cliente";
import { ProjetoCompletoView } from "./ProjetoCompletoView";

export interface Projeto {
  id?: number;
  nome: string;
  descricao?: string;
  dataInicio?: string;
  dataFim?: string;
  status?: number;
  clienteId?: number;
  briefing?: Briefing;
  modeloBriefingId: number;
  cliente?: Cliente;
}

export interface DadosCadastrarProjeto {
  id?: number;
  nome: string;
  descricao?: string;
  dataInicio?: string;
  dataFim?: string;
  status?: number;
  clienteId?: number;
  briefing?: Briefing;
  modeloBriefingId: number;
}

export const projetoCompletoViewToProjeto = (
  projetoView: ProjetoCompletoView
) => {
  const projeto: DadosAtualizarProjeto = {
    nome: projetoView.projetoNome,
    descricao: projetoView.projetoDescricao || "",
    dataInicio: projetoView.projetoDataInicio,
    dataFim: projetoView.projetoDataFim,
    status: projetoView.projetoStatus || 0,
  };

  return projeto;
};

export type DadosAtualizarProjeto = {
  nome: string;
  descricao?: string;
  dataInicio?: string;
  dataFim?: string;
  status?: number;
};
